import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import router from './router'
import store from './store'; // 引入 Vuex store
import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);
Vue.prototype.$app
Vue.prototype.$auth
Vue.prototype.$db
//是否支持webgl
Vue.prototype.$WebGL_available = false
Vue.prototype.$EditKey
Vue.prototype.$ShareCloudFiles_inSchool
Vue.prototype.$USERINFO



// Vue.prototype.$PartInfo_partThreeData=null
//base64
import '../src/js/base64';
Vue.prototype.$MyBase64 = window.Base64

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({

  store, 

  render: h => h(App),
}).$mount('#app')
