<template>
    <!-- style="width: 100%;height:100%;display: flex;flex-wrap: wrap;" -->
    <!-- :class="ParentWidth > ParentHeight ? 'horizontal' : 'row'" -->
    <!-- class="scrollablehorizontal" -->

    <div :class="ParentWidth > ParentHeight ? 'scrollablevertical' : 'scrollablehorizontal'">
        <!-- {{ParentWidth}}:{{ ParentHeight }} -->
        <div v-for="(   item, index   ) in ImegesData   " :key="index" style="width: 150px;height: 150px;">
            <el-button @click="partClick(item)" type="text"
                style="width:100% ;height: 100%;color: #000;padding: 0px;font-size:larger;">
                <div style="width: 100%;height: 100%;position: relative;">
                    <img style="width: 100%;height: 100%;user-drag:none;" :src="item.url">
                    <div
                        style="position: absolute;bottom: 0px;left: 0px;font-weight: 900;text-shadow: white 0px 0px 2px;">
                        <div v-if='ImegesData.length > 6'>↗</div>
                        <div>{{ item.num }}X</div>
                    </div>
                </div>
            </el-button>
        </div>
    </div>
</template>

<script>




export default {
    mounted() {

    },

    data() {
        return {

        }
    },

    props: {
        ImegesData: Array,
        ParentWidth: Number,
        ParentHeight: Number,
    },

    methods: {



        partClick(e) {

            console.log('零件被点击', e)

            // this.HoverIndex = e.index + 1

            // console.log(this.chooseIndexArr)
            // console.log('图片点击事件', e.item)

            this.$emit('image-click', e);

        },

    }


}
</script>

<style scoped>
#container {
    width: 100%;
    height: 100%;
}

/* 横 */
.scrollablehorizontal {



    width: 100%;
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;


    overflow-y: auto;
    scrollbar-width: none;
    /* 隐藏滚动条 */
    -ms-overflow-style: none;
    /* 隐藏滚动条（适用于IE和Edge） */

}

/* 隐藏滚动条但保留滚动功能 */
.scrollablehorizontal::-webkit-scrollbar {
    display: none;
    /* 隐藏滚动条 */
}


/* 竖 */
.scrollablevertical {


    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    /* 隐藏滚动条（适用于IE和Edge） */




}

/* 
.horizontal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
} */

/* 横 */
/* .row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
} */
</style>