// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //userLogIn_teacher 教师用户登录 或者 VisitorLogin 游客登录 或者  notLogin 未登录 
        //
        //Registering 注册中  VisitorLogin
        stateLogIn: 'VisitorLogin', // 初始化全局变量的值
        userName: '',//用户真实姓名
        vueWindowSize: {
            VueViewWidth: 300,
            VueViewHeight: 300
        },
        FilePlayerData: {
            data: '测试'
        },
        //校区分享给教师的的文件_教师查看
        AllSchoolFilesInCloud_teacherView:{},

        //校区在公网ip分享给学生的文件_教师查看
        AllSchool_IP_FilesInCloud_teacherView: {},


        //用户的云端文件夹_所有权限放行的那个
        AllFilesInCloud_userView: {},

        // debugMode:false

    },
    // 更改全局变量的 mutation
    mutations: {
        setLoginState(state, newState) {
            state.stateLogIn = newState;
        },
        setLoginUserName(state, newState) {
            state.userName = newState;
        },

        setWindowSize(state, newState) {
            state.vueWindowSize = newState;
        },


        setFilePlayerData(state, newState) {
            state.FilePlayerData = newState;
        },

        setAllSchoolFilesInCloud_teacherViewData(state, newState) {
            state.AllSchoolFilesInCloud_teacherView = newState;
        },

        setAllSchool_IP_FilesInCloud_teacherViewData(state, newState) {
            state.AllSchool_IP_FilesInCloud_teacherView = newState;
        },
        // AllFilesInCloud_userView

        setAllFilesInCloud_userViewData(state, newState) {
            state.AllFilesInCloud_userView = newState;
        },

        // AllSchool_IP_FilesInCloud_teacherView



        //



    },

    // 触发 mutation 来更改全局变量的值
    actions: {

        changeLoginState({ commit }, newState) {
            commit('setLoginState', newState);
        },

        changeUserName({ commit }, newState) {
            commit('setLoginUserName', newState);
        },

        changeWindowSize({ commit }, newState) {
            commit('setWindowSize', newState);
        },

        changeFilePlayerData({ commit }, newState) {
            commit('setFilePlayerData', newState);
        },

        changeAllSchoolFilesInCloud_teacherViewData({ commit }, newState) {
            commit('setAllSchoolFilesInCloud_teacherViewData', newState);
        },

        changeAllSchool_IP_FilesInCloud_teacherViewData({ commit }, newState) {
            commit('setAllSchool_IP_FilesInCloud_teacherViewData', newState);
        },

        changeAllFilesInCloud_userViewData({ commit }, newState) {
            commit('setAllFilesInCloud_userViewData', newState);
        },

        
    },
    // 获取全局变量的值
    getters: {
        getLoginState(state) {
            return state.stateLogIn;
        },

        getLoginUserName(state) {
            return state.userName;
        },

        getWindowSize(state) {
            return state.vueWindowSize;
        },

        getFilePlayerData(state) {
            return state.FilePlayerData;
        },

        getAllSchoolFilesInCloud_teacherViewData(state) {
            return state.AllSchoolFilesInCloud_teacherView;
        },

        getAllSchool_IP_FilesInCloud_teacherViewData(state) {
            return state.AllSchool_IP_FilesInCloud_teacherView;
        },

        getAllFilesInCloud_userViewData(state) {
            return state.AllFilesInCloud_userView;
        },


    },
});
