<template>
    <!-- <div> -->
    <div style="width:100%;height:50px">

        <div
            style="width:100%;height:50px;background-color: #2c3e50;display: flex;align-items: center;flex-direction: row;justify-content: space-between;">


            <el-button type="text">
                <div @click="goMainView"
                    style="width:150px;height: 50px;display:flex;align-items: center;justify-content: center;">
                    <div style="height:30px ;display: flex;align-items: center;">
                        <img style="height:25px;width:100%;user-drag:none" src="../assets/dadaLOGO.png">
                    </div>
                </div>
            </el-button>






            <!-- <div style="color:rgb(213, 0, 0) ;" v-if="$UserLoginState">用户登录啦？是的</div>
            <div style="color:rgb(213, 0, 0) ;" v-else>{{$UserLoginState}}用户登录啦？不</div> -->

            <!--<div style="color:rgb(213, 0, 0) ;margin-right: 200px;">状态{{ stateLogIn }}</div>-->

            <!-- <div style="color:rgb(213, 0, 0) ;">用户登录啦？{{ $root.$UserLoginState }}</div> -->




            <div
                style="width: 200px;height: 50px;display:flex;align-items: center;justify-content: space-between;margin-right: 15px;">

                <el-popover placement="left" width="200" trigger="click">
                    <img style="width:200px;height: 200px;" src="../assets/WeChat_customer_service.jpg.jpg">

                    <el-button type="text" slot="reference">联系客服<i
                            class="el-icon-arrow-down el-icon--right"></i></el-button>
                </el-popover>



                <div v-if="stateLogIn == 'userLogIn_teacher'" style="display: flex;align-items: center;">
                    <div
                        style="width: 30px;height: 30px;background-color:#9b59b6;border-radius: 50%;display: flex;justify-content: center;align-items: center;">
                        <i class="el-icon-user" style="font-size: 25px;color: #ffffff;"></i>
                    </div>
                    <el-dropdown @command="handleCommand_head_userSet">

                        <el-button type="text">
                            个人中心<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <!-- @command -->
                        <el-dropdown-menu slot="dropdown">
                            <!-- <el-dropdown-item>名称:武汉市宝来乐教育科技有限公司</el-dropdown-item> -->
                            <!-- <el-dropdown-item>账号角色:校区管理员</el-dropdown-item> -->
                            <el-dropdown-item>姓名:{{ userNameLogIn }}</el-dropdown-item>
                            <el-dropdown-item command="setPassword">修改资料</el-dropdown-item>
                            <el-dropdown-item command="Exit">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div v-else style="display: flex;align-items: center;margin-left: 10px;">
                    <!-- <div
                        style="width: 30px;height: 30px;border-radius: 50%;display: flex;justify-content: center;align-items: center;">
                        <i class="el-icon-question" style="font-size: 25px;color:#2c3e50;"></i>
                    </div> -->
                    <el-button @click="loginIn" type="text" style="margin-left: 6px;">
                        登录/注册
                    </el-button>
                    <!-- <div style="color:#3498db ;font-size: medium;font-weight: 600;">点击登录</div> -->

                </div>
                <!-- <div  v-if="$root.$UserLoginState == false">未登录</div> -->

            </div>


        </div>

        <!-- <div style="height:20px;background-color: #ffffff;">
            <el-page-header @back="goBack" ></el-page-header>
        </div> -->

        <!-- <div style="height:20px;background-color: #ffffff;">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
                <el-breadcrumb-item><a href="/my3dview">活动管理</a>活动列表</el-breadcrumb-item>
                <el-breadcrumb-item>活动详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->

    </div>
</template>
<script>
// MeshToonMaterial
// navigateToCeShi
export default {
    // name: 'PartListImageScroll',
    data() {
        return {
            gridData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }]

            // chooseIndex: 3,
            // // showstep:1,
            // StepNumberInput: null,

            // TargetStep: null,

        }
    },
    // props: {
    //     images: Array
    // },

    mounted() {


        // this.$parent.$on('messageToLeftList', this.handleParentMessage);

    },

    computed: {
        stateLogIn() {
            return this.$store.getters.getLoginState;
        },
        userNameLogIn() {
            return this.$store.getters.getLoginUserName;
        },
    },


    methods: {

        handleCommand_head_userSet(e) {

            if (e == 'Exit') {
                this.ExitAccount()
            }


            // this.$message('这是一条消息提示');


            // console.log('头部操作', e)

        },

        //退出登录
        async ExitAccount() {
            console.log(this.$root.$auth)
            console.log('点击退出登录')
            await this.$root.$auth.signOut()
            // await this.$root.$auth.anonymousAuthProvider().signIn();
            await this.$root.$auth.signInAnonymously()
            // this.$root.$auth.signOut()
            //vuex 登录状态是false
            this.$store.dispatch('changeLoginState', 'notLogin');

            //用户信息清空
            //用户姓名
            this.$store.dispatch('changeUserName', null);

            this.$root.$USERINFO = {
                _id: '',
                //用户昵称
                name: "",
                //用户真名字
                userRealName: "",
                //用户生份证号
                userIDNumber: "",
                //用户昵称
                email: "",
                uid: "",
                userEmail: "",
                userPhoneNumber: "",
                //是否是将文件存储在cos对象存储内的
                userIsCOSFolder: "",
                //用户文件夹名称
                userFolder: "",
                //存储桶的名称
                userBucket: "",
                //存储桶的位置 shanghai
                userRegion: "",
                //用户上次登录时的角色
                userLastLoginRole: "",
                //是否具有teacher属性
                userIsTeacher: "",
                //作为教师的信息（昵称，到期时间戳等等）
                userTeacherInfo: "",
                //用户是否是校区管理员
                userIsSchoolAdministrator: "",
                //用户作为校区管理员的信息（校区名称，地址，到期时间等等）
                userSchoolAdministratorInfo: ""
            }

            // that.initUserFileTool(that.$root.$USERINFO)

            //文件操作器设为空对象
            this.$root.$MyCloudFiles = null
            this.$root.$ShareCloudFiles = null


            if (this.$route.path != '/') {
                this.$router.push("/")
            }

            // this.$router.push("/")
            // this.$root.$UserLoginState = false
        },

        navigateToCeShi() {
            this.$router.push("/")

            // this.$router.push("/ClassroomFileSharing")


            // /ClassroomFileSharing

        },

        navigateToCeShi2() {
            this.$router.push("/my3dview")

        },

        goMainView() {
            console.log('当前路由的页面值是', this.$route.path)
            if (this.$route.path != '/') {
                this.$router.push("/")
            }
        },

        loginIn() {

            // if (this.$store.state.stateLogIn == 'notLogin') {

            this.$store.dispatch('changeLoginState', 'notLogin');

            // }

        }





    }
}
</script>
<style></style>