<template>
    <div v-if="ImageUrl"
        style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;background-color: rgb(0, 0, 0);position: absolute;left: 0;top: 0;">

        <div
            style="width: 100%;height:100% ;background-color: rgb(0, 0, 0);display: flex;align-items: center;justify-content: center;">

            <!-- <video id="video" @ended="endedVideo" @timeupdate="videoTimeUpdate" @loadeddata="videoLoadedData"
                ref="videoPlayer"  
                :src="videoUrl"></video> -->


            <el-image @load="handelimageload" style="width: 100%; height: 100%;background-color: rgb(0, 0, 0);"
                :src="ImageUrl" @dragstart.prevent @dragover.prevent @drop.prevent fit="contain"></el-image>

            <!-- <img object-fit :src="ImageUrl" style="max-width: 100%;max-height: 100%;background-color: rgb(0, 0, 0);" alt=""> -->
            <!-- <div
                style="position:absolute;bottom: 0px;width: 100%;display: flex;align-items: center;justify-content: space-between;background-color: rgba(0, 0, 0, 0.351);">
                <div style="display: flex;align-items: center;padding: 5px;width: 100%;justify-content: space-between;">

                    <div style="display:flex;align-items: center;margin-right: 10px;">
                        <el-button @click="stopVideo"
                            style="padding: 0px;display: flex;align-items: center;justify-content: center;" type="text"
                            circle> <img style="width: 2.5em;height:2.5em;pointer-events: none;" src="../assets/stop.svg"
                                alt="">
                        </el-button>
                        <el-button v-if="playVideoButtonShow" @click="playVideo" style="padding: 0px;font-size: 2em;"
                            type="text" icon="el-icon-video-play" circle>
                        </el-button>
                        <el-button v-if="!playVideoButtonShow" @click="pauseVideo" style="padding: 0px;font-size: 2em;"
                            type="text" icon="el-icon-video-pause" circle>
                        </el-button>
                    </div>
                    <div style="display:flex ;align-items: center;flex: 1;">
                        <el-progress :show-text="false" :percentage=progress :stroke-width=2 style="flex: 1;"></el-progress>
                        <div style="font-size: small;margin-left: 10px;">{{ nowTime + "/" + totalTime
                        }}</div>
                    </div>
                 
                </div>

            </div> -->

            <!-- '搭搭星球' + '          ' + nameRes + '          ' + IDNUmber -->


            <!-- <div
                style="position:absolute;top: 0px;left: 0px;width: 100%;height: 100%;display: flex;transform: rotate(-45deg);flex-wrap: wrap;">
                <div style="background-color: aqua;">测试33333333</div>
      
            </div> -->

            <div
                style="position:absolute;top: 0px;width: 100%;display: flex;align-items: center;flex-direction: row-reverse">
                <el-button @click="closeImagePlayer" style="padding: 0px;font-size: xx-large;margin: 10px;" type="text"
                    icon="el-icon-close" circle>
                </el-button>
            </div>

        </div>


    </div>
</template>
<script>



var loading


export default {
    data() {
        return {
            // videoUrl: null,
            // playVideoButtonShow: true,
            // progress: 0,
            // nowTime: 0,
            // totalTime: 0,
            ImageUrl: null

        }
    },





    mounted() {

        this.$parent.$on('messageToImagePlayer', this.handleParentMessage);




        window.addEventListener('resize', this.windowResize)

        // this.gettempKey()


        // this.getvideoPlayerView()
        // this.getMediaUrl()
    },


    beforeDestroy() {

        window.removeEventListener("resize", this.windowResize);
        // window.removeEventListener("mousemove", this.windowResize);

    },

    props: {
        // images: Array
    },

    // beforeDestroy() {

    // },





    methods: {


        windowResize() {

            // this.loadedmetadata()
            // this.setVideoWidthAndHeight()

        },

        handleParentMessage(e) {

            console.log('handleParentMessage_e222222', e)
            // this.getvideoPlayerView(e.Key)

            this.getMediaUrl(e)

        },

        //关闭图片播放器
        closeImagePlayer() {

            this.ImageUrl = null

        },

        handelimageload() {
            console.log('图片加载完成')

            loading.close()

            loading = null

        },

        // closeVideoPlayer() {

        //     this.videoUrl = null
        //     this.playVideoButtonShow = true
        //     this.progress = 0
        //     this.nowTime = 0
        //     this.totalTime = 0

        //     // this.$refs.videoPlayer.pause()

        //     //显示播放按钮
        //     // this.playVideoButtonShow = true
        // },

        //判断key是不是用户自己的？
        // this.$root.$USERINFO.userFolder
        isKeyOfUser(key, userFolder) {
            // UsersFolder / 886f5319281740f88009117c2ddf9b2b

            // if (userFolder == null) return false

            const KeyArr = key.split('/')

            if (KeyArr[1] == userFolder) {

                return true

            } else {

                return false

            }

        },

        async getMediaUrl(e) {

            console.log('getMediaUrle',e)

            if (this.$root.$USERINFO&&this.$root.$USERINFO.userFolder&&this.isKeyOfUser(e.Key, this.$root.$USERINFO.userFolder)) {
                //showMyWPPS
                this.showMyImage(e.Key)

            } else {
                //分享的办公文件
                this.showSharerImage(e)

            }


        },

        async showMyImage(key) {
            const that = this

            var res = await this.$root.$MyCloudFiles.getMyCloudMideaUrl(key)

            if (res == 'err') {

                this.$message.error('资源连接错误');

                return
            }

            loading = this.$loading({
                lock: true,
                text: '加载数据中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {

                if (loading != null) {

                    loading.close()
                    loading = null

                    that.$message({
                        message: '超时！请检查网络环境或者资源过大',
                        type: 'warning'
                    });
                }


            }, 5000);

            console.log('多媒体的预览地址是', res)

            this.ImageUrl = res

        },

        //获取分享的文件
        async showSharerImage(e) {
            const that = this

            // TemporaryKey
            // BucketInfo

            //不是该用户的文件夹，则需要传入临时密钥
            const TemporaryKey = e.temporary_key

            const BucketInfo = {
                Bucket: TemporaryKey.bucket,
                Region: TemporaryKey.region
            }

            var res = await this.$root.$ShareCloudFiles_inSchool.getShareCloudMideaUrl(e.Key, TemporaryKey, BucketInfo)

            console.log('临时图片链接结果',res )
            if (res == 'err') {

                this.$message.error('资源连接错误');

                return
            }

            loading = this.$loading({
                lock: true,
                text: '加载数据中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {

                if (loading != null) {

                    loading.close()
                    loading = null

                    that.$message({
                        message: '超时！请检查网络环境或者资源过大',
                        type: 'warning'
                    });
                }


            }, 5000);

            console.log('多媒体的预览地址是', res)

            this.ImageUrl = res

        },




    }
}
</script>
<style></style>