<template>
    <div v-if="videoUrl" style="width: 100vw;height: 100%;position: absolute;top:0;left:0;background-color: #35495d;">

        <div id="video-container" style="width: 100%;height:100% ;background-color: rgb(0, 0, 0);overflow: hidden;">

            <video :src="videoUrl" width="100%" height="100%" controls></video>


            <!-- <video style="width: 100%;height:100% ;" :src=videoUrl allowfullscreen allowtransparency
                disableContextMenu></video> -->
            <!-- <video id="video" @ended="endedVideo" @timeupdate="videoTimeUpdate" @loadeddata="videoLoadedData"
                ref="videoPlayer" style="max-width: 100%;height:100%;background-color: rgb(0, 0, 0);"
                :src="videoUrl"></video> -->
            <!-- :options="playerOptions" -->
            <!--<vue-plyr ref="videoPlaye" style="width: 100%;height:100%">

          
                <div style="width: 100%;height:100% ;overflow: hidden;">
                    <iframe style="width: 100%;height:100% " :src=videoUrl allowfullscreen allowtransparency disableContextMenu
                        allow="autoplay"></iframe>
                </div>

            </vue-plyr>
            -->

            <!-- <div
                style="position:absolute;bottom: 0px;width: 100%;display: flex;align-items: center;justify-content: space-between;background-color: rgba(0, 0, 0, 0.351);">
                <div style="display: flex;align-items: center;padding: 5px;width: 100%;justify-content: space-between;">

                    <div style="display:flex;align-items: center;margin-right: 10px;">
                        <el-button @click="stopVideo"
                            style="padding: 0px;display: flex;align-items: center;justify-content: center;" type="text"
                            circle> <img style="width: 2.5em;height:2.5em;pointer-events: none;"
                                src="../assets/stop.svg" alt="">
                        </el-button>
                        <el-button v-if="playVideoButtonShow" @click="playVideo" style="padding: 0px;font-size: 2em;"
                            type="text" icon="el-icon-video-play" circle>
                        </el-button>
                        <el-button v-if="!playVideoButtonShow" @click="pauseVideo" style="padding: 0px;font-size: 2em;"
                            type="text" icon="el-icon-video-pause" circle>
                        </el-button>
                    </div>
                    <div style="display:flex ;align-items: center;flex: 1;">

                     
                        <el-slider v-model=progress :show-tooltip="false" :step="0.1" @input=progressChange_user_input
                            @change=progressChange_user_change style="flex: 1;"></el-slider>

                        <div style="font-size: small;margin-left: 10px;">{{ nowTime + "/" + totalTime
                            }}</div>
                    </div>
         
                </div>

            </div> -->

            <div
                style="position:absolute;top: 0px;width: 100%;display: flex;align-items: center;flex-direction: row-reverse">
                <el-button @click="closeVideoPlayer" style="padding: 0px;font-size: xx-large;margin: 10px;" type="text"
                    icon="el-icon-close" circle>
                </el-button>
            </div>
        </div>


    </div>
</template>
<script>



var loading
import COS from 'cos-js-sdk-v5';


export default {
    data() {
        return {
            videoUrl: null,
            playVideoButtonShow: true,
            progress: 0,
            nowTime: 0,
            totalTime: 0,
            playerOptions: {
                controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
                captions: { active: true },
                // 其他 Plyr 选项...
            },


        }
    },





    mounted() {

        this.$parent.$on('messageToVideoPlayer', this.handleParentMessage);




        window.addEventListener('resize', this.windowResize)

        // this.gettempKey()


        // this.getvideoPlayerView()
        // this.getMediaUrl()
    },


    beforeDestroy() {

        window.removeEventListener("resize", this.windowResize);
        // window.removeEventListener("mousemove", this.windowResize);

    },

    props: {
        // images: Array
    },

    // beforeDestroy() {

    // },





    methods: {


        windowResize() {

            // this.loadedmetadata()
            // this.setVideoWidthAndHeight()

        },

        handleParentMessage(e) {

            console.log('handleParentMessage_e', e)
            // this.getvideoPlayerView(e.Key)

            this.getMediaUrl(e)

        },


        //视频加载完成事件
        // videoLoadedData() {
        //     const video = this.$refs.videoPlayer;
        //     this.totalTime = this.formatTime(video.duration)
        //     this.nowTime = this.formatTime(video.currentTime)
        //     loading.close()
        //     loading = null
        // },




        //更新进度条
        // videoTimeUpdate() {
        //     console.log('进度更新')
        //     const video = this.$refs.videoPlayer;
        //     console.log(video.currentTime, video.duration)
        //     const progress = (video.currentTime / video.duration) * 100;
        //     this.totalTime = this.formatTime(video.duration)
        //     this.nowTime = this.formatTime(video.currentTime)

        //     console.log('this.nowTime', this.nowTime)
        //     console.log('this.totalTime', this.totalTime)


        //     this.progress = progress;
        // },
        // formatTime(seconds) {
        //     var hours = Math.floor(seconds / 3600);
        //     var minutes = Math.floor((seconds % 3600) / 60);
        //     var remainingSeconds = Math.floor(seconds % 60);

        //     var timeString = '';

        //     if (hours > 0) {
        //         timeString += hours + ':';
        //     }

        //     if (minutes > 0) {
        //         timeString += minutes + ':';
        //     }

        //     // if (remainingSeconds <  1){
        //     timeString += remainingSeconds;
        //     // }
        //     return timeString
        // },

        // displayProgress(currentTime, duration) {

        //     var progress = (currentTime / duration) * 100;
        //     var formattedCurrentTime = formatTime(currentTime);
        //     var formattedDuration = formatTime(duration);

        //     console.log('当前进度：' + progress.toFixed(2) + '%');
        //     console.log('已播放时间：' + formattedCurrentTime);
        //     console.log('视频总时长：' + formattedDuration);
        // },


        //播放结束事件
        // endedVideo() {
        //     console.log('播放结束')
        //     this.playVideoButtonShow = true
        // },

        // stopVideo() {
        //     this.$refs.videoPlayer.pause();
        //     this.$refs.videoPlayer.currentTime = 0;

        //     this.playVideoButtonShow = true

        // },




        //播放视频
        // playVideo() {

        //     this.$videoPlaye.plyr.player.play();

        //     // this.$refs.videoPlayer.play()
        //     //隐藏播放按钮，显示暂停按钮

        //     this.playVideoButtonShow = false

        // },

        //暂停视频
        // pauseVideo() {
        //     this.$refs.videoPlayer.pause()

        //     //显示播放按钮
        //     this.playVideoButtonShow = true
        // },

        closeVideoPlayer() {

            this.videoUrl = null
            this.playVideoButtonShow = true
            this.progress = 0,
                this.nowTime = 0,
                this.totalTime = 0

            // this.$refs.videoPlayer.pause()

            //显示播放按钮
            // this.playVideoButtonShow = true
        },

        //用户拖动
        // progressChange_user_input(e) {

        //     console.log('用户拖动input', e)

        //     // const that = this





        // },

        // progressChange_user_change(e) {

        //     console.log('用户拖动结束', e)


        //     this.$refs.videoPlayer.currentTime = Math.floor(this.$refs.videoPlayer.duration * (this.progress / 100))



        // },







        async getMediaUrl(e) {

            loading = this.$loading({
                lock: true,
                text: '加载数据中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {

                if (loading != null) {

                    loading.close()
                    loading = null

                    that.$message({
                        message: '超时！请检查网络环境或者资源过大',
                        type: 'warning'
                    });
                }


            }, 5000);

            const that = this

            console.log('key视频333', e)




            // var res = await this.$root.$MyCloudFiles.getMyCloudMideaUrl(key)


            var res
            if (this.$root.$USERINFO && this.$root.$USERINFO.userFolder && this.isKeyOfUser(e.Key, this.$root.$USERINFO.userFolder)) {
                //是该用户的文件夹

                console.log('e.key2222211', e.Key)
                res = await this.$root.$MyCloudFiles.getMyCloudMideaUrl(e.Key)

            } else {

                //不是该用户的文件夹，则需要传入临时密钥
                const TemporaryKey = e.temporary_key

                const BucketInfo = {
                    Bucket: TemporaryKey.bucket,
                    Region: TemporaryKey.region
                }


                // res = await this.$root.$MyCloudFiles.getObject_fromSharer(e.Key, (onprogress) => {

                //     console.log('文件下载进度666611', onprogress)

                // }, TemporaryKey, BucketInfo)

                res = await this.getCloudMideaUrl_fromSharer(e.Key, TemporaryKey, BucketInfo)

                console.log('获取分享者的视频url结果', res)

                // getObject_fromSharer(

            }












            if (res == 'err') {

                loading.close()
                loading = null
                this.$message.error('资源连接错误');
                return
            }


            console.log('多媒体的预览地址是', res)
            this.videoUrl = res

            loading.close()
            loading = null

            //             var Bucket = 'dadastar-1307054034'
            // var Region = 'ap-shanghai'


        },

        getCloudMideaUrl_fromSharer(Key, TemporaryKey, BucketInfo) {

            console.log('Key, TemporaryKey, BucketInfo', Key, TemporaryKey, BucketInfo)



            var CosShare = new COS({
                SecretId: TemporaryKey.SecretId,
                SecretKey: TemporaryKey.SecretKey,
                SecurityToken: TemporaryKey.SecurityToken,
            });

            // BucketInfo


            return new Promise((resolve, reject) => {
                CosShare.getObjectUrl({
                    Bucket: BucketInfo.Bucket,
                    Region: BucketInfo.Region,
                    Key,
                    Sign: true,// 获取带签名的对象 URL
                    // Protocol: 'https:'
                }, function (err, data) {


                    {
                        console.log(reject, err)
                        /* url为对象访问 url */
                        var url = data.Url;
                        /* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
                        var downloadUrl =
                            url +
                            (url.indexOf('?') > -1 ? '&' : '?') +
                            'response-content-disposition=inline'; // 补充强制下载的参数并重命名下载后的文件

                        console.log('类中生成的临时下载链接eee', downloadUrl)

                        resolve(downloadUrl);
                    }
                });
            });

        },





        //判断key是不是用户自己的？
        // this.$root.$USERINFO.userFolder
        isKeyOfUser(key, userFolder) {
            // UsersFolder / 886f5319281740f88009117c2ddf9b2b

            // if (userFolder == null) return false

            const KeyArr = key.split('/')

            if (KeyArr[1] == userFolder) {

                return true

            } else {

                return false

            }

        },




    }
}
</script>
<style></style>