<template>
    <div class="scrollable" style="width: 100%;height:100%;display: flex;flex-wrap: wrap;">
        <div v-for="(item, index) in ImegesData" :key="index" style="width: 150px;height: 150px;">

            
            <el-button  @click="partClick(item)" type="text" disabled
                style="width:100% ;height: 100%;color: #000;padding: 0px;font-size:larger;">
                <div style="width: 100%;height: 100%;position: relative;">
                    <img style="width: 100%;height: 100%;user-drag:none;" :src="item.url">
                    <div
                        style="position: absolute;bottom: 0px;left: 0px;font-weight: 900;text-shadow: white 0px 0px 2px;">
                        <div v-if='ImegesData.length > 6'>↗</div>
                        <div>{{ item.num }}X</div>
                    </div>
                </div>
            </el-button>

        </div>
    </div>
</template>
  
<script>




export default {
    mounted() {

    },

    data() {
        return {

        }
    },

    props: {
        ImegesData: Array
    },

    methods: {



        partClick(e) {

            console.log('零件被点击', e)

            // this.HoverIndex = e.index + 1

            // console.log(this.chooseIndexArr)
            // console.log('图片点击事件', e.item)

            this.$emit('image-click', e);

        },

    }


}
</script>
  
<style>
#container {
    width: 100%;
    height: 100%;
}

.scrollable {
    overflow-y: auto;
    scrollbar-width: none;
    /* 隐藏滚动条 */
    -ms-overflow-style: none;
    /* 隐藏滚动条（适用于IE和Edge） */
}
</style>
  