<!-- <div style="width: 100%;height: 100%;">

    <img v-if="FileInfo.type == 'folder'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'" src="../assets/folder.svg"
        alt="">

    <img v-else-if="FileInfo.type == 'txt'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/fileText.svg" alt="">

    <img v-else-if="FileInfo.type == 'mp4'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/videoFile.svg" alt="">

    <img v-else-if="FileInfo.type == 'pdf'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/pdfFile.svg" alt="">

    <img v-else-if="FileInfo.type == 'pptx'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/pptFile.svg" alt="">

    <img v-else-if="FileInfo.type == 'docx'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/docFile.svg" alt="">

    <img v-else-if="FileInfo.type == 'xlsx'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/xlsxFile.svg" alt="">

    <img v-else-if="FileInfo.type == 'jpg' || FileInfo.type == 'jpeg' || FileInfo.type == 'png'"
        style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/imageFile.svg" alt="">

    <img v-else-if="FileInfo.type == 'gif'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/gifFile.svg" alt="">


    <img v-else-if="FileInfo.type == 'ldr'" style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/ldrFile.svg" alt="">

    <img v-else style="user-select:none ;" @dragstart.prevent
        :style="'width:' + fileItemWidth / 2 + 'px;' + 'height:' + fileItemWidth / 2 + 'px;'"
        src="../assets/unknownFile.svg" alt="">

    <div style="user-select:none ;font-size: small;">{{ FileInfo.name }}</div>

</div> -->
<template>
    <div  style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;
    white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">

        <img v-if="FileInfo.type == 'folder'" class="image-style" @dragstart.prevent src="../assets/folder.svg" alt="">

        <img v-else-if="FileInfo.type == 'txt'" class="image-style" @dragstart.prevent src="../assets/fileText.svg" alt="">

        <img v-else-if="FileInfo.type == 'mp4' || FileInfo.type == 'avi'" class="image-style" @dragstart.prevent
            src="../assets/videoFile.svg" alt="">

        <img v-else-if="FileInfo.type == 'pdf'" class="image-style" @dragstart.prevent src="../assets/pdfFile.svg" alt="">

        <img v-else-if="FileInfo.type == 'pptx'" class="image-style" @dragstart.prevent src="../assets/pptFile.svg" alt="">

        <img v-else-if="FileInfo.type == 'docx'" class="image-style" @dragstart.prevent src="../assets/docFile.svg" alt="">

        <img v-else-if="FileInfo.type == 'xlsx'" class="image-style" @dragstart.prevent src="../assets/xlsxFile.svg" alt="">

        <img v-else-if="FileInfo.type == 'jpg' || FileInfo.type == 'jpeg' || FileInfo.type == 'png'" class="image-style"
            @dragstart.prevent src="../assets/imageFile.svg" alt="">

        <img v-else-if="FileInfo.type == 'gif'" class="image-style" @dragstart.prevent src="../assets/gifFile.svg" alt="">


        <img v-else-if="FileInfo.type == 'ldr'" class="image-style" @dragstart.prevent src="../assets/ldr.svg" alt="">

        <img v-else-if="FileInfo.type == 'mpd'" class="image-style" @dragstart.prevent src="../assets/ldrFile.svg" alt="">

        <img v-else class="image-style" @dragstart.prevent src="../assets/unknownFile.svg" alt="">



    </div>
</template>
<script>






export default {
    data() {
        return {


        }
    },





    mounted() {

        // this.$parent.$on('messageToVideoPlayer', this.handleParentMessage);

        // window.addEventListener('resize', this.windowResize)


        // this.getvideoPlayerView()
        // this.getMediaUrl()
    },
    beforeDestroy() {
        // window.removeEventListener("resize", this.windowResize);
        // window.removeEventListener("mousemove", this.windowResize);
    },

    props: {
        FileInfo: Object,
        // smallFontSize: String
        // images: Array
    },

    // beforeDestroy() {

    // },

    methods: {

        windowResize() {
            this.setCoursesContainerDivSize
        },

        //动态设置底部的商品的宽高尺寸



        // windowResize() {

        //     // this.loadedmetadata()
        //     // this.setVideoWidthAndHeight()

        // },

        // handleParentMessage() {

        //     // console.log('handleParentMessage_e', e)
        //     // this.getvideoPlayerView(e.Key)

        //     // this.getMediaUrl(e.Key)

        // },

        // async getMediaUrl(key) {
        //     var res = await this.$MyCloudFiles.getMyCloudMideaUrl(key)
        //     console.log('多媒体的预览地址是', res)
        //     this.videoUrl = res
        //     //             var Bucket = 'dadastar-1307054034'
        //     // var Region = 'ap-shanghai'
        // },

    }
}
</script>
<style>
.image-style {
    user-select: none;
    width: 100%;
    height: 100%;
}
</style>