<template>
    <div :style="{ height: WindowSize.VueViewHeight + 'px', display: 'flex', 'flex-direction': 'column' }" class="home">

        <PublicHead style="width:100%;"></PublicHead>

        <div style="display: flex;align-items: center;position:relative;background-color: #bdc3c7;min-height: 20px;">

            <el-button v-if="showFolder.upperFolder != null" style="padding: 0px;margin-right: 5px;margin-left: 10px;"
                type="text" @click.stop="GoToUpperFolder"><i class="el-icon-arrow-left"></i></el-button>

            <div v-if="showFolder.upperFolder != null" style="font-size:small ;">{{ FolderPath }}</div>

        </div>


        <div style="flex: 1;overflow-y: auto;">



            <div style="width: 100%;display: flex;margin-bottom: 10px;justify-content: space-between;">


                <div style="display: flex;margin: 10px;">
                    <el-input placeholder="搜索校区局域网分享的文件" autocomplete="off" style="width:250px"
                        v-model="searchFileName"></el-input>
                    <el-button v-if="searchFileName != ''" @click="searchFileName = ''" type="primary"
                        style="margin-left: 10px;margin-right: 10px;">取消</el-button>
                </div>

                <el-button type="text" @click="TeacherGetSchoolShareFiles" style="margin-right: 10px;padding: 0;"><i
                        style="font-size: xx-large;color: #2c3e50;" class="el-icon-refresh"> </i></el-button>
            </div>

            <div class="fileContainer">

                <div v-for="(FolderListItem, index) in showFolder.folder" :key="index"
                    @mousedown="handleStart($event, index, FolderListItem)" @mouseup="handleEnd"
                    @dblclick.stop="folderDBClick(index, FolderListItem)"
                    @click.stop="folderClick(index, FolderListItem)" @mouseenter="FileMouseEnter(index)"
                    @mouseleave="FileMouseLeave" @contextmenu.prevent="onContextmenu($event, index, FolderListItem)">


                    <div v-if="searchFileName == '' || !FolderListItem.name || FolderListItem.name.indexOf(searchFileName) != -1"
                        style="display: flex;align-items: center;justify-content: center;
                    flex-direction: column;margin-top: 0px;position: relative;width: 10vw;height: min-content;"
                        :class="fileHoverIndex == index || fileClickIndex == index ? 'hoverStyle' : ''">

                        <div
                            style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                            <div style="width: 50%;height:50%">
                                <FileImage :FileInfo="FolderListItem" style="width: 100%;height:100%"></FileImage>
                            </div>
                            <!-- :style="'font-size:' + smallFontSize" -->
                            <div style="margin-top: 1px;width: 100%;overflow: hidden;font-size: 1vw;">{{
                                FolderListItem.name }}</div>
                        </div>
                    </div>



                </div>






                <!-- <div v-for="( item, index ) in shareFileList " :key="index"
                    style="width: 100%;flex: 1;display:flex;align-items: center;justify-content: center;margin-top: 10px;">

                    <el-button type="text" @click="clickFileImage(item)"
                        style="width: 200px;height: 200px;position: relative;">


                        <img src="../assets/fileView.svg"
                            style="width: 100%;height: 100%;background-color:#bdc3c7;-webkit-user-drag: none;">


                        <el-tooltip
                            style="position:absolute;left: 0;bottom: 0;width: 100%;background-color: #34495e;color: #ecf0f1;font-size: medium;overflow: hidden;"
                            effect="dark" :content=item.FileName placement="top-start">
                            <div>{{ item.FileName }}</div>
                        </el-tooltip>


                    </el-button>

         

                </div> -->




            </div>


        </div>


        <div>武汉市宝来乐教育科技有限公司ICP备案/许可证号：鄂ICP备2022001666号</div>


        <!-- <el-button @click="linkSchoolHeadToUpdateSchoolIP">发邮件</el-button> -->



        <el-dialog title="当前局域网无文件" :visible.sync="outerVisible">
            <el-dialog width="30%" title="发送消息？" :visible.sync="innerVisible" append-to-body>

                <el-dialog width="30%" title="无法联系到校区负责人" :visible.sync="inner_innerVisible" append-to-body>

                    <div style="color: #2c3e50;margin: 5px;">本环境下并没有任何文件访问记录</div>

                    <div style="color: #2c3e50;margin: 5px;">无法联系到校区负责人</div>

                    <div style="color: #2c3e50;margin: 5px;">您可以直接在校区找负责人处理次事务</div>

                    <div slot="footer" class="dialog-footer">
                        <el-button @click="inner_innerVisible = false">知道了</el-button>
                        <!-- <el-button >连接了</el-button> -->
                    </div>
                </el-dialog>

                <div style="color: #2c3e50;margin: 5px;">这种情况可能是校区的网络地址发生了变化，</div>

                <div style="color: #2c3e50;margin: 5px;">校区重新绑定ip后你就能正常访问文件了</div>

                <div style="font-weight: 900;color: #2c3e50;margin: 5px;">
                    点击按钮可以发送消息给校区负责人来更新校区ip</div>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="linkSchoolHeadToUpdateSchoolIP">发送</el-button>
                    <!-- <el-button >连接了</el-button> -->
                </div>
            </el-dialog>
            <span style="font-size: large;color:#3498db;">请问你现在连接了校区网络吗？</span>
            <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisible = false">没有</el-button>
                <el-button @click="innerVisible = true">连接了</el-button>
            </div>
        </el-dialog>





    </div>
</template>

<script>

// import PublicHead from '@/components/PublicHead.vue'
import FileImage from '@/components/FileImage.vue'
import PublicHead from '@/components/PublicHead.vue'
// import { region } from 'dada/src/main';

export default {
    name: 'ClassroomFileSharing',
    data() {
        return {
            shareFileList: [],
            searchFileName: '',
            UserIP: "",
            outerVisible: false,
            innerVisible: false,
            inner_innerVisible: false,
            showFolder: [],
            fileHoverIndex: null,
            fileClickIndex: null,
            form: {},
            FolderPath: null,

            //长按逻辑，触摸
            isHolding: false,
            holdTimeout: null,

            //当前点击文件是否可下载
            clickFileDownloadAble: false,
        }
    },
    components: {
        // Edit3D
        // PublicHead
        FileImage,
        PublicHead

    },

    computed: {





        //搜索校区分享文件
        // searchFileSchoolShareFiles(e) {

        //     console.log(e)
        //     // searchFileName



        // },


        getLoginState() {
            // console.log('新的vuex值',this.$store.getters.getShowLoginValue)
            return this.$store.getters.getLoginState;
        },

        WindowSize() {
            console.log('新的vuex值22', this.$store.getters.getWindowSize)
            return this.$store.getters.getWindowSize;
        },


    },

    mounted() {

        const that = this

        setTimeout(() => {
            that.TeacherGetSchoolShareFiles()

        }, 2500);
    },

    methods: {

        handleStart(event, index, FolderListItem) {
            // 阻止默认行为，比如文本选择等
            event.preventDefault();

            // 设置标记为 true，表示现在正在按下鼠标键
            this.isHolding = true;

            // 使用 setTimeout 来延迟执行 longHoldAction 方法，
            // 这样可以模拟一个 "长按" 的效果
            this.holdTimeout = setTimeout(() => {
                this.longHoldAction(event, index, FolderListItem);
            }, 500); // 假设长按的阈值是 500 毫秒
        },
        handleEnd() {
            if (this.isHolding && this.holdTimeout) {
                clearTimeout(this.holdTimeout);
            }
            this.isHolding = false;
        },
        longHoldAction(event, index, FolderListItem) {
            console.log('长按时触发的动作');
            // 在这里执行你需要的逻辑
            this.onContextmenu(event, index, FolderListItem)
        },

        onContextmenu(event, index, info) {

            const that = this


            console.log('event, index, info3333', event, index, info)

            // const that = this

            this.fileClickIndex = index

            this.fileClickIndexInfo = info

            this.form.FileName = info.name

            this.form.FilePath = info.path

            this.form.fileType = info.type

            console.log('event,index', event, index, this.fileClickIndexInfo)


            //找到顶级父级下的分享者和校区等信息
            const firstparent = this.findFirstParent(info)

            console.log('11firstparent呃呃呃呃1', firstparent)

            this.clickFileDownloadAble = firstparent.DownloadAble ? true : false


            const label = this.clickFileDownloadAble == true ? '下载' : "不可下载"

            this.$contextmenu({
                items: [
                    {
                        label: label,

                        icon: "el-icon-download",

                        disabled: !that.clickFileDownloadAble,

                        onClick: () => {
                            console.log('下载功能');

                            if (info.type == 'folder') {

                                that.$message({
                                    message: '目前仅支持下载文件，不支持文件夹',
                                    type: 'warning'
                                });

                                return

                            }

                            info.downloadable = true


                            that.downloadFile(info)

                            // 在这里实现下载功能
                        }

                        // children: [

                        //     { label: "敬请期待" }


                        // ]


                    }


                ],
                event,
                //x: event.clientX,
                //y: event.clientY,
                customClass: "custom-class",
                zIndex: 3,
                minWidth: 230,

            });
            return false;
        },


        //文件夹或者文件被点击
        async folderDBClick(index, info) {

            console.log('index, info', index, info)

            console.log('双击文件或者文件夹事件')

            if (info.type != 'folder') {


                //打开文件逻辑
                console.log('打开文件', info)

                //将临时密钥存到全局变量中
                //this.root.$ShareCloudFiles.setOpen_temporary_key_info(temporary_key_)





                console.log('保存临时密钥')
                await this.$root.$ShareCloudFiles_inSchool.set_temporary_key_info(info)


                //找到顶级父级下的分享者和校区等信息
                const firstparent = this.findFirstParent(info)

                console.log('11firstparent1', firstparent)


                //获取最新的密钥用来打开文件
                const temporary_key = this.$root.$ShareCloudFiles_inSchool.get_temporary_key_info()
                console.log('读取临时密钥www', temporary_key)

                // return 


                const loading = this.$loading({
                    lock: true,
                    text: '加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                setTimeout(() => {
                    loading.close();
                }, 10000);


                const resHand = await this.$root.$ShareCloudFiles_inSchool.doesObjectExist(info)

                console.log('resHand文件是否存在：', resHand)

                if (!resHand) {
                    loading.close();
                    this.$message.error('无法握手文件,文件似乎不见了');

                    return

                }
                // - statusCode 请求返回的 HTTP 状态码，例如200，304等，如果在指定时间后未被修改，则返回304


                if (!resHand.statusCode || !(resHand.statusCode == 200 || resHand.statusCode == 304)) {
                    loading.close();
                    this.$message.error('文件似乎不见了');

                    return

                }

                loading.close();
                console.log('temporary_keyeeee', temporary_key)
                // Bucket
                this.$store.dispatch('changeFilePlayerData', {
                    type: info.type,
                    Key: info.path,
                    temporary_key,
                    schoolName: firstparent.schoolName,
                    shareTeacher: firstparent.shareTeacher,
                    CreatorID: firstparent.CreatorID,
                    bucket: temporary_key.bucket,
                    region: temporary_key.region

                });

                console.log('11111temporary_key2222', info, temporary_key)


            } else {

                console.log('打开文件夹')
                //打开文件夹逻辑
                this.showFolder = this.showFolder.folder[index]
                //去除路径前面部分
                let arr = this.showFolder.path.split("/"); // 将字符串按照"/"分隔成数组
                arr = arr.slice(3); // 去除第一个元素
                let result = arr.join("/"); // 将数组重新拼接成字符串
                this.FolderPath = result

            }

        },

        findFirstParent(fileinfo) {
            var firstParent = fileinfo


            const loop = true
            //向文件上级查找，找到祖籍第一代看看有没有数据
            while (loop) {

                //上级如果是根目录，这个级别内理论上有临时密钥
                if (firstParent.upperFolder.isRootPath) {

                    break

                }

                firstParent = firstParent.upperFolder

            }

            return firstParent
        },

        async downloadFile(info) {



            console.log('downloadFile222333', info)

            if (info.downloadable == false) {

                console.error('不能下载')

                return

            }


            // this.$root.$ShareCloudFiles_inSchool.downloadFile()


            console.log('保存临时密钥33')
            await this.$root.$ShareCloudFiles_inSchool.set_temporary_key_info(info)


            //找到顶级父级下的分享者和校区等信息
            const firstparent = this.findFirstParent(info)

            console.log('11firstparent1', firstparent)


            //获取最新的密钥用来打开文件
            const temporary_key = this.$root.$ShareCloudFiles_inSchool.get_temporary_key_info()
            console.log('读取临时密钥www', temporary_key)

            // return 


            const loading = this.$loading({
                lock: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {
                loading.close();
            }, 10000);


            const resHand = await this.$root.$ShareCloudFiles_inSchool.doesObjectExist(info)

            console.log('resHand文件是否存在：', resHand)

            if (!resHand) {
                loading.close();
                this.$message.error('无法握手文件,文件似乎不见了');

                return

            }
            // - statusCode 请求返回的 HTTP 状态码，例如200，304等，如果在指定时间后未被修改，则返回304


            if (!resHand.statusCode || !(resHand.statusCode == 200 || resHand.statusCode == 304)) {
                loading.close();
                this.$message.error('文件似乎不见了');

                return

            }

            loading.close();
            console.log('temporary_keyeeee333', temporary_key)

            //
            //不是该用户的文件夹，则需要传入临时密钥
            const TemporaryKey = temporary_key

            const BucketInfo = {
                Bucket: TemporaryKey.bucket,
                Region: TemporaryKey.region
            }

            console.log('BucketInfo3332111', BucketInfo)

            const Key = info.path

            var res = await this.$root.$ShareCloudFiles_inSchool.getShareCloudMideaUrl(Key, TemporaryKey, BucketInfo)

            console.log('文件下载链接结果', res)


            //下载操作
            var link = document.createElement('a');
            link.href = res;
            // 设置下载的文件名，如果服务器允许的话
            // link.download = info.;

            // 把 a 元素添加到 body 中
            document.body.appendChild(link);

            // 模拟点击 a 元素
            link.click();

            // 下载完成后移除 a 元素
            document.body.removeChild(link);








        },

        folderClick(index, info) {

            console.log('index, info', index, info)

            this.fileClickIndex = index

            this.fileClickIndexInfo = info


            this.form.FileName = info.name

            this.form.FilePath = info.path

            this.form.fileType = info.type


        },

        FileMouseEnter(index) {

            this.fileHoverIndex = index

            // console.log('鼠标进入')

        },

        FileMouseLeave() {

            this.fileHoverIndex = null

            if (this.isHolding && this.holdTimeout) {
                clearTimeout(this.holdTimeout);
            }
            this.isHolding = false;

            // console.log('鼠标进出')

        },



        // async shareFileINLAN_ByIP() {
        //     console.log('this.form.FilePath333www', this.form)


        //     var Resoult

        //     var FilePath, FileType, FileName

        //     console.log('this.form4444', this.form)
        //     FilePath = this.form.FilePath
        //     FileType = this.form.fileType
        //     FileName = this.form.FileName

        //     //当前选择的校区id
        //     console.log('当前的校区信息222', this.$root.$schoolInfo)

        //     const school_id = this.$root.$schoolInfo._id

        //     // EffectiveDuration_Days = this.form.EffectiveDuration_Days
        //     // maximum_using_times = this.form.maximum_using_times

        //     console.log('FilePath, FileType,FileName,EffectiveDuration_Days,maximum_using_times')
        //     console.log('FilePath', FilePath)
        //     console.log('FileType', FileType)

        //     console.log('FileName', FileName)

        //     // console.log('EffectiveDuration_Days', EffectiveDuration_Days)

        //     // console.log('maximum_using_times', maximum_using_times)

        //     if (FilePath == null || FileType == null || FileName == null || school_id == null) {
        //         this.$message({
        //             message: '检查填写,',
        //             type: 'warning'
        //         });
        //         return
        //     }


        //     //         FilePath = event.FilePath
        //     // FileType = event.FileType
        //     // FileName = event.FileName
        //     // EffectiveDuration_Days = event.EffectiveDuration_Days
        //     // maximum_using_times = event.maximum_using_times

        //     console.log('this.$root.$app33333', this.$root.$app)
        //     // event.FilePath == null || event.FileType == null || event.FileName == null || event.ViewPassword == null

        //     try {
        //         Resoult = await this.$root.$app.callFunction({
        //             // 云函数名称

        //             name: "UserShareFileByLAN",

        //             // 传给云函数的参数
        //             data: {
        //                 FilePath,
        //                 FileType,
        //                 FileName,
        //                 school_id
        //             }
        //             //                 FilePath = event.FilePath
        //             // FileType = event.FileType
        //             // FileName = event.FileName

        //         })

        //         this.$alert('请让用户连接到校区局域网即可查看到你的分享', '分享成功', {
        //             confirmButtonText: '确定',
        //             callback: action => {
        //                 console.log(action)
        //                 // this.$message({
        //                 //     type: 'info',
        //                 //     message: `action: ${action}`
        //                 // });
        //             }
        //         });



        //     } catch (error) {
        //         console.error("复制云函数错误", error);
        //         this.$message({
        //             message: '操作失败',
        //             type: 'warning'
        //         });
        //         // const err = 
        //         return 'err'
        //     }


        //     console.log('分享的Resoult', Resoult)

        //     this.dialogFormVisible = false





        // },


















        clickFileImage(info) {
            console.log('点击事件', info)
            // vm.$route.params.FilePath, vm.$route.params.bucket, vm.$route.params.region,vm.$route.params.db_id
            this.$router.push({
                name: 'ClassFilesView', params: {

                    db_id: info._id
                    // FileShareID: this.form_3dDraw_View.FileShareID,
                    // ViewPassword: this.form_3dDraw_View.ViewPassword
                    // FileShareID: this.form_3dDraw_View.FileShareID,
                    // ViewPassword: this.form_3dDraw_View.ViewPassword
                }
            });
        },

        //获取校区分享的文件
        async TeacherGetSchoolShareFiles() {

            const loading = this.$loading({
                lock: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {
                loading.close();
            }, 10000);


            var that = this

            // var UserIP


            // console.log('that.$root.$app2222', that.$root.$app)

            // console.log('that.$root.$schoolInfo333', that.$root.$schoolInfo)

            // console.log('that.$root.$schoolInfo', that.$root.$schoolInfo._id)
            // const schoolid = that.$root.$schoolInfo._id

            try {

                // const res = await that.$root.$app.callFunction({

                //     name: "Teacher_Get_School_Share_files",

                //     data: {
                //         // this.$root.$schoolInfo
                //         school_ID: schoolid

                //     }

                // });

                console.log('that.$root.$app3344', that.$root.$app)

                const res = await that.$root.$app.callFunction({

                    name: "Get_LAN_files",

                });

                // shareFileList_ = res.result.LAN_shareFiles
                // UserIP = res.result.UserIP

                console.log('3344res', res)

                // that.shareFileList = res.result.school_shareFiles

                that.shareFileList = res.result.LAN_shareFiles

                const cloudbaseTime = res.result.Timestamp
                // that.UserIP = res.result.UserIP

                console.log('cloudbaseTime', cloudbaseTime)


                console.log('that.shareFileList3333', that.shareFileList)

                //数据的临时密钥如果没有就补充为null
                for (let i = 0; i < that.shareFileList.length; i++) {
                    // tmpSecretId
                    if (!that.shareFileList[i].TemporaryKey) {

                        that.shareFileList[i].TemporaryKey = {
                            sessionToken: null,
                            tmpSecretId: null,
                            tmpSecretKey: null
                        }

                    }
                }

                const shareFileList = that.shareFileList



                var rootFolder = {}

                var FolderList = []

                rootFolder = {
                    folder: FolderList,
                    name: 'SchoolShaherefolder',
                    path: null,
                    type: 'folder',
                    upperFolder: null,
                    isRootPath: true,

                }

                //将分享的文件和文件夹（数据库数据）生成一个新的文件夹
                for (let i = 0; i < shareFileList.length; i++) {

                    FolderList.push({
                        // folder: [],
                        name: shareFileList[i].FileName,
                        path: shareFileList[i].FilePath,
                        bucket: shareFileList[i].bucket,
                        region: shareFileList[i].region,
                        // size: 0,
                        type: shareFileList[i].FileType,
                        upperFolder: rootFolder,
                        database_id: shareFileList[i]._id,
                        temporaryKey: {
                            SecretId: shareFileList[i].TemporaryKey.tmpSecretId,
                            SecretKey: shareFileList[i].TemporaryKey.tmpSecretKey,
                            SecurityToken: shareFileList[i].TemporaryKey.sessionToken,
                            Expiration_timestamp: shareFileList[i].ExpirationTimestamp,
                        },
                        cloudbaseTime,
                        schoolName: shareFileList[i].bindSchoolName,
                        shareTeacher: shareFileList[i].CreatorNickname,
                        CreatorID: shareFileList[i].Creator,
                        DeviceTimestamp: Date.now(),
                        DownloadAble: shareFileList[i].Downloadable
                        //云端时间戳
                        //


                    })


                }






                console.log('FolderList234', FolderList)

                console.log('rootFolder333322qqq', rootFolder)

                this.showFolder = rootFolder


                //如果是文件夹，则获取文件夹的子文件（文件夹们）
                for (let i = 0; i < FolderList.length; i++) {

                    if (FolderList[i].type == "folder") {
                        //获取文件夹列表

                        // loading = this.$loading({
                        //     lock: true,
                        //     text: '获取文件列表中',
                        //     spinner: 'el-icon-loading',
                        //     background: 'rgba(0, 0, 0, 0.7)'
                        // });

                        // this.$root.$AllFilesInCloud
                        console.log('FolderList[i]', FolderList[i])

                        console.log(FolderList[i].path + '/')

                        console.log('this.$root.$ShareCloudFiles_inSchool3333', this.$root.$ShareCloudFiles_inSchool)

                        const res = await this.$root.$ShareCloudFiles_inSchool.getAllFilesInChooseFolder(FolderList[i])

                        // const res = await this.getIpSchoolData()

                        console.log('当前获取到的校区的文件夹列表是:', res)

                        //没有子文件，就迭代下一次循环
                        if (res == 'err') continue

                        var res_ = this.buildTree(res)
                        console.log('新整理出的文件夹格式是', res_)

                        //这个文件列表将会是从根目录往下的一级一级的形式，我要找到分享文件夹下面的内容
                        var ArrPath = FolderList[i].path.split('/')
                        // const length = ArrPath.length

                        console.log('length长度', ArrPath.length)

                        var children = res_

                        for (let k = 0; k < ArrPath.length; k++) {

                            // ArrPath
                            console.log('children.folder.length', children.folder.length)
                            for (let j = 0; j < children.folder.length; j++) {

                                console.log('children.folder[j]', children.folder[j])

                                if (children.folder[j].name == ArrPath[k]) {

                                    console.log('找到子级了', children.folder[j])

                                    children = children.folder[j]

                                }


                            }

                            // children = children.folder
                        }

                        console.log('这个分享文件下面的内容是', children)

                        //将这两个内容写到文件中

                        // var nowFolder = children

                        // for(nowFolder.children.length>0){

                        // }


                        FolderList[i].folder = []
                        FolderList[i].nawwmecc = 2222

                        console.log('FolderList[i].folder333', FolderList[i].folder)

                        console.log('children.folder.length', children.folder.length)
                        for (let l = 0; l < children.folder.length; l++) {


                            // FolderList
                            children.folder[l].upperFolder = FolderList[i]

                            console.log('children.folder[l]', children.folder[l])

                            console.log('l', l)

                            console.log('FolderList[i]', FolderList[i])

                            // console.log()


                            FolderList[i].folder.push(children.folder[l])


                        }

                        console.log('FolderList3332222223', FolderList)

                        //更新文件列表
                        // this.$root.$AllFilesInCloud = res

                        // loading.close();


                    }


                }


                // showFolder
                console.log('3showFolder3', this.showFolder)




                //如果获取到的文件数量是0，说明用户可能不在校区或者校区的ip变了
                // if (that.shareFileList.length == 0) {
                //     that.outerVisible = true
                //     //询问用户是不是在校区？
                // } else {
                //     //缓存校区id
                //     // that.shareFileList
                //     localStorage.setItem('MYDADASCHOOLID', that.shareFileList[0].bindSchool);
                // }

                //缓存我的ip

                // localStorage.setItem('MYDADAIP', that.UserIP);
                console.log('云函数2222ip', res)
                // console.log('localStorage.getItem(key)', localStorage.getItem('MYDADAIP'))

                // if(LAN_shareFiles ){

                // }

                loading.close();
                // this.callFunctionResult = res;
            } catch (e) {
                console.error(e)
                // this.callFunctionResult = e.message;

                // setTimeout(() => {
                loading.close();
                that.$message({
                    message: '加载失败',
                    type: 'warning'
                });
                // }, 2000);
            }

        },

        // async getIpSchoolData() {

        //     var shareFileList_

        //     const that = this

        //     // var UserIP 

        //     try {

        //         const res = await that.$root.$app.callFunction({
        //             name: "Get_LAN_files",

        //         });

        //         shareFileList_ = res.result.LAN_shareFiles
        //         // UserIP = res.result.UserIP

        //         //如果获取到的文件数量是0，说明用户可能不在校区或者校区的ip变了
        //         // if (shareFileList.length == 0) {
        //         //     that.outerVisible = true
        //         //     //询问用户是不是在校区？
        //         // } else {
        //         //     //缓存校区id
        //         //     // that.shareFileList
        //         //     localStorage.setItem('MYDADASCHOOLID', that.shareFileList[0].bindSchool);
        //         // }


        //     } catch (err) {

        //         console.log('errr',err)

        //     }

        //     return shareFileList_

        // },



        buildTree(paths) {
            const root = { name: '', path: '', folder: [] };

            paths.forEach(path => {
                let currentNode = root;
                const parts = path.Key.split('/');

                parts.forEach((part, index) => {
                    const existingFolder = currentNode.folder.find(f => f.name === part);

                    if (existingFolder) {
                        currentNode = existingFolder;
                    } else {
                        if (part != '') {
                            const newFolder = { name: part, size: 0, path: parts.slice(0, index + 1).join('/'), folder: [] };
                            currentNode.folder.push(newFolder);
                            //上级文件夹属性
                            newFolder.upperFolder = currentNode
                            newFolder.type = this.GetFileType(part)
                            newFolder.size = newFolder.type == 'folder' ? 0 : path.Size * 1
                            currentNode = newFolder;
                        }

                    }
                });
            });

            return root;
        },

        //根据名称判断文件类型
        GetFileType(name) {

            if (name.indexOf('.') == -1) {

                //文件夹
                return 'folder'

            } else {
                // var nameArr = name.split('.')

                const fileExtension = name.split('.').pop().toLowerCase();

                return fileExtension

            }

        },

        //返回上级菜单
        GoToUpperFolder() {

            if (this.showFolder.isRootPath) {

                this.$message({
                    message: '无上级文件夹',
                    type: 'warning'
                });

                return

            }

            this.LastShowFolder = this.showFolder

            this.showFolder = this.showFolder.upperFolder

            if (!this.showFolder.path) return

            //去除路径前面部分
            let arr = this.showFolder.path.split("/"); // 将字符串按照"/"分隔成数组
            arr = arr.slice(2); // 去除第一个元素
            let result = arr.join("/"); // 将数组重新拼接成字符串
            this.FolderPath = result


            // this.FolderPath = this.showFolder.path

        },



















        //

        async linkSchoolHeadToUpdateSchoolIP() {

            const loading = this.$loading({
                lock: true,
                text: '发信息联系校区负责人中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {
                loading.close();
            }, 10000);
            //检测本地是否有缓存的校区id

            const that = this


            const schoolID = localStorage.getItem('MYDADASCHOOLID')

            console.log('本地读取到的校区ip是', schoolID)

            if (!schoolID) {

                this.$notify({
                    title: '无法完成操作',
                    message: '您未在本地查看过任何作品，所以无法联系校区'
                });


                this.inner_innerVisible = true

                return

            }

            //发送邮件给校区负责人来更新本校区的ip和本校区所有分享文件对应的ip
            try {
                const Resoult = await this.$root.$app.callFunction({
                    // 云函数名称

                    name: "send-email",
                    data: {
                        schoolID
                    }

                    // name: "MakeUpMpdFIleFromStepFIle",
                    // name: "make_up_mpd_file_from_step_file_cos",


                    // 传给云函数的参数
                    // data: {
                    //   type: 'url',
                    //   // mpdFileID
                    // }
                })

                console.log('send-email结果', Resoult)

                //关闭对话框
                that.outerVisible = false
                that.innerVisible = false
                that.inner_innerVisible = false

                that.$message({
                    message: '已经发送消息提醒校区管理人',
                    type: 'success'
                });


            } catch (error) {
                console.error("send-email失败w", error);
                //关闭对话框
                that.outerVisible = false
                that.innerVisible = false
                that.inner_innerVisible = false

                this.$message.error('发送提醒消息失败');
            }



            loading.close();




        }

    },





}
</script>

<style>
.hoverStyle {
    background-color: #ecf0f1;
}

.fileContainer {

    width: 100%;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
    /* transform: translateX(calc((100% - width/2))) */
}
</style>