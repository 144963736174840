const materialLibraryTXT = {
    txt: `0 // LDraw Solid Colours
0                              // LEGOID  26 - Black
0 !COLOUR Black                                                 CODE   0   VALUE #05131D   EDGE #595959
0                              // LEGOID  23 - Bright Blue
0 !COLOUR Blue                                                  CODE   1   VALUE #0055BF   EDGE #333333
0                              // LEGOID  28 - Dark Green
0 !COLOUR Green                                                 CODE   2   VALUE #257A3E   EDGE #333333
0                              // LEGOID 107 - Bright Bluish Green
0 !COLOUR Dark_Turquoise                                        CODE   3   VALUE #00838F   EDGE #333333
0                              // LEGOID  21 - Bright Red
0 !COLOUR Red                                                   CODE   4   VALUE #C91A09   EDGE #333333
0                              // LEGOID 221 - Bright Purple
0 !COLOUR Dark_Pink                                             CODE   5   VALUE #C870A0   EDGE #333333
0                              // LEGOID 217 - Brown
0 !COLOUR Brown                                                 CODE   6   VALUE #583927   EDGE #1E1E1E
0                              // LEGOID   2 - Grey
0 !COLOUR Light_Grey                                            CODE   7   VALUE #9BA19D   EDGE #333333
0                              // LEGOID  27 - Dark Grey
0 !COLOUR Dark_Grey                                             CODE   8   VALUE #6D6E5C   EDGE #333333
0                              // LEGOID  45 - Light Blue
0 !COLOUR Light_Blue                                            CODE   9   VALUE #B4D2E3   EDGE #333333
0                              // LEGOID  37 - Bright Green
0 !COLOUR Bright_Green                                          CODE  10   VALUE #4B9F4A   EDGE #333333
0                              // LEGOID 116 - Medium Bluish Green
0 !COLOUR Light_Turquoise                                       CODE  11   VALUE #55A5AF   EDGE #333333
0                              // LEGOID   4 - Brick Red
0 !COLOUR Salmon                                                CODE  12   VALUE #F2705E   EDGE #333333
0                              // LEGOID   9 - Light Reddish Violet
0 !COLOUR Pink                                                  CODE  13   VALUE #FC97AC   EDGE #333333
0                              // LEGOID  24 - Bright Yellow
0 !COLOUR Yellow                                                CODE  14   VALUE #F2CD37   EDGE #333333
0                              // LEGOID   1 - White
0 !COLOUR White                                                 CODE  15   VALUE #FFFFFF   EDGE #333333
0                              // LEGOID   6 - Light Green
0 !COLOUR Light_Green                                           CODE  17   VALUE #C2DAB8   EDGE #333333
0                              // LEGOID   3 - Light Yellow
0 !COLOUR Light_Yellow                                          CODE  18   VALUE #FBE696   EDGE #333333
0                              // LEGOID   5 - Brick Yellow
0 !COLOUR Tan                                                   CODE  19   VALUE #E4CD9E   EDGE #333333
0                              // LEGOID  39 - Light Bluish Violet
0 !COLOUR Light_Violet                                          CODE  20   VALUE #C9CAE2   EDGE #333333
0                              // LEGOID 104 - Bright Violet
0 !COLOUR Purple                                                CODE  22   VALUE #81007B   EDGE #333333
0                              // LEGOID 196 - Dark Royal Blue
0 !COLOUR Dark_Blue_Violet                                      CODE  23   VALUE #2032B0   EDGE #1E1E1E
0                              // LEGOID 106 - Bright Orange
0 !COLOUR Orange                                                CODE  25   VALUE #FE8A18   EDGE #333333
0                              // LEGOID 124 - Bright Reddish Violet
0 !COLOUR Magenta                                               CODE  26   VALUE #923978   EDGE #333333
0                              // LEGOID 119 - Bright Yellowish Green
0 !COLOUR Lime                                                  CODE  27   VALUE #BBE90B   EDGE #333333
0                              // LEGOID 138 - Sand Yellow
0 !COLOUR Dark_Tan                                              CODE  28   VALUE #958A73   EDGE #333333
0                              // LEGOID 222 - Light Purple
0 !COLOUR Bright_Pink                                           CODE  29   VALUE #E4ADC8   EDGE #333333
0                              // LEGOID 324 - Medium Lavender
0 !COLOUR Medium_Lavender                                       CODE  30   VALUE #AC78BA   EDGE #333333
0                              // LEGOID 325 - Lavender
0 !COLOUR Lavender                                              CODE  31   VALUE #E1D5ED   EDGE #333333
0                              // LEGOID  36 - Light Yellowish Orange
0 !COLOUR Very_Light_Orange                                     CODE  68   VALUE #F3CF9B   EDGE #333333
0                              // LEGOID 198 - Bright Reddish Lilac
0 !COLOUR Bright_Reddish_Lilac                                  CODE  69   VALUE #CD6298   EDGE #333333
0                              // LEGOID 192 - Reddish Brown
0 !COLOUR Reddish_Brown                                         CODE  70   VALUE #582A12   EDGE #595959
0                              // LEGOID 194 - Medium Stone Grey
0 !COLOUR Light_Bluish_Grey                                     CODE  71   VALUE #A0A5A9   EDGE #333333
0                              // LEGOID 199 - Dark Stone Grey
0 !COLOUR Dark_Bluish_Grey                                      CODE  72   VALUE #6C6E68   EDGE #333333
0                              // LEGOID 102 - Medium Blue
0 !COLOUR Medium_Blue                                           CODE  73   VALUE #5C9DD1   EDGE #333333
0                              // LEGOID  29 - Medium Green
0 !COLOUR Medium_Green                                          CODE  74   VALUE #73DCA1   EDGE #333333
0                              // LEGOID 223 - Light Pink
0 !COLOUR Light_Pink                                            CODE  77   VALUE #FECCCF   EDGE #333333
0                              // LEGOID 283 - Light Nougat
0 !COLOUR Light_Flesh                                           CODE  78   VALUE #F6D7B3   EDGE #333333
0                              // LEGOID  38 - Dark Orange
0 !COLOUR Medium_Dark_Flesh                                     CODE  84   VALUE #CC702A   EDGE #333333
0                              // LEGOID 268 - Medium Lilac
0 !COLOUR Medium_Lilac                                          CODE  85   VALUE #3F3691   EDGE #1E1E1E
0                              // LEGOID 312 - Medium Nougat
0 !COLOUR Dark_Flesh                                            CODE  86   VALUE #7C503A   EDGE #333333
0                              // LEGOID 195 - Medium Royal Blue
0 !COLOUR Blue_Violet                                           CODE  89   VALUE #4C61DB   EDGE #333333
0                              // LEGOID  18 - Nougat
0 !COLOUR Flesh                                                 CODE  92   VALUE #D09168   EDGE #333333
0                              // LEGOID 100 - Light Red
0 !COLOUR Light_Salmon                                          CODE 100   VALUE #FEBABD   EDGE #333333
0                              // LEGOID 110 - Bright Bluish Violet
0 !COLOUR Violet                                                CODE 110   VALUE #4354A3   EDGE #333333
0                              // LEGOID 112 - Medium Bluish Violet
0 !COLOUR Medium_Violet                                         CODE 112   VALUE #6874CA   EDGE #333333
0                              // LEGOID 115 - Medium Yellowish Green
0 !COLOUR Medium_Lime                                           CODE 115   VALUE #C7D23C   EDGE #333333
0                              // LEGOID 118 - Light Bluish Green
0 !COLOUR Aqua                                                  CODE 118   VALUE #B3D7D1   EDGE #333333
0                              // LEGOID 120 - Light Yellowish Green
0 !COLOUR Light_Lime                                            CODE 120   VALUE #D9E4A7   EDGE #333333
0                              // LEGOID 125 - Light Orange
0 !COLOUR Light_Orange                                          CODE 125   VALUE #F9BA61   EDGE #333333
0                              // LEGOID 208 - Light Stone Grey
0 !COLOUR Very_Light_Bluish_Grey                                CODE 151   VALUE #E6E3E0   EDGE #333333
0                              // LEGOID 191 - Flame Yellowish Orange
0 !COLOUR Bright_Light_Orange                                   CODE 191   VALUE #F8BB3D   EDGE #333333
0                              // LEGOID 212 - Light Royal Blue
0 !COLOUR Bright_Light_Blue                                     CODE 212   VALUE #86C1E1   EDGE #333333
0                              // LEGOID 216 - Rust
0 !COLOUR Rust                                                  CODE 216   VALUE #B31004   EDGE #333333
0                              // LEGOID 226 - Cool Yellow
0 !COLOUR Bright_Light_Yellow                                   CODE 226   VALUE #FFF03A   EDGE #333333
0                              // LEGOID 232 - Dove Blue
0 !COLOUR Sky_Blue                                              CODE 232   VALUE #56BED6   EDGE #333333
0                              // LEGOID 140 - Earth Blue
0 !COLOUR Dark_Blue                                             CODE 272   VALUE #0D325B   EDGE #1E1E1E
0                              // LEGOID 141 - Earth Green
0 !COLOUR Dark_Green                                            CODE 288   VALUE #184632   EDGE #595959
0                              // LEGOID 308 - Dark Brown
0 !COLOUR Dark_Brown                                            CODE 308   VALUE #352100   EDGE #595959
0                              // LEGOID  11 - Pastel Blue
0 !COLOUR Maersk_Blue                                           CODE 313   VALUE #54A9C8   EDGE #333333
0                              // LEGOID 154 - New Dark Red
0 !COLOUR Dark_Red                                              CODE 320   VALUE #720E0F   EDGE #333333
0                              // LEGOID 321 - Dark Azur
0 !COLOUR Dark_Azure                                            CODE 321   VALUE #1498D7   EDGE #333333
0                              // LEGOID 322 - Medium Azur
0 !COLOUR Medium_Azure                                          CODE 322   VALUE #3EC2DD   EDGE #333333
0                              // LEGOID 323 - Aqua
0 !COLOUR Light_Aqua                                            CODE 323   VALUE #BDDCD8   EDGE #333333
0                              // LEGOID 326 - Spring Yellowish Green
0 !COLOUR Yellowish_Green                                       CODE 326   VALUE #DFEEA5   EDGE #333333
0                              // LEGOID 330 - Olive Green
0 !COLOUR Olive_Green                                           CODE 330   VALUE #9B9A5A   EDGE #333333
0                              // LEGOID 153 - Sand Red
0 !COLOUR Sand_Red                                              CODE 335   VALUE #D67572   EDGE #333333
0                              // LEGOID  22 - Medium Reddish Violet
0 !COLOUR Medium_Dark_Pink                                      CODE 351   VALUE #F785B1   EDGE #333333
0                              // LEGOID  25 - Earth Orange
0 !COLOUR Earth_Orange                                          CODE 366   VALUE #FA9C1C   EDGE #333333
0                              // LEGOID 136 - Sand Violet
0 !COLOUR Sand_Purple                                           CODE 373   VALUE #845E84   EDGE #333333
0                              // LEGOID 151 - Sand Green
0 !COLOUR Sand_Green                                            CODE 378   VALUE #A0BCAC   EDGE #333333
0                              // LEGOID 135 - Sand Blue
0 !COLOUR Sand_Blue                                             CODE 379   VALUE #597184   EDGE #333333
0                              // LEGOID  12 - Light Orange Brown
0 !COLOUR Fabuland_Brown                                        CODE 450   VALUE #B67B50   EDGE #333333
0                              // LEGOID 105 - Bright Yellowish Orange
0 !COLOUR Medium_Orange                                         CODE 462   VALUE #FFA70B   EDGE #333333
0                              // LEGOID  38 - Dark Orange
0 !COLOUR Dark_Orange                                           CODE 484   VALUE #A95500   EDGE #333333
0                              // LEGOID 103 - Light Grey
0 !COLOUR Very_Light_Grey                                       CODE 503   VALUE #E6E3DA   EDGE #333333
0                              // LEGOID 218 - Reddish Lilac
0 !COLOUR Reddish_Lilac                                         CODE 218   VALUE #8E5597   EDGE #333333
0                              // LEGOID 295 - Flamingo Pink
0 !COLOUR Flamingo_Pink                                         CODE 295   VALUE #FF94C2   EDGE #333333
0                              // LEGOID 219 - Lilac
0 !COLOUR Lilac                                                 CODE 219   VALUE #564E9D   EDGE #333333
0                              // LEGOID 128 - Dark Nougat
0 !COLOUR Dark_Nougat                                           CODE 128   VALUE #AD6140   EDGE #333333


0 // LDraw Transparent Colours
0                              // LEGOID  40 - Transparent
0 !COLOUR Trans_Clear                                           CODE  47   VALUE #FCFCFC   EDGE #C3C3C3   ALPHA 128
0                              // LEGOID 111 - Transparent Brown
0 !COLOUR Trans_Black                                           CODE  40   VALUE #635F52   EDGE #171316   ALPHA 128
0                              // LEGOID  41 - Transparent Red
0 !COLOUR Trans_Red                                             CODE  36   VALUE #C91A09   EDGE #880000   ALPHA 128
0                              // LEGOID  47 - Transparent Fluorescent Reddish Orange
0 !COLOUR Trans_Neon_Orange                                     CODE  38   VALUE #FF800D   EDGE #BD2400   ALPHA 128
0                              // LEGOID 182 - Trans Bright Orange
0 !COLOUR Trans_Orange                                          CODE  57   VALUE #F08F1C   EDGE #A45C28   ALPHA 128
0                              // LEGOID 157 - Transparent Fluorescent Yellow
0 !COLOUR Trans_Neon_Yellow                                     CODE  54   VALUE #DAB000   EDGE #C3BA3F   ALPHA 128
0                              // LEGOID  44 - Transparent Yellow
0 !COLOUR Trans_Yellow                                          CODE  46   VALUE #F5CD2F   EDGE #8E7400   ALPHA 128
0                              // LEGOID  49 - Transparent Fluorescent Green
0 !COLOUR Trans_Neon_Green                                      CODE  42   VALUE #C0FF00   EDGE #84C300   ALPHA 128
0                              // LEGOID 311 / 227 - Transparent Bright Green / Transparent Bright Yellowish Green 
0 !COLOUR Trans_Bright_Green                                    CODE  35   VALUE #56E646   EDGE #9DA86B   ALPHA 128
0                              // LEGOID  48 - Transparent Green
0 !COLOUR Trans_Green                                           CODE  34   VALUE #237841   EDGE #1E6239   ALPHA 128
0                              // LEGOID  43 - Transparent Blue
0 !COLOUR Trans_Dark_Blue                                       CODE  33   VALUE #0020A0   EDGE #000064   ALPHA 128
0                              // LEGOID 143 - Transparent Fluorescent Blue
0 !COLOUR Trans_Medium_Blue                                     CODE  41   VALUE #559AB7   EDGE #196973   ALPHA 128
0                              // LEGOID  42 - Transparent Light Blue
0 !COLOUR Trans_Light_Blue                                      CODE  43   VALUE #AEE9EF   EDGE #72B3B0   ALPHA 128
0                              // LEGOID 229 - Transparent Light Bluish Green
0 !COLOUR Trans_Very_Light_Blue                                 CODE  39   VALUE #C1DFF0   EDGE #85A3B4   ALPHA 128
0                              // LEGOID 236 - Transparent Bright Reddish Lilac
0 !COLOUR Trans_Bright_Reddish_Lilac                            CODE  44   VALUE #96709F   EDGE #5A3463   ALPHA 128
0                              // LEGOID 126 - Transparent Bright Bluish Violet
0 !COLOUR Trans_Purple                                          CODE  52   VALUE #A5A5CB   EDGE #280025   ALPHA 128
0                              // LEGOID 113 - Transparent Medium Reddish Violet
0 !COLOUR Trans_Dark_Pink                                       CODE  37   VALUE #DF6695   EDGE #A32A59   ALPHA 128
0                              // LEGOID 230 - Transparent Bright Pink
0 !COLOUR Trans_Pink                                            CODE  45   VALUE #FC97AC   EDGE #A8718C   ALPHA 128
0                              // LEGOID 285 - Transparent Light Green 
0 !COLOUR Trans_Light_Green                                     CODE  285  VALUE #7DC291   EDGE #52805F   ALPHA 128
0                              // LEGOID 234 - Transparent Fire Yellow
0 !COLOUR Trans_Fire_Yellow                                     CODE  234  VALUE #FBE890   EDGE #BAAB6A   ALPHA 128
0                              // LEGOID 293 - Transparent Light Royal Blue
0 !COLOUR Trans_Light_Blue_Violet                               CODE  293  VALUE #6BABE4   EDGE #4D7BA3   ALPHA 128
0                              // LEGOID 231 - Transparent Flame Yellowish Orange
0 !COLOUR Trans_Bright_Light_Orange                             CODE  231  VALUE #FCB76D   EDGE #BD8951   ALPHA 128
0                              // LEGOID 284 - Transparent Reddish Lilac
0 !COLOUR Trans_Reddish_Lilac                                   CODE  284  VALUE #C281A5   EDGE #82566E   ALPHA 128


0 // LDraw Chrome Colours
0                              // LEGOID 299 - Warm Gold Drum Lacq
0 !COLOUR Chrome_Gold                                           CODE 334   VALUE #BBA53D   EDGE #BBB23D                               CHROME
0                              // LEGOID 298 - Cool Silver Drum Lacq
0 !COLOUR Chrome_Silver                                         CODE 383   VALUE #E0E0E0   EDGE #A4A4A4                               CHROME
0                              // LEGOID 187 - Metallic Earth Orange
0 !COLOUR Chrome_Antique_Brass                                  CODE  60   VALUE #645A4C   EDGE #281E10                               CHROME
0 !COLOUR Chrome_Black                                          CODE  64   VALUE #1B2A34   EDGE #595959                               CHROME
0                              // LEGOID 185 - Metallic Bright Blue
0 !COLOUR Chrome_Blue                                           CODE  61   VALUE #6C96BF   EDGE #202A68                               CHROME
0                              // LEGOID 147 - Metallic Dark Green
0 !COLOUR Chrome_Green                                          CODE  62   VALUE #3CB371   EDGE #007735                               CHROME
0 !COLOUR Chrome_Pink                                           CODE  63   VALUE #AA4D8E   EDGE #6E1152                               CHROME


0 // LDraw Pearl Colours
0                              // LEGOID 183 - Metallic White
0 !COLOUR Pearl_White                                           CODE 183   VALUE #F2F3F2   EDGE #333333                               PEARLESCENT
0                              // LEGOID 150 - Metallic Light Grey
0 !COLOUR Pearl_Very_Light_Grey                                 CODE 150   VALUE #BBBDBC   EDGE #333333                               PEARLESCENT
0                              // LEGOID 179 / 296 / 131 / 315 - Silver Flip-flop / Cool Silver / Silver / Silver Metallic
0 !COLOUR Pearl_Light_Grey                                      CODE 135   VALUE #9CA3A8   EDGE #333333                               PEARLESCENT
0                              // LEGOID 131 - Silver
0 !COLOUR Flat_Silver                                           CODE 179   VALUE #898788   EDGE #333333                               PEARLESCENT
0                              // LEGOID 148 - Metallic Dark Grey
0 !COLOUR Pearl_Dark_Grey                                       CODE 148   VALUE #575857   EDGE #333333                               PEARLESCENT
0                              // LEGOID 145 - Sand Blue Metallic
0 !COLOUR Metal_Blue                                            CODE 137   VALUE #5677BA   EDGE #333333                               PEARLESCENT
0                              // LEGOID 127 - Gold
0 !COLOUR Pearl_Light_Gold                                      CODE 142   VALUE #DCBE61   EDGE #333333                               PEARLESCENT
0                              // LEGOID 297 - Warm Gold
0 !COLOUR Pearl_Gold                                            CODE 297   VALUE #CC9C2B   EDGE #333333                               PEARLESCENT
0                              // LEGOID 147 - Metallic Sand Yellow
0 !COLOUR Flat_Dark_Gold                                        CODE 178   VALUE #B4883E   EDGE #333333                               PEARLESCENT
0                              // LEGOID 139 - Copper
0 !COLOUR Copper                                                CODE 134   VALUE #964A27   EDGE #333333                               PEARLESCENT
0                              // LEGOID 189 - Reddish Gold
0 !COLOUR Reddish_Gold                                          CODE 189   VALUE #AC8247   EDGE #333333                               PEARLESCENT


0 // LDraw Metallic Colours
0                              // LEGOID 315 - Silver Metallic
0 !COLOUR Metallic_Silver                                       CODE  80   VALUE #A5A9B4   EDGE #333333                               METAL
0                              // LEGOID 200 - Lemon Metallic
0 !COLOUR Metallic_Green                                        CODE  81   VALUE #899B5F   EDGE #333333                               METAL
0                              // LEGOID 310 / 335  Metalized Gold / Gold Ink
0 !COLOUR Metallic_Gold                                         CODE  82   VALUE #DBAC34   EDGE #333333                               METAL
0                              // LEGOID 149 - Metallic Black
0 !COLOUR Metallic_Black                                        CODE  83   VALUE #1A2831   EDGE #333333                               METAL
0                              // LEGOID 309 / 336 - Metalized Silver / Silver Ink
0 !COLOUR Metallic_Dark_Grey                                    CODE  87   VALUE #6D6E5C   EDGE #333333                               METAL
0                              // LEGOID 300 / 334 - Copper Drum Lacq / Copper Ink
0 !COLOUR Metallic_Copper                                       CODE 300   VALUE #C27F53   EDGE #333333                               METAL
0                              // LEGOID 184 - Metallic Bright Red
0 !COLOUR Metallic_Bright_Red                                   CODE 184   VALUE #D60026   EDGE #333333                               METAL
0                              // LEGOID 186 - Metallic Dark Green
0 !COLOUR Metallic_Dark_Green                                   CODE 186   VALUE #008E3C   EDGE #333333                               METAL


0 // LDraw Milky Colours
0                              // LEGOID  20 - Nature
0 !COLOUR Milky_White                                           CODE  79   VALUE #FFFFFF   EDGE #C3C3C3   ALPHA 240
0                              // LEGOID 294 - Phosphorescent Green
0 !COLOUR Glow_In_Dark_Opaque                                   CODE  21   VALUE #E0FFB0   EDGE #A4C374   ALPHA 240   LUMINANCE 15
0                              // LEGOID  50 - Phosphorescent White
0 !COLOUR Glow_In_Dark_Trans                                    CODE 294   VALUE #BDC6AD   EDGE #818A71   ALPHA 240   LUMINANCE 15
0                              // LEGOID 329 - White Glow
0 !COLOUR Glow_In_Dark_White                                    CODE 329   VALUE #F5F3D7   EDGE #B5B49F   ALPHA 240   LUMINANCE 15


0 // LDraw Glitter Colours
0                              // LEGOID 114 - Tr. Medium Reddish-Violet w. Glitter 2%
0 !COLOUR Glitter_Trans_Dark_Pink                               CODE 114   VALUE #DF6695   EDGE #9A2A66   ALPHA 128                   MATERIAL GLITTER VALUE #923978 FRACTION 0.17 VFRACTION 0.2 SIZE 1
0                              // LEGOID 117 - Transparent Glitter
0 !COLOUR Glitter_Trans_Clear                                   CODE 117   VALUE #FFFFFF   EDGE #C3C3C3   ALPHA 128                   MATERIAL GLITTER VALUE #FFFFFF FRACTION 0.08 VFRACTION 0.1 SIZE 1
0                              // LEGOID 129 - Tr. Bright Bluish Violet w. Glitter 2%
0 !COLOUR Glitter_Trans_Purple                                  CODE 129   VALUE #640061   EDGE #280025   ALPHA 128                   MATERIAL GLITTER VALUE #8C00FF FRACTION 0.3 VFRACTION 0.4 SIZE 1
0                              // LEGOID 302  Tr. Light Blue with Glitter 2%
0 !COLOUR Glitter_Trans_Light_Blue                              CODE 302   VALUE #AEE9EF   EDGE #72B3B0   ALPHA 128                   MATERIAL GLITTER VALUE #923978 FRACTION 0.17 VFRACTION 0.2 SIZE 1
0                              // LEGOID 339  Tr Fluorescent Green with Glitter 2%
0 !COLOUR Glitter_Trans_Neon_Green                              CODE 339   VALUE #C0FF00   EDGE #84C300   ALPHA 128                   MATERIAL GLITTER VALUE #923978 FRACTION 0.17 VFRACTION 0.2 SIZE 1 


0 // LDraw Speckle Colours
0 !COLOUR Speckle_Black_Silver                                  CODE 132   VALUE #000000   EDGE #898788                               MATERIAL SPECKLE VALUE #898788 FRACTION 0.4 MINSIZE 1 MAXSIZE 3
0                              // LEGOID 132 - Black Glitter
0 !COLOUR Speckle_Black_Gold                                    CODE 133   VALUE #000000   EDGE #DBAC34                               MATERIAL SPECKLE VALUE #DBAC34 FRACTION 0.4 MINSIZE 1 MAXSIZE 3
0 !COLOUR Speckle_Black_Copper                                  CODE  75   VALUE #000000   EDGE #AB6038                               MATERIAL SPECKLE VALUE #AB6038 FRACTION 0.4 MINSIZE 1 MAXSIZE 3
0 !COLOUR Speckle_Dark_Bluish_Grey_Silver                       CODE  76   VALUE #635F61   EDGE #898788                               MATERIAL SPECKLE VALUE #898788 FRACTION 0.4 MINSIZE 1 MAXSIZE 3


0 // LDraw Rubber Colours
0 !COLOUR Rubber_Yellow                                         CODE  65   VALUE #F5CD2F   EDGE #333333                               RUBBER
0 !COLOUR Rubber_Trans_Yellow                                   CODE  66   VALUE #CAB000   EDGE #8E7400   ALPHA 128                   RUBBER
0 !COLOUR Rubber_Trans_Clear                                    CODE  67   VALUE #FFFFFF   EDGE #C3C3C3   ALPHA 128                   RUBBER
0 !COLOUR Rubber_Black                                          CODE 256   VALUE #212121   EDGE #595959                               RUBBER
0 !COLOUR Rubber_Blue                                           CODE 273   VALUE #0033B2   EDGE #333333                               RUBBER
0 !COLOUR Rubber_Red                                            CODE 324   VALUE #C40026   EDGE #333333                               RUBBER
0 !COLOUR Rubber_Orange                                         CODE 350   VALUE #D06610   EDGE #333333                               RUBBER
0 !COLOUR Rubber_Light_Grey                                     CODE 375   VALUE #C1C2C1   EDGE #333333                               RUBBER
0 !COLOUR Rubber_Dark_Blue                                      CODE 406   VALUE #001D68   EDGE #595959                               RUBBER
0 !COLOUR Rubber_Purple                                         CODE 449   VALUE #81007B   EDGE #333333                               RUBBER
0 !COLOUR Rubber_Lime                                           CODE 490   VALUE #D7F000   EDGE #333333                               RUBBER
0 !COLOUR Rubber_Light_Bluish_Grey                              CODE 496   VALUE #A3A2A4   EDGE #333333                               RUBBER
0 !COLOUR Rubber_Flat_Silver                                    CODE 504   VALUE #898788   EDGE #333333                               RUBBER
0 !COLOUR Rubber_White                                          CODE 511   VALUE #FAFAFA   EDGE #333333                               RUBBER


0 // LDraw Internal Common Material Colours
0 !COLOUR Main_Colour                                           CODE  16   VALUE #FFFF80   EDGE #333333
0 !COLOUR Edge_Colour                                           CODE  24   VALUE #7F7F7F   EDGE #333333
0                              // LEGOID 109 - Black IR
0 !COLOUR Trans_Black_IR_Lens                                   CODE  32   VALUE #000000   EDGE #333333   ALPHA 210
0 !COLOUR Magnet                                                CODE 493   VALUE #656761   EDGE #595959                               METAL
0 !COLOUR Electric_Contact_Alloy                                CODE 494   VALUE #D0D0D0   EDGE #333333                               METAL
0 !COLOUR Electric_Contact_Copper                               CODE 495   VALUE #AE7A59   EDGE #333333                               METAL
0`}

console.log('materialLibraryTXT',materialLibraryTXT)

// 导出对象
export { materialLibraryTXT };



