//设备时间戳
// var Device_timestamp
//时间戳时间差
var timeDifference

var temporaryKey

// var Bucket = 'dadastar-1307054034'
// var Region = 'ap-shanghai'

var userInfo


// import tcb from "@cloudbase/js-sdk"
import COS from 'cos-js-sdk-v5';
// import { region } from "dada/src/main";
var app, auth
// , school_id
// var app = tcb.init({
//     env: "cloudbase-baas-5g5r8gfv6e64cb31"
// })
// const auth = this.app.auth();
//local	在本地存储中长期地保留登录状态。
// const auth = app.auth({
//     persistence: "local"
// });
// const auth = {}


var cos

var temporary_key

// var uploadFile_to_tempFolder

//存储文件列表
// var allFilesArr = []

class ShareCloudFiles_inSchool {

    constructor(userInfo_,) {

        userInfo = userInfo_

        console.log('文件处理工具的实例化传入数据是:', userInfo)

    }

    init(app_cloudbase, auth_cloudbase) {
        // , schoolInfo_id
        app = app_cloudbase
        auth = auth_cloudbase
        // school_id = schoolInfo_id
        console.log('app,auth', app, auth)
    }

    //临时存放密钥，在其它地方好取出来使用
    async set_temporary_key_info(fileinfo) {

        console.log('传入的分享文件是', fileinfo)


        await this.updateNewTempSecretKey(fileinfo)

        // async updateNewTempSecretKey(folderInfo) {

        // const needUpdateSecretKey = this.isTemporaryKeyExpired(folderInfo)

        // console.log('需要更新密钥吗？', needUpdateSecretKey)

        var firstParent = fileinfo


        const loop = true
        //向文件上级查找，找到祖籍第一代看看有没有数据
        while (loop) {

            //上级如果是根目录，这个级别内理论上有临时密钥
            if (firstParent.upperFolder.isRootPath) {

                break

            }

            firstParent = firstParent.upperFolder

        }

        console.log('最上级文件夹我找到了223311144ww44', firstParent)




        console.log('存储的临时文件操作密钥444是', fileinfo)

        //打开文件前保存密钥，打开文件的时候可用
        temporary_key = firstParent.temporaryKey
        temporary_key.bucket = firstParent.bucket
        temporary_key.region = firstParent.region





        // get_temporary_key_info
        // temporary_key={

        // }

        //
        // temporary_key = TemporaryKey
    }

    //在外面获取这个临时密钥
    get_temporary_key_info() {

        return temporary_key

    }

    // super();


    Confirm_login_status() {

        // 应用初始化时
        // auth.hasLoginState()
        if (auth.hasLoginState()) {
            // 此时已经登录
            console.log('已经登录')
            return true
        } else {
            console.log('未登录')

            return false

            // 此时未登录，执行您的登录流程
        }

    }

    //获取cos私有文件授权
    async getCosAuthorization(Key) {
        // getFileAuthorization

        // await this.updateNewTempSecretKey()

        try {
            const Resoult = await app.callFunction({
                // 云函数名称

                name: "getFileAuthorization",
                // name: "MakeUpMpdFIleFromStepFIle",
                // name: "make_up_mpd_file_from_step_file_cos",

                // 传给云函数的参数
                data: {
                    Key
                    // type: 'url',
                    // mpdFileID
                }
            })

            console.log('cos文件访问授权Authorization结果', Resoult)



            return Resoult.result



        } catch (error) {

            console.error("临时密钥错误", error);
            const err = 'err'
            return err
        }
    }

    async updateNewTempSecretKey(folderInfo) {

        const needUpdateSecretKey = this.isTemporaryKeyExpired(folderInfo)

        console.log('需要更新密钥吗？', needUpdateSecretKey)

        var firstParent = folderInfo


        const loop = true
        //向文件上级查找，找到祖籍第一代看看有没有数据
        while (loop) {

            //上级如果是根目录，这个级别内理论上有临时密钥
            if (firstParent.upperFolder.isRootPath) {

                break

            }

            firstParent = firstParent.upperFolder

        }

        console.log('最上级文件夹我找到了2233111', firstParent)


        const share_database_id = firstParent.database_id

        // var viewKey = firstParent.path

        // if (firstParent.type == 'folder') {

        // } else {

        // }


        if (needUpdateSecretKey) {
            //过期状态则重新获取密钥

            try {
                const Resoult = await app.callFunction({
                    // 云函数名称

                    name: "SecretKey_share_in_school",
                    // name: "MakeUpMpdFIleFromStepFIle",
                    // name: "make_up_mpd_file_from_step_file_cos",

                    // 传给云函数的参数
                    data: {
                        // schoolId: school_id,
                        share_database_id,
                        bucket: firstParent.bucket,

                        region: firstParent.region,

                        //     type: 'upload',
                        //     // mpdFileID
                    }
                })



                console.log('云函数生成的临时密钥结果', Resoult)

                var SecretInfo = Resoult.result.result

                if (Resoult.result.result.credentials) {
                    temporaryKey = Resoult.result.result
                    //设备时间差
                    var timestamp = new Date().getTime();
                    // timestamp = Math.floor(timestamp / 1000)
                    console.log(timestamp);

                    //真实时间-系统时间
                    timeDifference = Resoult.result.result.startTime - timestamp

                }


           
         
                //更新文件夹顶端的临时密钥
                firstParent.temporaryKey.Expiration_timestamp = SecretInfo.expiredTime
                firstParent.temporaryKey.SecretId= SecretInfo.credentials.tmpSecretId,
                firstParent.temporaryKey.SecretKey = SecretInfo.credentials.tmpSecretKey,
                firstParent.temporaryKey.SecurityToken = SecretInfo.credentials. sessionToken
 


                cos = new COS({
                    SecretId: SecretInfo.credentials.tmpSecretId,
                    SecretKey: SecretInfo.credentials.tmpSecretKey,
                    SecurityToken: SecretInfo.credentials.sessionToken,
                });


            } catch (error) {
                console.error("临时密钥错误", error);

                // const err = 
                return 'err'
            }
        } else {

            //不需要更新，用数据库的临时密钥
            cos = new COS({
                SecretId: firstParent.temporaryKey.SecretId,
                SecretKey: firstParent.temporaryKey.SecretKey,
                SecurityToken: firstParent.temporaryKey.SecurityToken,
            });

        }
    }

    // async getTempSecretKeyInCOS() {

    //     await this.updateNewTempSecretKey()

    //     cos.getObject({
    //         Bucket: userInfo.userBucket,
    //         Region: userInfo.userRegion,
    //         // Key: 'DADAdata/FileList.txt',
    //         Key: 'UsersFolder/Administrator/MyFolder/qiutou.ldr',

    //     }, function (err, data) {
    //         console.log('获取到的文件', data || err)
    //         // 在这里处理获取到的对象文件
    //     })

    //     cos.getBucket({
    //         Bucket: userInfo.userBucket,/* 填入您自己的存储桶，必须字段 */
    //         Region: userInfo.userRegion,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
    //         // UsersFolder/Administrator/SystemFolder/
    //         Prefix: 'UsersFolder/Administrator/MyFolder/',           /* Prefix表示列出的object的key以prefix开始，非必须 */
    //         // Prefix: 'UsersFolder/Administrator/SystemFolder/',           /* Prefix表示列出的object的key以prefix开始，非必须 */

    //         Delimiter: '/',
    //     }, function (err, data) {

    //         console.log('列出对象', err || data.Contents);
    //     });

    // }

    // getFolderList() {
    //     cos.getBucket({
    //         Bucket: 'dadastar-1307054034', /* 填入您自己的存储桶，必须字段 */
    //         Region: 'ap-shanghai',  /* 存储桶所在地域，例如ap-beijing，必须字段 */
    //         Prefix: 'UsersFolder/Administrator/SystemFolder/',           /* Prefix表示列出的object的key以prefix开始，非必须 */
    //         Delimiter: '/',
    //     }, function (err, data) {

    //         console.log('列出对象', err || data.Contents);
    //     });
    // }

    async getShareCloudMideaUrl(key, TemporaryKey, BucketInfo) {
        // const TemporaryKey = fileinfo.
        // this.updateNewTempSecretKey()
        console.log('TemporaryKey33333', TemporaryKey)
        var CosShare = new COS({
            SecretId: TemporaryKey.SecretId,
            SecretKey: TemporaryKey.SecretKey,
            SecurityToken: TemporaryKey.SecurityToken,
        });

        // Bucket: BucketInfo.Bucket,
        // Region: BucketInfo.Region,

        try {
            // await this.updateNewTempSecretKey()
            const res = await this.getMediaUrl_Share(CosShare, key, BucketInfo.Bucket, BucketInfo.Region)
            return res
        } catch (err) {
            console.log('错误,', err)
            return 'err'
        }


    }


    getBucket(prefix, Marker, userBucket, userRegion) {

        console.log('获取列表的数据', prefix, Marker, userBucket, userRegion)

        return new Promise((resolve, reject) => {
            cos.getBucket({
                Bucket: userBucket,
                Region: userRegion,
                Prefix: prefix,
                Marker,
                // Delimiter: '/',

            }, function (err, data) {
                {
                    console.log(reject, err)
                    console.log('列出对象eeeee', data)
                    resolve(data || err);
                }
            });
        });
    }

    //不考虑缓存中的文件,获取分享者的文件,需要传入临时密钥相关的数据
    async getObject_fromSharer(Key, onProgressCallback, TemporaryKey, BucketInfo) {
        // var that = this

        // await this.updateNewTempSecretKey()
        var CosShare = new COS({
            SecretId: TemporaryKey.tmpSecretId,
            SecretKey: TemporaryKey.tmpSecretKey,
            SecurityToken: TemporaryKey.sessionToken,
        });
        // BucketInfo


        return new Promise((resolve, reject) => {
            CosShare.getObject({
                Bucket: BucketInfo.Bucket,
                Region: BucketInfo.Region,
                Key,
                ResponseCacheControl: 'no-cache',

                onProgress: function (progressData) {
                    console.log('下载进度3333', JSON.stringify(progressData));
                    onProgressCallback(Math.floor(progressData.loaded / progressData.total * 100))
                }

            }, function (err, data) {
                {
                    console.log(reject, err)
                    console.log('下载对象', data)
                    resolve(data);
                }
            });
        });

    }

    //不考虑缓存中的文件
    async getObject(Key, onProgressCallback) {
        // var that = this

        await this.updateNewTempSecretKey()

        return new Promise((resolve, reject) => {
            cos.getObject({
                Bucket: userInfo.userBucket,
                Region: userInfo.userRegion,
                Key,
                ResponseCacheControl: 'no-cache',

                onProgress: function (progressData) {
                    console.log('下载进度3333', JSON.stringify(progressData));
                    onProgressCallback(Math.floor(progressData.loaded / progressData.total * 100))
                }

            }, function (err, data) {
                {
                    console.log(reject, err)
                    console.log('下载对象', data)
                    resolve(data);
                }
            });
        });

    }

    //如果有缓冲，就从缓存中获取文件
    async getObject_cache(Key, onProgressCallback) {
        // var that = this

        await this.updateNewTempSecretKey()

        return new Promise((resolve, reject) => {
            cos.getObject({
                Bucket: userInfo.userBucket,
                Region: userInfo.userRegion,
                Key,
                onProgress: function (progressData) {
                    console.log('下载进度3333', JSON.stringify(progressData));
                    onProgressCallback(Math.floor(progressData.loaded / progressData.total * 100))
                }

            }, function (err, data) {
                {
                    console.log(reject, err)
                    console.log('下载对象', data)
                    resolve(data);
                }
            });
        });

    }

    async getObjectUrl(Key) {

        await this.updateNewTempSecretKey()

        // cos.getObjectUrl(
        //     {
        //         Bucket: 'examplebucket-1250000000', // 填入您自己的存储桶，必须字段
        //         Region: 'COS_REGION', // 存储桶所在地域，例如 ap-beijing，必须字段
        //         Key: '头像.jpg', // 存储在桶里的对象键（例如1.jpg，a/b/test.txt），支持中文，必须字段
        //         Sign: true, // 获取带签名的对象 URL
        //     },
        //     function (err, data) {
        //         if (err) return console.log(err);
        //         /* url为对象访问 url */
        //         var url = data.Url;
        //         /* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
        //         var downloadUrl =
        //             url +
        //             (url.indexOf('?') > -1 ? '&' : '?') +
        //             'response-content-disposition=attachment;filename=图片.jpg'; // 补充强制下载的参数并重命名下载后的文件
        //     }
        // );

        return new Promise((resolve, reject) => {
            cos.getObjectUrl({
                Bucket: userInfo.userBucket,
                Region: userInfo.userRegion,
                Key,
                Sign: true,// 获取带签名的对象 URL
                Protocol: 'https:'
            }, function (err, data) {
                {
                    if (err) return console.log(err);

                    console.log('文件访问的url是', data)
                    /* url为对象访问 url */
                    var url = data.Url;
                    /* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
                    var downloadUrl =
                        url +
                        (url.indexOf('?') > -1 ? '&' : '?') +
                        'response-content-disposition=attachment'; // 补充强制下载的参数并重命名下载后的文件
                    console.log(reject, err)
                    // console.log('下载对象', data)
                    resolve(downloadUrl);
                    // response-content-disposition=attachment
                }
            });
        });
    }

    async getTempKey() {

        await this.updateNewTempSecretKey()

        return temporaryKey

    }

    // allFiles = ''
    async getAllFilesInChooseFolder(folderInfo) {

        console.log('folderInfoe3333ee', folderInfo)

        const folder = folderInfo.type == 'folder' ? folderInfo.path + '/' : folderInfo.path

        console.log('folderInfoeee', folderInfo, folder)

        await this.updateNewTempSecretKey(folderInfo)


        //找到文件夹顶部
        var firstParent = this.findFirstParent(folderInfo)
        // var firstParent = folderInfo


        // const loop = true
        // //向文件上级查找，找到祖籍第一代看看有没有数据
        // while (loop) {

        //     //上级如果是根目录，这个级别内理论上有临时密钥
        //     if (firstParent.upperFolder.isRootPath) {

        //         break

        //     }

        //     firstParent = folderInfo.upperFolder

        // }

        // console.log('最上级文件夹我找到了22222233111', firstParent)




        //指定文件夹下的所有文件列表
        var allFilesArr = []

        var that = this

        const getAllFilesInFolder = async function (folder, NextMarker) {
            console.log('下载列表文件', folder)
            // 调用COS.getBucket()方法获取文件夹内的文件, // 指定文件夹路径
            //   maxKeys: 1000 // 每次最多获取1000个文件
            // };

            await that.updateNewTempSecretKey(folderInfo)
            // getBucket(prefix, Marker,userBucket,userRegion)
            console.log('folder, NextMarker,firstParent.bucket,firstParent.regineeee', folder, NextMarker, firstParent.bucket, firstParent.region)
            var data = await that.getBucket(folder, NextMarker, firstParent.bucket, firstParent.region)

            console.log('111222data', data)

            if (data == 'AccessDenied: Access Denied.') {
                console.log('无权访问文件')
                return
            }

            if (!data) {
                console.log('无文件数据')
                return
            }

            console.log('datarrrrr', data)

            for (let i = 0; i < data.Contents.length; i++) {
                // allFilesArr.push(data.Contents[i].Key)
                allFilesArr.push({
                    Key: data.Contents[i].Key,
                    Size: data.Contents[i].Size,

                })
            }

            // allFiles = allFiles.concat(data.Contents);
            // 判断是否还有未获取的文件
            // if (data.IsTruncated) {

            if (data.NextMarker) {
                // 如果还有未获取的文件，则递归调用该函数继续获取
                await getAllFilesInFolder(folder, data.NextMarker);
            }
        }


        console.log('查询所有的文件夹文件工具的传入参数', folder)

        try {

            await getAllFilesInFolder(folder)
            console.log('本文件夹内的所有子对象333', allFilesArr)
            return allFilesArr

        } catch (err) {

            console.log('err3333', err)

            return 'err'

        }


        // await this.getBucket('UsersFolder/Administrator/MyFolder/')
    }

    //找到第一个父级
    findFirstParent(folderInfo) {

        var firstParent = folderInfo

        const loop = true

        //向文件上级查找，找到祖籍第一代看看有没有数据
        while (loop) {

            //上级如果是根目录，这个级别内理论上有临时密钥
            if (firstParent.upperFolder.isRootPath) {

                break

            }

            firstParent = firstParent.upperFolder

        }

        console.log('最上级文件夹我找到了2222223311122233w', firstParent)

        return firstParent

    }

    //判断是否存在文件
    //检查文件是否存在
    async doesObjectExist(folderInfo) {

        const handKey = folderInfo.path

        console.log('检查文件是否存在传入参数qq', handKey)

        await this.updateNewTempSecretKey(folderInfo)

        // await this.updateNewTempSecretKey(folderInfo)
        // firstParent.bucket, firstParent.region

        //找到文件夹顶部
        var firstParent = this.findFirstParent(folderInfo)

        return new Promise((resolve, reject) => {
            cos.headObject({
                Bucket: firstParent.bucket,
                Region: firstParent.region,
                Key: handKey
            }, function (err, data) {
                console.log('err', err)
                {
                    console.log('文件是否存在', reject)
                    // if (data) {
                    //     console.log('对象存在');
                    // } else if (err.statusCode == 404) {
                    //     console.log('对象不存在');
                    // } else if (err.statusCode == 403) {
                    //     console.log('没有该对象读权限');
                    // }
                    resolve(data);
                }
            });
        });


        // cos.headObject({
        //     Bucket: 'examplebucket-1250000000', /* 填入您自己的存储桶，必须字段 */
        //     Region: 'COS_REGION',  /* 存储桶所在地域，例如ap-beijing，必须字段 */
        //     Key: '1.jpg',  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
        // }, function (err, data) {
        //     if (data) {
        //         console.log('对象存在');
        //     } else if (err.statusCode == 404) {
        //         console.log('对象不存在');
        //     } else if (err.statusCode == 403) {
        //         console.log('没有该对象读权限');
        //     }
        // });
    }



    //传入文件夹树后计算文件夹的size
    countFolderTreeSize(folderTree) {

        console.log('统计文件体积传入的参数是', folderTree)

        var size = 0


        const countChildrenSize = function (folder_tree) {

            for (let i = 0; i < folder_tree.folder.length; i++) {

                if (folder_tree.folder[i].size != null) {

                    size += folder_tree.folder[i].size

                }

                countChildrenSize(folder_tree.folder[i])

            }

        }

        countChildrenSize(folderTree)

        console.log('统计出的文件夹内的体积是:', size)

        return size

    }


    //重命名文件
    RenameFile(showFolderPath, oldPath, newName, type, onProgressCallback, deleteRES) {
        console.log('文件重命名传参', showFolderPath, newName, type)
        const CopySource = userInfo.userBucket + '.cos.' + userInfo.userRegion + '.myqcloud.com/' + oldPath
        console.log('CopySource', CopySource)
        cos.sliceCopyFile({
            Bucket: userInfo.userBucket,
            Region: userInfo.userRegion,
            Key: showFolderPath + '/' + newName + '.' + type,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
            CopySource: encodeURI(CopySource),
            onProgress: function (progressData) {                     /* 非必须 */
                console.log('进度3i3i3i3', JSON.stringify(progressData));
                onProgressCallback(Math.floor(progressData.loaded / progressData.total * 100))
            }

        }, function (err, data) {
            console.log(err || data);

            cos.deleteObject({
                Bucket: userInfo.userBucket,
                Region: userInfo.userRegion,
                Key: oldPath       /* 必须 */
            }, function (err, data) {
                console.log(err || data);
                if (data) {
                    deleteRES('success')
                } else {
                    deleteRES('error')
                }
            });



        });


    }


    //按照标签获取文件list
    // getLietByTags(key) {

    //     // const cos = new COS({
    //     //     SecretId: 'AKIDueB3oQp0xgKm394oNipiy3A7sSnvpX4P',
    //     //     SecretKey: 'FI8mCuPglfS1XqvmPnxtb7LUedR4TebJ',
    //     //     // SecurityToken: SecretInfo.credentials.sessionToken,
    //     // });

    //     return new Promise((resolve, reject) => {


    //         // secretId: 'AKIDueB3oQp0xgKm394oNipiy3A7sSnvpX4P', // 固定密钥
    //         // secretKey: 'FI8mCuPglfS1XqvmPnxtb7LUedR4TebJ', // 固定密钥

    //         cos.getObjectTagging(
    //             {
    //                 Bucket, // 填入您自己的存储桶，必须字段
    //                 Region, // 存储桶所在地域，例如 ap-beijing，必须字段
    //                 Key: key, // 存储在桶里的对象键（例如1.jpg，a/b/test.txt），支持中文，必须字段

    //             },
    //             function (err, data) {
    //                 if (err) return console.log(err);


    //                 // console.log('data44444',data)


    //                 {
    //                     console.log(reject, err)

    //                     console.log('类中生获取的文件tag', data)

    //                     resolve(data);
    //                 }


    //             }
    //         );
    //         // cos.getObjectUrl({
    //         //     Bucket,
    //         //     Region,
    //         //     Key: key,
    //         //     Sign: true,
    //         // }, function (err, data) {
    //         //     {
    //         //         console.log('临时链接',reject, err)
    //         //         var url = data.Url;
    //         //         /* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
    //         //         var downloadUrl =
    //         //             url +
    //         //             (url.indexOf('?') > -1 ? '&' : '?') +
    //         //             'response-content-disposition=inline'; // 补充强制下载的参数并重命名下载后的文件

    //         //         resolve(downloadUrl);
    //         //     }
    //         // });
    //     });

    //     // cos.getObjectTagging({
    //     //     Bucket, /* 必须 */
    //     //     Region,     /* 存储桶所在地域，必须字段 */
    //     //     Key: '1.png', /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
    //     // }, function(err, data) {
    //     //     console.log(err || data);
    //     // });

    // }





    //获取分享的媒体临时链接
    getMediaUrl_Share(CosShare, key, Bucket, Region) {
        console.log('下载的key', key)
        console.log('CosShare,key,Bucket,Region', CosShare, key, Bucket, Region)
        return new Promise((resolve, reject) => {

            CosShare.getObjectUrl(
                {
                    Bucket: Bucket, // 填入您自己的存储桶，必须字段
                    Region: Region, // 存储桶所在地域，例如 ap-beijing，必须字段
                    Key: key, // 存储在桶里的对象键（例如1.jpg，a/b/test.txt），支持中文，必须字段
                    Sign: true, // 获取带签名的对象 URL
                    Protocol:'https:'
                },
                function (err, data) {
                    if (err) return console.log(err);


                    // console.log('data44444',data)


                    {
                        console.log(reject, err)
                        /* url为对象访问 url */
                        var url = data.Url;
                        /* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
                        var downloadUrl =
                            url +
                            (url.indexOf('?') > -1 ? '&' : '?') +
                            'response-content-disposition=inline'; // 补充强制下载的参数并重命名下载后的文件

                        console.log('类中生成的临时下载链接eee', downloadUrl)

                        resolve(downloadUrl);
                    }


                }
            );
            // cos.getObjectUrl({
            //     Bucket,
            //     Region,
            //     Key: key,
            //     Sign: true,
            // }, function (err, data) {
            //     {
            //         console.log('临时链接',reject, err)
            //         var url = data.Url;
            //         /* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
            //         var downloadUrl =
            //             url +
            //             (url.indexOf('?') > -1 ? '&' : '?') +
            //             'response-content-disposition=inline'; // 补充强制下载的参数并重命名下载后的文件

            //         resolve(downloadUrl);
            //     }
            // });
        });

    }

    // async getShareCloudMideaUrl(key, TemporaryKey, BucketInfo) {
    //     // const TemporaryKey = fileinfo.

    //     console.log('TemporaryKey33333', TemporaryKey)
    //     var CosShare = new COS({
    //         SecretId: TemporaryKey.SecretId,
    //         SecretKey: TemporaryKey.SecretKey,
    //         SecurityToken: TemporaryKey.SecurityToken,
    //     });

    //     // Bucket: BucketInfo.Bucket,
    //     // Region: BucketInfo.Region,

    //     try {
    //         // await this.updateNewTempSecretKey()
    //         const res = await this.getMediaUrl_Share(CosShare, key, BucketInfo.Bucket, BucketInfo.Region)
    //         return res
    //     } catch (err) {
    //         console.log('错误,', err)
    //         return 'err'
    //     }


    // }




    // // 定义一个递归函数，用于获取指定文件夹下的所有文件列表
    // async getAllFilesInFolder(folder, NextMarker) {
    //     console.log('下载列表文件', folder)
    //     // 调用COS.getBucket()方法获取文件夹内的文件, // 指定文件夹路径
    //     //   maxKeys: 1000 // 每次最多获取1000个文件
    //     // };

    //     await this.updateNewTempSecretKey()

    //     var data = await this.getBucket(folder, NextMarker)

    //     console.log('111222data', data)

    //     if (data == 'AccessDenied: Access Denied.') {
    //         console.log('无权访问文件')
    //         return
    //     }

    //     if (!data) {
    //         console.log('无文件数据')
    //         return
    //     }

    //     console.log('datarrrrr', data)

    //     for (let i = 0; i < data.Contents.length; i++) {
    //         allFilesArr.push(data.Contents[i].Key)
    //     }

    //     // allFiles = allFiles.concat(data.Contents);
    //     // 判断是否还有未获取的文件
    //     // if (data.IsTruncated) {

    //     if (data.NextMarker) {
    //         // 如果还有未获取的文件，则递归调用该函数继续获取
    //         await this.getAllFilesInFolder(folder, data.NextMarker);
    //     }
    // }


    // 判断临时密钥是否过期
    isTemporaryKeyExpired(folderInfo) {
        // ?????

        console.log('222folderInfo222', folderInfo)

        var firstParent = folderInfo

        const loop = true
        //向文件上级查找，找到祖籍第一代看看有没有数据
        while (loop) {

            //上级如果是根目录，这个级别内理论上有临时密钥
            if (firstParent.upperFolder.isRootPath) {

                break

            }

            firstParent = firstParent.upperFolder

        }

        console.log('最上级文件夹我找到了333qqq', firstParent)


        // if 


        //找到祖上第一级,接下来看看密钥过期没

        // firstParent

        var cloudbaseTime = firstParent.cloudbaseTime

        var temporaryKey = firstParent.temporaryKey

        var timestamp = firstParent.DeviceTimestamp

        var Expiration_timestamp = temporaryKey.Expiration_timestamp

        console.log('cloudbaseTime || !temporaryKey || !Expiration_timestamp', cloudbaseTime, temporaryKey, Expiration_timestamp)

        //数据不足 需要更新
        if (!cloudbaseTime || !temporaryKey || !Expiration_timestamp || !timestamp) return true






        // timeDifference = Resoult.result.result.startTime - timestamp

        // //设备时间差
        // var timestamp = new Date().getTime();
        // // timestamp = Math.floor(timestamp / 1000)
        // console.log(timestamp);

        // //真实时间-系统时间
        timeDifference = cloudbaseTime - timestamp

        //没有获取过密钥
        // if (timeDifference == null) {

        //     // this.$message('未获取过密钥');
        //     console.log('未获取过密钥')

        //     return true

        // }
        // const temporaryKey = getTemporaryKey();
        //设备时间
        const currentTime = Math.floor(Date.now()); // 当前时间的Unix时间戳

        console.log('时间戳问题2222211r2r', currentTime, timeDifference, Expiration_timestamp)

        // 判断当前时间是否超过过期时间
        if (currentTime + timeDifference > Expiration_timestamp) {

            // this.$message('密钥过期');

            console.log('密钥过期')


            return true; // 临时密钥已过期
        } else {
            // this.$message('密钥未过期');

            console.log('密钥未过期')


            return false; // 临时密钥未过期
        }
    }




    //上传文件到存储桶
    //FileKey 文件路径 和file 文件
    async uploadFile(FileKey, file, secretKey, onProgressCallback, onFileFinishCallBack) {

        //更新密钥
        //不用更新因为上传会生成单独的上传密钥
        // await this.updateNewTempSecretKey()

        console.log('上传密钥33334444函数内:', secretKey)

        console.log('函数内的上传fileKey', FileKey)

        const updateCOS = new COS({
            SecretId: secretKey.tmpSecretId,
            SecretKey: secretKey.tmpSecretKey,
            SecurityToken: secretKey.sessionToken,
        });

        updateCOS.uploadFile({
            Bucket: userInfo.userBucket, /* 填入您自己的存储桶，必须字段 */
            Region: userInfo.userRegion,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
            Key: FileKey,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
            Body: file, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
            SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
            //
            onTaskReady: function (taskId) {                   /* 非必须 */
                console.log(taskId);
            },
            onProgress: function (progressData) {           /* 非必须 */
                console.log(JSON.stringify(progressData));
                const progress = progressData.percent * 100;
                onProgressCallback(Math.floor(progress));

            },
            onFileFinish: function (err, data, options) {   /* 非必须 */
                console.log(options.Key + 'upload' + (err ? 'err' : 'success'));

                onFileFinishCallBack({
                    res: err ? 'err' : 'success',
                    key: options.Key
                })

            },
            // 支持自定义headers 非必须
            // Headers: {
            //     'x-cos-meta-test': 123
            // },
        }, function (err, data) {
            console.log(err || data);
        });
    }

    // async putStringToCOS(Key, string) {

    //     return new Promise((resolve, reject) => {
    //         cos.putObject({
    //             Bucket,
    //             Region,
    //             Key,
    //             Body: string,
    //         }, function (err, data) {
    //             console.log(err || data);
    //             resolve(data);
    //         });
    //     });

    // }

    //在云函数中将临时文件夹的文件存储到用户的文件夹中，需要提前检测看看云文件夹的内存够不够
    //如果不够，则删除临时文件夹中的文件
    //只能将用户自己的临时文件夹文件转存到用户的文件夹中
    async saveCloudTempFileToUserFolder(fileName, TargetSaveFolder) {

        console.log('TargetSaveFolder3333', TargetSaveFolder)

        // const TargetSaveFolderPathArr = TargetSaveFolder.split('/')

        // const UserFolderPath = TargetSaveFolderPathArr[2]

        // console.log('保存用户文件夹位置',UserFolderPath)


        // const folderName = UserFolderPath

        console.log('云函数上传参数3333', fileName, TargetSaveFolder)

        const Resoult = await app.callFunction({
            // 云函数名称
            name: "saveUsrTempFileToUserFolder",
            // name: "MakeUpMpdFIleFromStepFIle",
            // 传给云函数的参数
            data: {
                fileName,
                TargetSaveFolder
                // targetSaveFile:folderName
            }
        })

        console.log('文件剪切函数结果', Resoult)

        return Resoult


    }




    //删除单个文件
    // async DeleteFile(Key) {

    //     //更新密钥
    //     await this.updateNewTempSecretKey()

    //     return new Promise((resolve, reject) => {

    //         cos.deleteObject({
    //             Bucket: userInfo.userBucket,
    //             Region: userInfo.userRegion,
    //             Key
    //         }, function (err, data) {
    //             {
    //                 console.log(reject)

    //                 resolve(data || err);
    //             }
    //         });

    //     })



    // }

    //删除文件夹
    async deleteFolder(Key) {

        console.log('删除文件函数传参222', Key)

        var deleteRes

        const deleteKey = Key

        if (deleteKey[deleteKey.length - 1] == '/') {

            // type = '文件夹'

            //获取文件列表
            // const fileList = await FolderList()
            try {
                deleteRes = await this.deleteFolder_(deleteKey);
                console.log('deleteFolder result:', deleteRes);
            } catch (error) {
                console.log('deleteFolder error:', error);
            }


        } else {
            //删除文件
            //删除文件
            // type = '文件'

            deleteRes = await this.deleteObject(deleteKey)

        }

        return deleteRes



    }

    //删除文件夹逻辑
    async deleteFolder_(Key) {
        return new Promise((resolve, reject) => {
            var deleteFiles = function (Key, marker) {
                cos.getBucket({
                    Bucket: userInfo.userBucket,
                    Region: userInfo.userRegion,
                    Prefix: Key,
                    Marker: marker,
                    MaxKeys: 1000,
                }, function (listError, listResult) {
                    if (listError) return console.log('list error:', listError);
                    var nextMarker = listResult.NextMarker;
                    var objects = listResult.Contents.map(function (item) {
                        return { Key: item.Key }
                    });
                    cos.deleteMultipleObject({
                        Bucket: userInfo.userBucket,
                        Region: userInfo.userRegion,
                        Objects: objects,
                    }, function (delError, deleteResult) {
                        if (delError) {
                            console.log('delete error', delError);
                            console.log('delete stop');
                        } else {
                            console.log('delete result', deleteResult);
                            if (listResult.IsTruncated === 'true') deleteFiles(Key, nextMarker);
                            else {
                                console.log('delete complete');
                                console.log(reject)
                                resolve(deleteResult);
                            }
                        }
                    });
                });
            }

            deleteFiles(Key);

        });

    }

    //只能删除文件，不能删除文件夹

    deleteObject(key) {

        console.log('删除的key是,', key)

        // cos.deleteObject({
        //     Bucket: 'examplebucket-1250000000', /* 填入您自己的存储桶，必须字段 */
        //     Region: 'COS_REGION',  /* 存储桶所在地域，例如ap-beijing，必须字段 */
        //     Key: '1.jpg',  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
        // }, function(err, data) {
        //     console.log(err || data);
        // });

        return new Promise((resolve, reject) => {
            cos.deleteObject({
                Bucket: userInfo.userBucket,
                Region: userInfo.userRegion,
                Key: key
                // Marker,
                // Delimiter: '/',

            }, function (err, data) {
                {
                    console.log(reject, err)
                    console.log('删除对象', data || err)
                    resolve(data || err);
                }
            });
        });




    }

    //上传文件到用户临时文件夹
    async getUpLoadFileKey_userTempFolder(path) {
        //文件尺寸上传

        var reskey = await this.getUploadKey_userTempFolder(path)

        console.log('上传密钥', reskey)

        if (reskey && reskey.result && reskey.result.result && reskey.result.result.credentials) {

            return reskey.result.result.credentials

        } else {
            //上传

            return '无上传权限'

        }



    }

    //获取上传文件的密钥（用户临时文件夹）
    //如果用户上传的文件过大则不返回上传密钥
    async getUploadSecretKey_userTempFolder(TempUpdateFileName, FileSize) {


        //去除path中的userfolder/userfoldername部分。只留取用户文件夹后面的文件位置
        // /实现将路径中的前面部分去除，只保留最后两个斜杠后面的部分。
        // var tempFileKey = path.replace(/.*\/(.*)/, "$1");

        console.log('TempUpdateFileName222', TempUpdateFileName, "  ", FileSize)

        // console.log('文件上传前申请密钥的文件尺寸', filesize)

        var Resoult

        try {
            Resoult = await app.callFunction({
                // 云函数名称

                name: "getUploadFileSecretKey",
                // name: "MakeUpMpdFIleFromStepFIle",
                // name: "make_up_mpd_file_from_step_file_cos",

                // 传给云函数的参数
                data: {
                    // type: 'upload',
                    // filesize,
                    FileSize,
                    TempUpdateFileName
                    // mpdFileID
                }
            })
            console.log('云函数生成的上传文件的临时密钥结果222', Resoult)

        } catch (err) {

            console.log('错误')

        }

        return Resoult
    }


    //上传文件到存储桶
    //FileKey 文件路径 和file 文件
    // async AddNewFolder(FileKey) {

    //     //更新密钥
    //     await this.updateNewTempSecretKey()

    //     cos.uploadFile({
    //         Bucket, /* 填入您自己的存储桶，必须字段 */
    //         Region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
    //         Key: FileKey,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
    //         Body: file, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
    //         SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
    //         //
    //         onTaskReady: function (taskId) {                   /* 非必须 */
    //             console.log(taskId);
    //         },
    //         onProgress: function (progressData) {           /* 非必须 */
    //             console.log(JSON.stringify(progressData));
    //             const progress = progressData.percent * 100;
    //             onProgressCallback(progress);

    //         },
    //         onFileFinish: function (err, data, options) {   /* 非必须 */
    //             console.log(options.Key + 'upload' + (err ? 'err' : 'success'));

    //             onFileFinishCallBack({
    //                 res: err ? 'err' : 'success',
    //                 key: options.Key
    //             })

    //         },
    //         // 支持自定义headers 非必须
    //         Headers: {
    //             'x-cos-meta-test': 123
    //         },
    //     }, function (err, data) {
    //         console.log(err || data);
    //     });
    // }

    async createFolder(KeyAboutFolderName) {
        var result

        try {
            result = await cos.putObject({
                Bucket: userInfo.userBucket,
                Region: userInfo.userRegion,
                Key: KeyAboutFolderName,
                Body: '',
                // ContentLength: 0, // 文件夹大小为 0
                // ContentType: 'application/x-directory', // 设置 ContentType 为 application/x-directory 表示这是一个文件夹
            });
            result.state = 'success'
            console.log('文件夹创建成功', result);
        } catch (err) {
            result.state = 'error'
            console.error('文件夹创建失败', err);
        }

        return result
    }

    //将ldr文件转换为mpd文件
    //converte 转换的意思
    // convertLdrToMpd() {


    // }


    //文件位置（云文件），保存位置(云文件)
    //precision 精度
    //Convert 转换的意思
    // async ConvertLdrToMpdAndSaveFile(LdrKey, saveMpdKey, precision) {

    //     console.log('LdrKey, saveFolfer', LdrKey, saveMpdKey)

    //     //此函数云端需要鉴权
    //     const Resoult = await app.callFunction({
    //         // 云函数名称
    //         name: "make_up_mpd_file_from_step_file_cos",
    //         // name: "MakeUpMpdFIleFromStepFIle",
    //         // 传给云函数的参数
    //         data: {
    //             Model_precision: precision,
    //             type: 'cosKey',
    //             LdrKey: LdrKey,
    //             //保存的文件夹
    //             saveMpdKey
    //         }
    //     })

    //     console.log('转换结果', Resoult)

    //     return Resoult



    // }

    //文件位置（云文件），保存位置(云文件)
    //precision 精度
    //Convert 转换的意思

    // async ConvertLdrToMpdAndSaveFile(LdrKey, saveMpdKey, precision) {

    // }

    //上传文件到云端
    //上传文件
    //逻辑是先上传到用户的上传文件夹，然后检测文件尺寸如果能存入userfolder后云端储存到userFolder
    //如果用户空间不足则删除该对象
    // async upload_TXT_FileToMyCloudFolder(TxtMPD, EditMPDFileKey, onProgressCallback) {

    //     // return new Promise((resolve, reject) => {
    //         // cos.headObject({
    //         //     Bucket: userInfo.userBucket,
    //         //     Region: userInfo.userRegion,
    //         //     Key
    //         // }, function (err, data) {
    //         //     console.log('err', err)
    //         //     {
    //         //         console.log(reject)
    //         //         // if (data) {
    //         //         //     console.log('对象存在');
    //         //         // } else if (err.statusCode == 404) {
    //         //         //     console.log('对象不存在');
    //         //         // } else if (err.statusCode == 403) {
    //         //         //     console.log('没有该对象读权限');
    //         //         // }
    //         //         resolve(data);
    //         //     }
    //         // });

    //         //去除名称中的加减乘除，否则wpps会出现解析问题
    //         const path = EditMPDFileKey.replace(/[+\-*/]/g, "_")

    //         const that = this
    //         const EditLDRFileKeyArr = EditMPDFileKey.split('/')
    //         const FileMPD_Name = EditLDRFileKeyArr[EditLDRFileKeyArr.length - 1]
    //         // const MpdFileName
    //         // const path = 'UsersFolder' + this.$refs.UserName + 

    //         console.log('上传参数', path, TxtMPD)

    //         // this.uploadProgressNum = 0

    //         // this.loading_ProgressEnable = true
    //         // var secretKey = await this.$root.$MyCloudFiles.getUpLoadFileKey(files[0].size)

    //         // var pathArr = path.split('/')

    //         // const FileMPD_Name = pathArr[pathArr.length - 1]

    //         //

    //         // 将字符串转换为Blob对象
    //         const blob = new Blob([TxtMPD]);

    //         // 获取Blob对象的大小
    //         const size = blob.size;

    //         console.log('3333文件尺寸', size)

    //         var secretKey = await this.getUploadSecretKey_userTempFolder(FileMPD_Name, size * 1)

    //         console.log('secretKey,', secretKey)

    //         if (secretKey.result && secretKey.result.info && secretKey.result.info == "空间不足") {
    //             //
    //             // this.$message({
    //             //     message: '空间不足,上传失败',
    //             //     type: 'warning'
    //             // });

    //             // this.loading_ProgressEnable = false

    //             return {
    //                 res: "空间不足"
    //             }

    //         }

    //         var credentials

    //         if (!(secretKey && secretKey.result && secretKey.result.result && secretKey.result.result.credentials)) {

    //             // this.$message({
    //             //     message: '获取上传凭证失败',
    //             //     type: 'warning'
    //             // });

    //             this.loading_ProgressEnable = false

    //             return {
    //                 res: '获取上传凭证失败'
    //             }
    //         }

    //         credentials = secretKey.result.result.credentials

    //         //实现将路径中的前面部分去除，只保留最前两个斜杠后面的部分。
    //         // var newPath2 = this.convertPath(path)
    //         const uploadFileTempPath = "UsersFolder/" + userInfo.userFolder + "/userUploadTempFolder/" + FileMPD_Name
    //         console.log('newPath22222', path, uploadFileTempPath); // 输出 c/d/e/k.png


    //         this.uploadFile(uploadFileTempPath, TxtMPD, credentials, (progress) => {
    //             // this.progress = progress;

    //             // that.uploadProgressNum = progress
    //             // onProgressCallback()

    //             // function (progressData) {           /* 非必须 */
    //             // console.log(JSON.stringify(progressData));
    //             // const progress = progressData.percent * 100;
    //             onProgressCallback(Math.floor(progress));

    //             // },




    //             console.log('主组建内的文件上传进度', that.uploadProgressNum, progress)
    //         }, async (finishRes) => {

    //             console.log('上传结果', finishRes)


    //             that.loading_ProgressEnable = false

    //             if (finishRes.res == 'err') {

    //                 // that.$message.error('上传失败');

    //                 return {
    //                     res: '上传失败'
    //                 }

    //             } else if (finishRes.res == 'success') {


    //                 //
    //                 // loading = this.$loading({
    //                 //     lock: true,
    //                 //     text: '文件转存中',
    //                 //     spinner: 'el-icon-loading',
    //                 //     background: 'rgba(0, 0, 0, 0.7)'
    //                 // });


    //                 //获取文件的尺寸
    //                 //如果文件大小足以上传，则在云函数中将文件剪切到相应的文件夹中
    //                 //然后将用户文件夹已使用的空间加上文件的size
    //                 //第二个参数是用户计划要保存到的文件夹位置
    //                 // const SaveFolder = this.convertPath(this.showFolder.path) + '/'
    //                 var pathArr = EditMPDFileKey.split('/')
    //                 var pathRes = ''
    //                 for (let i = 3; i < pathArr.length; i++) {
    //                     pathRes += pathArr[i]
    //                     if (i < (pathArr.length - 1)) {

    //                         pathRes += '/'


    //                     }
    //                 }
    //                 const SaveFolder = pathRes
    //                 // EditMPDFileKey
    //                 // if(){}

    //                 const CloudCopyUploadRes = await this.saveCloudTempFileToUserFolder(FileMPD_Name, SaveFolder)



    //                 // loading.close()
    //                 // loading = null

    //                 console.log(FileMPD_Name, SaveFolder)
    //                 console.log('云端将刚刚上传到临时文件夹的文件转存到目标文件夹并增加用户使用内存数据库的结果是', CloudCopyUploadRes)





    //                 const res_user = CloudCopyUploadRes.result
    //                 //
    //                 //剩余空间
    //                 const residueMemory = res_user.UserFolderSizeTotal - res_user.userCloudSizeUsed - res_user.uploadFileSize

    //                 console.log('剩余空间字节,', residueMemory)

    //                 const residueMemory_format = that.formatBytes(residueMemory)

    //                 if (CloudCopyUploadRes.result && CloudCopyUploadRes.result.info && CloudCopyUploadRes.result.info == "用户储存空间不足") {
    //                     const User_residue = res_user.UserFolderSizeTotal - res_user.userCloudSizeUsed
    //                     const User_residue_format = that.formatBytes(User_residue)
    //                     console.log('用户空间不足提醒', User_residue, User_residue_format)
    //                     // that.$message({
    //                     //     message: '剩余空间不足,仅' + User_residue_format,
    //                     //     type: 'error'
    //                     // });


    //                     return {
    //                         res: '上传失败,剩余空间不足,仅' + User_residue_format,


    //                     }
    //                 }


    //                 // that.$message({
    //                 //     message: '上传成功',
    //                 //     type: 'success'
    //                 // });

    //                 //更新菜单
    //                 // await that.getFolderList()
    //                 // console.log('查找传递de参数', that.showFolder, that.LastShowFolder)
    //                 // that.FileListChange(that.showFolder, that.LastShowFolder)


    //                 return {
    //                     message: '上传成功' + ',剩余空间:' + residueMemory_format,
    //                     type: 'success'
    //                 }
    //                 // that.$message({
    //                 //     message: '剩余空间:' + residueMemory_format,
    //                 //     type: 'success'
    //                 // });

    //             }





    //         });
    //     // });
    //     // this.getFolderList() 
    // }

    // formatBytes(bytes) {
    //     if (bytes >= 1024 * 1024) {
    //         return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    //     } else if (bytes >= 1024) {
    //         return (bytes / 1024).toFixed(2) + ' KB';
    //     } else {
    //         return bytes
    //     }
    // }


    //检查云空间是否有足够的上传空间
    // async uploadFileCheckSize(Key) {

    //     return new Promise((resolve, reject) => {
    //         cos.getBucket({
    //             Bucket: userInfo.userBucket,
    //             Region: userInfo.userRegion,
    //             Prefix: Key,              /* Prefix表示列出的object的key以prefix开始，非必须 */
    //             Delimiter: '/',            /* Deliter表示分隔符, 设置为/表示列出当前目录下的object, 设置为空表示列出所有的object，非必须 */
    //         }, function (err, data) {
    //             {
    //                 console.log(reject)
    //                 if (data) {
    //                     console.log('对象存在');
    //                 } else if (err.statusCode == 404) {
    //                     console.log('对象不存在');
    //                 } else if (err.statusCode == 403) {
    //                     console.log('没有该对象读权限');
    //                 }
    //                 resolve(data);
    //             }
    //         });
    //     });

    //     cos.getBucket({
    //         Bucket: 'examplebucket-1250000000', /* 填入您自己的存储桶，必须字段 */
    //         Region: 'COS_REGION',  /* 存储桶所在地域，例如ap-beijing，必须字段 */
    //         Prefix: 'a/',              /* Prefix表示列出的object的key以prefix开始，非必须 */
    //         Delimiter: '/',            /* Deliter表示分隔符, 设置为/表示列出当前目录下的object, 设置为空表示列出所有的object，非必须 */
    //     }, function (err, data) {
    //         console.log(err || data.CommonPrefixes);
    //     });




    // }

    // async getFolderSize(Key) {
    //     try {
    //         // const options = {
    //         //     Prefix: folder,
    //         //     Delimiter: '/',
    //         // };

    //         const result = await cos.getBucket({
    //             Bucket: userInfo.userBucket,
    //             Region: userInfo.userRegion,
    //             Prefix: Key,              /* Prefix表示列出的object的key以prefix开始，非必须 */
    //             Delimiter: '/',            /* Deliter表示分隔符, 设置为/表示列出当前目录下的object, 设置为空表示列出所有的object，非必须 */
    //         });
    //         const objects = result.Contents;
    //         let size = 0;

    //         for (const object of objects) {

    //             if (object.Key.endsWith('/')) {
    //                 continue;
    //             }

    //             const objectInfo = await this.doesObjectExist(object.Key);

    //             size += objectInfo.ContentLength;
    //         }

    //         return size;
    //     } catch (error) {
    //         console.error('查询文件夹体积失败：', error);
    //         return 0;
    //     }
    // }

    // async getUserFolderSize() {

    //     const FolderPath1 = 'UsersFolder/' + userInfo.userFolder + '/'
    //     // const FolderPath2 = 'UsersFolder/' + userInfo.userFolder + '/SystemFolder/'


    //     var resList = [];

    //     async function listFolder(Folder, marker) {
    //         return new Promise((resolve, reject) => {
    //             cos.getBucket({
    //                 Bucket: userInfo.userBucket,
    //                 Region: userInfo.userRegion,
    //                 Prefix: Folder,
    //                 Marker: marker,
    //                 MaxKeys: 1000,
    //             }, function (err, data) {
    //                 if (err) {
    //                     return console.log('list error:', err);
    //                 } else {
    //                     for (let i = 0; i < data.Contents.length; i++) {
    //                         resList.push(data.Contents[i]);
    //                     }
    //                     console.log(reject)
    //                     console.log('list result:', data.Contents);
    //                     if (data.IsTruncated === 'true') {
    //                         listFolder(data.NextMarker).then(() => resolve());
    //                     } else {
    //                         resolve();
    //                     }
    //                 }
    //             });
    //         });
    //     }

    //     async function main() {
    //         await listFolder(FolderPath1);
    //     }

    //     await main();

    //     console.log('resList文件结果', resList);

    //     var size = 0
    //     //计算所有文件的体积
    //     for (let j = 0; j < resList.length; j++) {

    //         size += resList[j].Size * 1

    //     }

    //     console.log('size', size)

    //     return size



    // }


}


export { ShareCloudFiles_inSchool };
