<template>
  <div>
    <!-- <div>文件查看器</div>
    <div>{{ FilePlayerData }}</div> -->

      <VideoPlayerView></VideoPlayerView>
  

      <FileImage></FileImage>


      <wppsPlayer></wppsPlayer>


      <ImagePlayer></ImagePlayer>


      <MpdPlayer></MpdPlayer>

  </div>

</template>

<script>
import ImagePlayer from '@/components/ImagePlayer.vue'
import wppsPlayer from '@/components/wppsPlayer.vue'
import VideoPlayerView from '@/components/VideoPlayer.vue'
import FileImage from '@/components/FileImage.vue'
import MpdPlayer from "@/components/MpdPlayer.vue"
export default {
  data() {
    return {

    }
  },

  components: {
    VideoPlayerView,
    FileImage,
    wppsPlayer,
    ImagePlayer,
    MpdPlayer,
  },

  computed: {
    FilePlayerData() {
      return this.$store.getters.getFilePlayerData;
    },
  },

  mounted() {
    // 在组件挂载文件后启动vuex变量（查看文件的变量）监视器
    this.startWatching();
  },



  methods: {

    startWatching() {
      this.$watch(() => this.$store.state.FilePlayerData, (newVal, oldVal) => {
        console.log(`vuex变量变化Count changed from ${oldVal} to ${newVal}`);
        console.log('oldVal', oldVal)
        console.log('newVal', newVal)

        const CreatorID = newVal.CreatorID
        const schoolName = newVal.schoolName
        const shareTeacher = newVal.shareTeacher

        const temporary_key = newVal.temporary_key

        console.log('打开文件时获取到的数据是', newVal)

        if (newVal.type == 'mp4' || newVal.type == 'avi') {

          this.$emit("messageToVideoPlayer", {
            type: "putKey",
            Key: newVal.Key,
            temporary_key,




          });

        } else if (newVal.type == 'docx' || newVal.type == 'pptx' || newVal.type == 'pdf' || newVal.type == 'xlsx') {
          //wpps

          console.log('文件播放器即将打开wpps发消息', newVal)



          this.$emit("messageToWppsPlayer", {
            type: "putKey",
            Key: newVal.Key,
            temporary_key,
            CreatorID,
            schoolName,
            shareTeacher,
            bucket: newVal.bucket,
            region: newVal.region

          });

          console.log('文件播放器即将打开wpps发消息2', newVal)


          // this.$emit("messageToVideoPlayer", {
          //   type: "putKey",
          //   Key: newVal.Key,
          //   temporary_key
          // });


        } else if (newVal.type == 'jpg' || newVal.type == 'jpeg' || newVal.type == 'png' || newVal.type == 'gif') {

          this.$emit("messageToImagePlayer", {
            type: "putKey",
            Key: newVal.Key,
            temporary_key


          });

        } else if (newVal.type == 'mpd') {

          this.$emit("messageToMpdPlayer", {
            type: "putKey",
            Key: newVal.Key,
            temporary_key,
            CreatorID,
            schoolName,
            shareTeacher,
          });

          // messageToMpdPlayer
        } else if (newVal.type == 'ldr') {

          this.$message({
            message: 'ldr文件转换成mpd文件后才能打开',
            type: 'warning'
          });
          // messageToMpdPlayer
        } else {
          // this.$root.$MyCloudFiles.getLietByTags(newVal.path)
          // getLietByTags
          this.$message({
            message: '不支持打开此格式文件:' + newVal.type,
            type: 'warning'
          });
        }





      });
    },


  }
}

</script>

<style></style>