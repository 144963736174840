<template>
    <div v-if="showPlayer"
        style="width:100% ;height:100%;position: relative;display: flex;flex-direction: column;background-color: #7f8c8d;left: 0;top: 0;position: absolute;overflow: hidden;">

        <div style="width: 100%;display: flex;align-items: center;flex-direction: row-reverse;">
            <el-button @click="closeWppsPlayer" style="padding: 0px;font-size: large;margin: 10px;color: azure;"
                type="text" icon="el-icon-close" circle>
            </el-button>
            <el-button @click="UpdateWppsPlayer" style="padding: 0px;font-size: large;margin: 10px;color: azure;"
                type="text" icon="el-icon-refresh" circle>
            </el-button>
        </div>

        <div class="app" style="width: 100%;flex: 1;"></div>

        <!-- <div
            style="position:absolute;bottom: 0px;left: 0px;width: 100%;height: 100%;pointer-events: none;display: flex;align-items: center;justify-content: center;">
            水印liuyubhdbv</div> -->
    </div>
</template>
<script>



// var loading

// const qcloud = require('qcloud-sdk');


// import '../../src/js/base64 copy.js';

import COSDocPreviewSDK from '../../src/js/sdk-v0.2.1.js';

var loading

var Buckte
var Region

var key

var CreatorID,schoolName,shareTeacher,sharefileDataInfo

export default {
    data() {
        return {
            // videoUrl: null,
            // playVideoButtonShow: true,
            // progress: 0,
            // nowTime: 0,
            // totalTime: 0

            showPlayer: false

        }
    },





    mounted() {



        // console.log('wpps消息初始化前的数据',Buckte,Region)

        // this.gettempKey()

        // messageToWppsPlayer
        this.$parent.$on('messageToWppsPlayer', this.handleParentMessage);
        // this.$parent.$on
        // console.log('testTXT', this.$MyBase64.encodeUrl('testTXT'))




        // this.$parent.$on('messageToVideoPlayer', this.handleParentMessage);

        // window.addEventListener('resize', this.windowResize)


        // this.getvideoPlayerView()
        // this.getMediaUrl()
    },
    beforeDestroy() {

        // window.removeEventListener("resize", this.windowResize);
        // window.removeEventListener("mousemove", this.windowResize);

    },

    props: {
        // images: Array
    },

    // beforeDestroy() {

    // },





    methods: {


        //关闭播放器
        closeWppsPlayer() {

            this.showPlayer = false

        },

        handleParentMessage(e) {
            // Buckte = this.$root.$USERINFO.userBucket
            // Region = this.$root.$USERINFO.userRegion
            console.log('父级要打开wpps的播放器', e)
            Buckte = e.bucket || this.$root.$USERINFO.userBucket

            Region = e.region || this.$root.$USERINFO.userRegion

            sharefileDataInfo = e

            CreatorID = e.CreatorID

            schoolName = e.schoolName

            shareTeacher = e.shareTeacher

            key = e.Key

            this.showPPT(e, CreatorID, schoolName, shareTeacher)

        },


        // handleParentMessage(e) {

        //     Buckte = this.$root.$USERINFO.userBucket
        //     Region = this.$root.$USERINFO.userRegion

        //     console.log('handleParentMessage_e', e)
        //     // this.getvideoPlayerView(e.Key)

        //     // this.getMediaUrl(e)

        // },

        //         handleParentMessage(e) {

        // console.log('handleParentMessage_e', e)
        // // this.getvideoPlayerView(e.Key)

        // this.getMediaUrl(e.Key)

        // },

        async gettempKey() {
            const res = await this.$root.$MyCloudFiles.getTempKey()

            console.log('临时密钥', res)

            return res
        },

        UpdateWppsPlayer() {

            console.log('key21qweqwe', key)

            // this.showPPT({ Key: key })

            // this.showPPT(key)

            Buckte = Buckte || this.$root.$USERINFO.userBucket

            Region = Region || this.$root.$USERINFO.userRegion

            // const CreatorID = e.CreatorID

            // const schoolName = e.schoolName

            // const shareTeacher = e.shareTeacher

            

            // key = e.Key

            //将传入打开时的信息重新放进去重新打开wpps文件
            //主要解决分享文件打开的问题
            this.showPPT(sharefileDataInfo, CreatorID, schoolName, shareTeacher)



        },

        //判断key是不是用户自己的？
        // this.$root.$USERINFO.userFolder
        isKeyOfUser(key, userFolder) {
            // UsersFolder / 886f5319281740f88009117c2ddf9b2b

            // if (userFolder == null) return false

            const KeyArr = key.split('/')

            if (KeyArr[1] == userFolder) {

                return true

            } else {

                return false

            }

        },

        async showPPT(e, CreatorID = null, schoolName = null, shareTeacher = null) {


            console.log('CreatorID=null, schoolName=null, shareTeacher', CreatorID, schoolName, shareTeacher)





            console.log('key44444', e)

            //看看是自己的文件夹还是别人的文件夹
            //如果是自己的文件夹，就使用cloudfile.js打开，否则就用sharecloudfile打开


            if (this.$root.$USERINFO && this.$root.$USERINFO.userFolder && this.isKeyOfUser(e.Key, this.$root.$USERINFO.userFolder)) {
                //showMyWPPS
                this.showMyWPPS()

            } else {
                //分享的办公文件
                this.showSharerWPPS(e, CreatorID, schoolName, shareTeacher)

            }

        },

        async showMyWPPS() {
            // messageToVideoPlayer

            const that = this


            const tempKey = await this.gettempKey()

            this.showPlayer = true


            const authorization = await this.$root.$MyCloudFiles.getCosAuthorization(key)


            console.log('authorization22111222', authorization)

            if (authorization == 'err') {

                this.$message.error('资源连接错误');

                this.showPlayer = false

                return

            }

            loading = this.$loading({
                lock: true,
                text: '加载数据中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {

                if (loading != null) {

                    loading.close()

                    that.$message({
                        message: '超时！请检查网络环境或者资源过大',
                        type: 'warning'
                    });
                }


            }, 5000);

            console.log('authorization22233', authorization)

            console.log('tempKey23221', tempKey)

            const PPTurl = 'https://' + Buckte + '.cos.' + Region + '.myqcloud.com/' + key;

            // //处理身份证
            // var firstThree = this.$root.$USERINFO.userIDNumber.substring(0, 3); // 获取前3位
            // var lastThree = this.$root.$USERINFO.userIDNumber.substring(this.$root.$USERINFO.userIDNumber.length - 3); // 获取后3位
            // var IDNUmber = firstThree + '***' + lastThree


            // //处理名字
            // var name = this.$root.$USERINFO.userRealName
            // var nameRes
            // var nameLength = this.$root.$USERINFO.userRealName.length
            // if (nameLength == 2) {

            //     //名字长度2

            //     nameRes = name[0] + '*'

            // } else if (nameLength > 2) {

            //     //名字长度大于2

            //     const firstname = name[0]

            //     const lastname = name[nameLength - 1]

            //     var middleStars = '*'.repeat(nameLength - 2); // 生成中间的星号，长度为身份证号码长度减去前3位和后3位的长度


            //     nameRes = firstname + middleStars + lastname
            //     // console.log('名字处理',nameRes,firstname,middleStars,lastname)



            // }

            //处理身份证

            var IDNUmber
            if (this.$root.$USERINFO.userIDNumber) {
                var firstThree = this.$root.$USERINFO.userIDNumber.substring(0, 3); // 获取前3位
                var lastThree = this.$root.$USERINFO.userIDNumber.substring(this.$root.$USERINFO.userIDNumber.length - 3); // 获取后3位
                IDNUmber = firstThree + '***' + lastThree

            } else {

                IDNUmber = ''

            }


            //处理名字
            var name = this.$root.$USERINFO.userRealName
            var nameRes
            var nameLength = this.$root.$USERINFO.userRealName.length
            if (nameLength == 2) {

                //名字长度2

                nameRes = name[0] + '*'

            } else if (nameLength > 2) {

                //名字长度大于2

                const firstname = name[0]

                const lastname = name[nameLength - 1]

                var middleStars = '*'.repeat(nameLength - 2); // 生成中间的星号，长度为身份证号码长度减去前3位和后3位的长度


                nameRes = firstname + middleStars + lastname
                // console.log('名字处理',nameRes,firstname,middleStars,lastname)



            }

            var url = await COSDocPreviewSDK.getPreviewUrl({
                objectUrl: PPTurl,
                credentials: {
                    secretId: tempKey.credentials.tmpSecretId,
                    secretKey: tempKey.credentials.tmpSecretKey,
                    authorization: authorization,
                    // secretId: process.env.VUE_APP_SECRET_ID,
                    // secretKey: process.env.VUE_APP_SECRET_KEY
                },
                copyable: 0,
                htmlwaterword: this.$MyBase64.encodeUrl('搭搭星球' + '          ' + nameRes + '          ' + IDNUmber),
            });
            console.log('url2121125555', url);

            const demo = COSDocPreviewSDK.config({
                mount: document.querySelector('.app'),
                url,
                mode: 'simple',
                commonOptions: {
                    // language:'en',
                    isShowHeader: false,
                    isShowTopArea: false,
                    isBrowserViewFullscreen: false,
                    isIframeViewFullscreen: true
                },
                wordOptions: {
                    isShowDocMap: false, // 是否开启目录功能
                    isBestScale: true, // 打开文档时，是否以最佳比例显示
                    isShowBottomStatusBar: false, // 是否展示底部状态栏
                },
                pptOptions: {
                    isShowBottomStatusBar: false, // 是否展示底部状态栏

                },
                pdfOptions: {
                    isShowComment: true, // 是否显示注解
                    isInSafeMode: true, // 是否处于安全模式
                    isShowBottomStatusBar: false, // 是否展示底部状态栏
                },
                commandBars: [
                    {
                        cmbId: 'Print', // 打印功能
                        attributes: {
                            visible: false, // 组件是否显示，默认为true。false：隐藏组件；true：显示组件
                            enable: false, // 禁用或者开启组件，默认为true
                        },
                    },
                    {
                        cmbId: 'DownloadImg', // 双击图片下载按钮图标
                        attributes: {
                            visible: false,
                            enable: false,
                        },
                    },
                    {
                        cmbId: 'PlayContextCheckImage', // 播放时右键 - 查看原图
                        attributes: {
                            visible: false,
                            enable: false,
                        },
                    },
                ],
            });

            demo.on('fileOpen', function (data) {
                console.log('打开成功333', data);

                loading.close()

                loading = null

                console.log('鼠标初始化', document.querySelector('.app'))
                // test();
            });
        },

        //打开分享者的文档
        async showSharerWPPS(e, CreatorID = null, schoolName = null, shareTeacher = null) {
            // messageToVideoPlayer

            console.log('schoolName ', schoolName)

            const that = this

            //不是该用户的文件夹，则需要传入临时密钥
            const tempKey = e.temporary_key

            

            console.log('tempKey33333', tempKey)

            const BucketInfo = {
                Bucket: tempKey.bucket,
                Region: tempKey.region
            }

            console.log('分享文档信息', e)

            //校区名称

            // const schoolName = e


            //校区id

            // const tempKey = await this.gettempKey()

            this.showPlayer = true


            var authorization

            console.log('this.$root$ShareCloudFiles', this.$root.$root$ShareCloudFiles)
            console.log('this.$ShareCloudFiles_inSchool', this.$root.$ShareCloudFiles_inSchool)

            // console.log('')
            // this.$root.$ShareCloudFiles_inSchool

            //看看哪个加载器好用
            if (this.$root.$ShareCloudFiles) {

                authorization = await this.$root.$ShareCloudFiles.getCosAuthorization(key)

            } else if (this.$root.$ShareCloudFiles_inSchool) {

                authorization = await this.$root.$ShareCloudFiles_inSchool.getCosAuthorization(key)

            } else {

                this.$message.error('资源加载器错误');
                return

            }
            if (authorization == 'err') {

                this.$message.error('资源连接错误');

                this.showPlayer = false

                return

            }

            loading = this.$loading({
                lock: true,
                text: '加载数据中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            setTimeout(() => {

                if (loading != null) {

                    loading.close()

                    that.$message({
                        message: '超时！请检查网络环境或者资源过大',
                        type: 'warning'
                    });
                }


            }, 5000);

            console.log('authorization22233', authorization)

            console.log('tempKey23221', tempKey)

            const PPTurl = 'https://' + BucketInfo.Bucket + '.cos.' + BucketInfo.Region + '.myqcloud.com/' + key;

            //处理身份证

            var IDNUmber
            if (CreatorID) {
                var firstThree = CreatorID.substring(0, 3); // 获取前3位
                var lastThree = CreatorID.substring(CreatorID.length - 3); // 获取后3位
                IDNUmber = firstThree + '***' + lastThree

            } else {

                IDNUmber = ''

            }


            //处理名字
            var name = shareTeacher
            var nameRes
            var nameLength = shareTeacher.length
            if (nameLength == 2) {

                //名字长度2

                nameRes = name[0] + '*'

            } else if (nameLength > 2) {

                //名字长度大于2

                const firstname = name[0]

                const lastname = name[nameLength - 1]

                var middleStars = '*'.repeat(nameLength - 2); // 生成中间的星号，长度为身份证号码长度减去前3位和后3位的长度


                nameRes = firstname + middleStars + lastname
                // console.log('名字处理',nameRes,firstname,middleStars,lastname)



            }






            var url = await COSDocPreviewSDK.getPreviewUrl({
                objectUrl: PPTurl,
                credentials: {
                    secretId: tempKey.SecretId,
                    secretKey: tempKey.SecretKey,
                    authorization: authorization,
                    // secretId: process.env.VUE_APP_SECRET_ID,
                    // secretKey: process.env.VUE_APP_SECRET_KEY
                },
                copyable: 0,
                htmlwaterword: this.$MyBase64.encodeUrl('搭搭星球' + '          ' + nameRes + '          ' + IDNUmber),
            });
            console.log('url2121125555', url);

            const demo = COSDocPreviewSDK.config({
                mount: document.querySelector('.app'),
                url,
                mode: 'simple',
                commonOptions: {
                    // language:'en',
                    isShowHeader: false,
                    isShowTopArea: false,
                    isBrowserViewFullscreen: false,
                    isIframeViewFullscreen: true
                },
                wordOptions: {
                    isShowDocMap: false, // 是否开启目录功能
                    isBestScale: true, // 打开文档时，是否以最佳比例显示
                    isShowBottomStatusBar: false, // 是否展示底部状态栏
                },
                pptOptions: {
                    isShowBottomStatusBar: false, // 是否展示底部状态栏

                },
                pdfOptions: {
                    isShowComment: true, // 是否显示注解
                    isInSafeMode: true, // 是否处于安全模式
                    isShowBottomStatusBar: false, // 是否展示底部状态栏
                },
                commandBars: [
                    {
                        cmbId: 'Print', // 打印功能
                        attributes: {
                            visible: false, // 组件是否显示，默认为true。false：隐藏组件；true：显示组件
                            enable: false, // 禁用或者开启组件，默认为true
                        },
                    },
                    {
                        cmbId: 'DownloadImg', // 双击图片下载按钮图标
                        attributes: {
                            visible: false,
                            enable: false,
                        },
                    },
                    {
                        cmbId: 'PlayContextCheckImage', // 播放时右键 - 查看原图
                        attributes: {
                            visible: false,
                            enable: false,
                        },
                    },
                ],
            });

            demo.on('fileOpen', function (data) {
                console.log('打开成功333', data);

                loading.close()

                loading = null

                console.log('鼠标初始化', document.querySelector('.app'))
                // test();
            });
        }




        // windowResize() {

        //     // this.loadedmetadata()
        //     // this.setVideoWidthAndHeight()

        // },

        // handleParentMessage(e) {

        //     console.log('handleParentMessage_e', e)
        //     // this.getvideoPlayerView(e.Key)

        //     this.getMediaUrl(e.Key)

        // },


        //视频加载完成事件
        // videoLoadedData(){
        //     const video = this.$refs.videoPlayer;
        //     this.totalTime = this.formatTime(video.duration)
        //     this.nowTime = this.formatTime(video.currentTime)
        //     loading.close()
        // },




        //更新进度条
        // videoTimeUpdate() {
        //     console.log('进度更新')
        //     const video = this.$refs.videoPlayer;
        //     console.log(video.currentTime, video.duration)
        //     const progress = (video.currentTime / video.duration) * 100;
        //     this.totalTime = this.formatTime(video.duration)
        //     this.nowTime = this.formatTime(video.currentTime)

        //     console.log('this.nowTime',this.nowTime)
        //     console.log('this.totalTime',this.totalTime)


        //     this.progress = Math.round(progress);
        // },
        // formatTime(seconds) {
        //     var hours = Math.floor(seconds / 3600);
        //     var minutes = Math.floor((seconds % 3600) / 60);
        //     var remainingSeconds = Math.floor(seconds % 60);

        //     var timeString = '';

        //     if (hours > 0) {
        //         timeString += hours + ':';
        //     }

        //     if (minutes > 0) {
        //         timeString += minutes + ':';
        //     }

        //     // if (remainingSeconds <  1){
        //     timeString += remainingSeconds;
        //     // }
        //     return timeString
        // },

        // displayProgress(currentTime, duration) {

        //     var progress = (currentTime / duration) * 100;
        //     var formattedCurrentTime = formatTime(currentTime);
        //     var formattedDuration = formatTime(duration);

        //     console.log('当前进度：' + progress.toFixed(2) + '%');
        //     console.log('已播放时间：' + formattedCurrentTime);
        //     console.log('视频总时长：' + formattedDuration);
        // },


        //播放结束事件
        // endedVideo() {
        //     console.log('播放结束')
        //     this.playVideoButtonShow = true
        // },

        // stopVideo() {
        //     this.$refs.videoPlayer.pause();
        //     this.$refs.videoPlayer.currentTime = 0;

        //     this.playVideoButtonShow = true

        // },




        //播放视频
        // playVideo() {
        //     this.$refs.videoPlayer.play()
        //     //隐藏播放按钮，显示暂停按钮

        //     this.playVideoButtonShow = false

        // },

        // //暂停视频
        // pauseVideo() {
        //     this.$refs.videoPlayer.pause()

        //     //显示播放按钮
        //     this.playVideoButtonShow = true
        // },

        // closeVideoPlayer() {

        //     this.videoUrl = null
        //     this.playVideoButtonShow = true
        //     this.progress= 0,
        //     this.nowTime= 0,
        //     this.totalTime=0

        //     // this.$refs.videoPlayer.pause()

        //     //显示播放按钮
        //     // this.playVideoButtonShow = true
        // },







        // async getMediaUrl(key) {
        //     loading = this.$loading({
        //         lock: true,
        //         text: '加载数据中',
        //         spinner: 'el-icon-loading',
        //         background: 'rgba(0, 0, 0, 0.7)'
        //     });
        //     var res = await this.$MyCloudFiles.getMyCloudMideaUrl(key)
        //     console.log('多媒体的预览地址是', res)
        //     this.videoUrl = res
        //     //             var Bucket = 'dadastar-1307054034'
        //     // var Region = 'ap-shanghai'


        // },




    }
}
</script>
<style></style>