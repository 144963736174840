
import * as THREE from 'three';

// import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
import * as TWEEN from "tween.js";

var cameraAnimationTweenArr = []
var modelRotateTweenArr = []
var thisStepPartAnimation = []
var Model_children_visibleInfoArr = []
//自动摄像机动画，自动将模型最大化显示
var AutoCamera = true
var model, camera, targetGlobleStepNum, partAndGroupArr, OrbitControl, lDrawLoader, div_three


// // // 获取对应的 div 元素
// // const div = this.$refs.mpdview;
// div_three = 
// // 获取宽高
// const canvasWidth = div_three.offsetWidth;

//播放本步骤动画
//主模型旋转动画
//零件安装动画
//摄像机运镜动画
//动态显示与隐藏模型
class DoStepAnimation {

    constructor() {



        // console.log('文件处理工具的实例化传入数据是:', userInfo)

    }

    setModel(model_11) {
        model = model_11
    }

    setAutoCamera(bool) {

        if (bool) {
            AutoCamera = true
        } else {
            AutoCamera = false
        }
    }

    async playStepAnimation(stepPartsInfo, model_, camera_, targetGlobleStepNum_, partAndGroupArr_, OrbitControl_, Model_children_visibleInfoArr_, lDrawLoader_, div_three_) {

        //停止所有的动画
        this.stopAllTweenAnimation()
        //不清空数组的话会出现打开下一个模型还使用上一个模型的主模型旋转tween数据
        cameraAnimationTweenArr = []
        modelRotateTweenArr = []
        // thisStepPartAnimation = []
        Model_children_visibleInfoArr = []


        model = model_
        camera = camera_
        targetGlobleStepNum = targetGlobleStepNum_
        partAndGroupArr = partAndGroupArr_
        OrbitControl = OrbitControl_
        Model_children_visibleInfoArr = Model_children_visibleInfoArr_
        lDrawLoader = lDrawLoader_
        div_three = div_three_


        // this.seeAllPars = false

        // const stepPartsInfo = this.GetObjectsByGlobleStep(targetGlobleStepNum)

        //启动渲染（如果渲染没启用的话）
        // EnableRender
        // this.updataRenderer()

        var ObjArrInThisGlobleStep_arr = stepPartsInfo.FindObjectsArr

        if (ObjArrInThisGlobleStep_arr == null) {

            //空组则返回

            return

        }

        if (ObjArrInThisGlobleStep_arr.length == 1 && ObjArrInThisGlobleStep_arr[0].name == 'MY_EmPtY_SteP.dat') {

            return
        }

        var stepParent = stepPartsInfo.stepParent


        // 碗面弄
        // this.stepPath = ObjArrInThisGlobleStep_arr[0].userData.stepPath


        console.log('stepParent030303', stepParent)
        // stepParent,
        //     FindObjectsArr
        console.log('ObjArrInThisGlobleStep_arr222', ObjArrInThisGlobleStep_arr)


        // var stepParent = ObjArrInThisGlobleStep_arr[0].parent




        //外面弄
        //生成本步骤的零件列表
        // this.partsListImagesData = mpdPartListCreater.getImagesArr(ObjArrInThisGlobleStep_arr)





        // mpdPartListCreater.CreatePartListImagesArr(ObjArrInThisGlobleStep_arr)
        // console.log('生成的零件列表是：', this.partsListImagesData)
        //设置零件可见性


        this.setStepPartsVisible(stepParent, targetGlobleStepNum)

        this.setStandInChildrenVisibleInStandInGroup(targetGlobleStepNum)




        //停止所有的动画
        this.stopAllTweenAnimation()

        // stopAllTweenAnimation()
        // 
        // //停止所有的相机动画
        this.stopStepCameraTweenData()

        //停止所有的主模型旋转动画
        this.stopAllModelRotateAnimation()






        console.log('ObjArrInThisGlobleStep_arr', ObjArrInThisGlobleStep_arr)

        //显示或者隐藏本步骤中需要根据userdata中动态显示或者隐藏的零件
        this.PartOrGroupVisibleByStepData(model, ObjArrInThisGlobleStep_arr[0].userData.MyStep, Model_children_visibleInfoArr)






        //还原零件的安装位置
        this.placePositionAndRotation(ObjArrInThisGlobleStep_arr)


        //步骤动画中设置零件动画前是显示还是隐藏，执行出来
        this.setPartOrGroupVisibleByPartAnimation(ObjArrInThisGlobleStep_arr)


        //主模型旋转动画
        await this.rotateParentAnimation(ObjArrInThisGlobleStep_arr);

        model.updateMatrixWorld()

        console.log('主模型旋转结束')



        this.CreateStepCameraTweenData(ObjArrInThisGlobleStep_arr);



        console.log('摄像机动画开始')

        //播放摄像机动画
        await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);

        console.log('摄像机动画结束')



        if (ObjArrInThisGlobleStep_arr.length <= 15) {

            // this.$message({
            //     message: '本步骤中零件数大于15,不执行动画',
            //     type: 'warning'
            // });
            this.CreateStepPartsTweenDataOnStep(ObjArrInThisGlobleStep_arr)

            // return

        } else {
            console.warn('本步骤中零件数大于15,不执行零件动画')
        }

        //从该步骤第一个零件的userdata中获取到该步骤的camera的动画信息并更新到右侧摄像机动画列表中
        // this.getCameraAnimatinFromPart(StepCameraData_Part);



        console.log('主模型旋转结束开始')

        // //主模型旋转动画
        // await this.rotateParentAnimation(ObjArrInThisGlobleStep_arr);

        // console.log('主模型旋转结束')



        // this.CreateStepCameraTweenData(ObjArrInThisGlobleStep_arr);



        // console.log('摄像机动画开始')

        // //播放摄像机动画
        // await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);

        // console.log('摄像机动画结束')


        //重新确认显示状况，如果不对则更正（不对的情况主要是出现在重复点击步骤来回切换的时候，有些不该显示的显示了）
        // this.setStepPartsVisible(ObjArrInThisGlobleStep_arr, stepParent, targetGlobleStepNum)



        console.log('本步骤零件动画开始')

        //播放本步骤的动画
        await this.playThisStepPartOrGroupAnimation(ObjArrInThisGlobleStep_arr)


        this.placePositionAndRotation()

        console.log('本步骤零件动画结束')


        // const that = this
        // setTimeout(() => {
        //     that.playThisStepPartOrGroupAnimation(ObjArrInThisGlobleStep_arr)

        // }, 5000);

        //重新确认显示状况，如果不对则更正（不对的情况主要是出现在重复点击步骤来回切换的时候，有些不该显示的显示了）
        // this.setStepPartsVisible(stepParent, targetGlobleStepNum)


        //显示或者隐藏本步骤中需要根据userdata中动态显示或者隐藏的零件
        // this.PartOrGroupVisibleByStepData(model, ObjArrInThisGlobleStep_arr[0].userData.MyStep)



        console.log('零件动画执行完毕')




        // //为没有动画的零件设置步骤动画，设在userdata中
        // if (this.AnimationAuto) {
        //     this.CreateAnimationListData(ObjArrInThisGlobleStep_arr);
        // }

        // //存放相机动画的零件
        // var StepCameraData_Part = StepAnimationInfo[0]

        // //如果设置为执行动画才执行动画
        // if (this.DoAnimation) {

        //     //创建步骤中的零件动画数据
        //     this.CreateStepPartsTweenData(ObjArrInThisGlobleStep_arr);


        //     // 这个功能暂时还没有调试，先隐藏，下一版本开放
        //     // 就算下方函数去除注释也还不能用哈
        //     // this.AddChildrenAnimation()



        //     //主模型旋转动画
        //     await this.rotateParentAnimation();




        //     //从该步骤第一个零件的userdata中获取到该步骤的camera的动画信息并更新到右侧摄像机动画列表中
        //     this.getCameraAnimatinFromPart(StepCameraData_Part);

        //     this.CreateStepCameraTweenData();

        //     //执行它摄像机动画---异步执行
        //     // this.TweenArrAnimationsDo(cameraAnimationTweenArr)

        //     ////执行它摄像机动画---异步执行
        //     await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);

        //     //执行步骤中的零件动画
        //     await this.TweenArrAnimationsDo(StepAnimationTweenArr);

        //     console.log("所有动画完成2");
        // }





        // ChooseObjArrBottom = [];


        // var myEvent = {
        //     parentId: showgroup_.id,
        //     partEmpty: ChoosePartIDArr.length == 0,
        //     partIDArr: ChoosePartIDArr,
        //     partName: showgroup_.name,
        //     stepNum: targetStepNum - 1,
        // };

        //将该组移动到指定步骤

        //3d图设置步骤
        // this.handleLeftStepImageClicked(myEvent, true);

        // set3DShow会调用这个方法handleLeftStepImageClicked()，这个方法会将ThisFoorChooseStepNum减小1，所以我们现在要将它增加1
        // showgroup_.userData.ThisFoorChooseStepNum += 1;
    }




    //根据局部步骤来执行动画
    //这和playStepAnimation全局动画执行有区别，尤其是在某个局部步骤中有零件a又有组b，在组内又有多个步骤1，2，3。。。的情况下
    async playStepAnimationByLocalStep(stepPartsInfo, model_, camera_, targetGlobleStepNum_, partAndGroupArr_, OrbitControl_, Model_children_visibleInfoArr_, lDrawLoader_, div_three_) {

        //停止所有的动画
        this.stopAllTweenAnimation()
        //不清空数组的话会出现打开下一个模型还使用上一个模型的主模型旋转tween数据
        cameraAnimationTweenArr = []
        modelRotateTweenArr = []
        // thisStepPartAnimation = []
        Model_children_visibleInfoArr = []


        model = model_
        camera = camera_


        // targetGlobleStepNum = targetGlobleStepNum_
        partAndGroupArr = partAndGroupArr_
        OrbitControl = OrbitControl_
        Model_children_visibleInfoArr = Model_children_visibleInfoArr_
        lDrawLoader = lDrawLoader_
        div_three = div_three_

        console.log('typeof targetGlobleStepNum', typeof targetGlobleStepNum_)

        console.log('targetGlobleStepNum_rrrrr', targetGlobleStepNum_, typeof targetGlobleStepNum_ === 'number')

        //有可能是纯数字格式，也有可能是11-16格式，如果有多个，就按照组大的传
        if (typeof targetGlobleStepNum_ === 'number') {
            targetGlobleStepNum = targetGlobleStepNum_
        } else if (typeof targetGlobleStepNum_ === 'string' && targetGlobleStepNum_.indexOf('-') != -1) {
            targetGlobleStepNum = targetGlobleStepNum_.split('-')[1] * 1
        }






        // this.seeAllPars = false


        // const stepPartsInfo = this.GetObjectsByGlobleStep(targetGlobleStepNum)


        //启动渲染（如果渲染没启用的话）
        // EnableRender

        // this.updataRenderer()





        var ObjArrInThisGlobleStep_arr = stepPartsInfo.FindObjectsArr

        if (ObjArrInThisGlobleStep_arr == null) {

            //空组则返回

            return

        }

        if (ObjArrInThisGlobleStep_arr.length == 1 && ObjArrInThisGlobleStep_arr[0].name == 'MY_EmPtY_SteP.dat') {

            return
        }

        var stepParent = stepPartsInfo.stepParent


        // 碗面弄
        // this.stepPath = ObjArrInThisGlobleStep_arr[0].userData.stepPath


        console.log('stepParent0303033333', stepParent)
        // stepParent,
        //     FindObjectsArr
        console.log('ObjArrInThisGlobleStep_arr222wwe', ObjArrInThisGlobleStep_arr)


        // var stepParent = ObjArrInThisGlobleStep_arr[0].parent




        //外面弄
        //生成本步骤的零件列表
        // this.partsListImagesData = mpdPartListCreater.getImagesArr(ObjArrInThisGlobleStep_arr)





        // mpdPartListCreater.CreatePartListImagesArr(ObjArrInThisGlobleStep_arr)
        // console.log('生成的零件列表是：', this.partsListImagesData)
        //设置零件可见性


        // this.setStepPartsVisible(stepParent, targetGlobleStepNum, false)

        this.setStepPartsVisible_localStep(stepParent, false)


        //这是局部步骤，替身零件显示，如果是组，就按照组内的的最大步全局步骤骤显示，如果是零件，就正常显示当前局部步骤对应的全局步骤

        this.setStandInChildrenVisibleInStandInGroup(targetGlobleStepNum)

        // //停止所有的动画
        // this.stopAllTweenAnimation()
        // 
        // //停止所有的相机动画
        this.stopStepCameraTweenData()

        //停止所有的主模型旋转动画
        this.stopAllModelRotateAnimation()

        console.log('ObjArrInThisGlobleStep_arr', ObjArrInThisGlobleStep_arr)

        //显示或者隐藏本步骤中需要根据userdata中动态显示或者隐藏的零件
        this.PartOrGroupVisibleByStepData(model, ObjArrInThisGlobleStep_arr[0].userData.MyStep, Model_children_visibleInfoArr)

        //还原零件的安装位置
        this.placePositionAndRotation()

        //主模型旋转动画
        await this.rotateParentAnimation(ObjArrInThisGlobleStep_arr);

        //主模型旋转动画
        await this.rotateParentAnimation(ObjArrInThisGlobleStep_arr);

        this.CreateStepCameraTweenData(ObjArrInThisGlobleStep_arr);

        console.log('摄像机动画开始')

        //播放摄像机动画
        await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);



        if (ObjArrInThisGlobleStep_arr.length <= 15) {

            // this.$message({
            //     message: '本步骤中零件数大于15,不执行动画',
            //     type: 'warning'
            // });
            this.CreateStepPartsTweenDataOnStep(ObjArrInThisGlobleStep_arr)

            // return

        } else {
            console.warn('本步骤中零件数大于15,不执行零件动画')
        }

        //从该步骤第一个零件的userdata中获取到该步骤的camera的动画信息并更新到右侧摄像机动画列表中
        // this.getCameraAnimatinFromPart(StepCameraData_Part);



        console.log('主模型旋转结束开始')

        // //主模型旋转动画
        // await this.rotateParentAnimation(ObjArrInThisGlobleStep_arr);

        console.log('主模型旋转结束')



        // this.CreateStepCameraTweenData(ObjArrInThisGlobleStep_arr);



        // console.log('摄像机动画开始')

        // //播放摄像机动画
        // await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);

        console.log('摄像机动画结束')


        //重新确认显示状况，如果不对则更正（不对的情况主要是出现在重复点击步骤来回切换的时候，有些不该显示的显示了）
        // this.setStepPartsVisible(ObjArrInThisGlobleStep_arr, stepParent, targetGlobleStepNum)



        console.log('本步骤零件动画开始')

        //播放本步骤的动画
        await this.playThisStepPartOrGroupAnimation(ObjArrInThisGlobleStep_arr)

        console.log('本步骤零件动画结束')


        // const that = this
        // setTimeout(() => {
        //     that.playThisStepPartOrGroupAnimation(ObjArrInThisGlobleStep_arr)

        // }, 5000);

        //重新确认显示状况，如果不对则更正（不对的情况主要是出现在重复点击步骤来回切换的时候，有些不该显示的显示了）
        // this.setStepPartsVisible(stepParent, targetGlobleStepNum)


        //显示或者隐藏本步骤中需要根据userdata中动态显示或者隐藏的零件
        // this.PartOrGroupVisibleByStepData(model, ObjArrInThisGlobleStep_arr[0].userData.MyStep)

        //显示或者隐藏本步骤中需要根据userdata中动态显示或者隐藏的零件
        // this.PartOrGroupVisibleByStepData(model, ObjArrInThisGlobleStep_arr[0].userData.MyStep, Model_children_visibleInfoArr)

        // //还原零件的安装位置
        // this.placePositionAndRotation()



        console.log('零件动画执行完毕')




        // //为没有动画的零件设置步骤动画，设在userdata中
        // if (this.AnimationAuto) {
        //     this.CreateAnimationListData(ObjArrInThisGlobleStep_arr);
        // }

        // //存放相机动画的零件
        // var StepCameraData_Part = StepAnimationInfo[0]

        // //如果设置为执行动画才执行动画
        // if (this.DoAnimation) {

        //     //创建步骤中的零件动画数据
        //     this.CreateStepPartsTweenData(ObjArrInThisGlobleStep_arr);


        //     // 这个功能暂时还没有调试，先隐藏，下一版本开放
        //     // 就算下方函数去除注释也还不能用哈
        //     // this.AddChildrenAnimation()



        //     //主模型旋转动画
        //     await this.rotateParentAnimation();




        //     //从该步骤第一个零件的userdata中获取到该步骤的camera的动画信息并更新到右侧摄像机动画列表中
        //     this.getCameraAnimatinFromPart(StepCameraData_Part);

        //     this.CreateStepCameraTweenData();

        //     //执行它摄像机动画---异步执行
        //     // this.TweenArrAnimationsDo(cameraAnimationTweenArr)

        //     ////执行它摄像机动画---异步执行
        //     await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);

        //     //执行步骤中的零件动画
        //     await this.TweenArrAnimationsDo(StepAnimationTweenArr);

        //     console.log("所有动画完成2");
        // }





        // ChooseObjArrBottom = [];


        // var myEvent = {
        //     parentId: showgroup_.id,
        //     partEmpty: ChoosePartIDArr.length == 0,
        //     partIDArr: ChoosePartIDArr,
        //     partName: showgroup_.name,
        //     stepNum: targetStepNum - 1,
        // };

        //将该组移动到指定步骤

        //3d图设置步骤
        // this.handleLeftStepImageClicked(myEvent, true);

        // set3DShow会调用这个方法handleLeftStepImageClicked()，这个方法会将ThisFoorChooseStepNum减小1，所以我们现在要将它增加1
        // showgroup_.userData.ThisFoorChooseStepNum += 1;
    }

    //在替身组内，显示对应的替身零件，而不是全显示
    //这一步可以在设置播放动画前设置可见性的后面一步做
    setStandInChildrenVisibleInStandInGroup(Global_step) {

        console.log('设置替身可见性222')

        //找到所有的替身组
        //在所有的替身组里面找到本步骤需要设置可见性的零件
        //然后设置可见性

        var StandInGroupArrInModel = []

        model.traverse(c => {

            if (c.isGroup) {
                if (c.userData.TYPE && c.userData.TYPE == 'GROUP') {

                    if (c.userData.isStandInGroup) {

                        StandInGroupArrInModel.push(c)

                    }

                }

            }
        })

        for (let i = 0; i < StandInGroupArrInModel.length; i++) {

            if (StandInGroupArrInModel[i].userData.StandInSet) {

                //看看set中有没有针对本步骤可见性的设置
                //如第7步;第10步有相应的替身设置，我现在在第9步,于是我就以最近的前面的第7步这是为准
                var setArr = StandInGroupArrInModel[i].userData.StandInSet

                // var step_Useful = 
                var showIndex_Useful = 1

                for (let j = 0; j < setArr.length; j++) {

                    // var j_set = 

                    // var j_set_arr = setArr[j].split(';')

                    var step = setArr[j].step

                    var showIndex = setArr[j].showIndex

                    if (step <= Global_step) {

                        showIndex_Useful = showIndex - 1

                    }


                    //不用查了，后面步骤太大
                    if (step > Global_step) {

                        break

                    }

                    // if(setArr[j]){

                    // }

                }

                StandInGroupArrInModel[i].userData.StandInSet_showIndex = showIndex_Useful
            }

        }

        console.log('StandInGroupArrInModel', StandInGroupArrInModel)


        //根据显示的替身子对象设置显示或者隐藏子对象
        for (let i = 0; i < StandInGroupArrInModel.length; i++) {

            var showChildrenIndex = StandInGroupArrInModel[i].userData.StandInSet_showIndex

            for (let j = 0; j < StandInGroupArrInModel[i].children.length; j++) {

                if (StandInGroupArrInModel[i].children[j].userData.TYPE && (StandInGroupArrInModel[i].children[i].userData.TYPE == 'GROUP' || StandInGroupArrInModel[i].children[i].userData.TYPE == 'PART')) {

                    // if(j == showChildrenIndex){

                    //     //可见性设置后它是显示的才显示，否则不显示
                    //     //比如我现在在后面的子步骤里面显示搭建图，这个零件就应该隐藏
                    //     if(){

                    //     }

                    //     StandInGroupArrInModel[i].children[j].visible = true

                    // }else{

                    //     StandInGroupArrInModel[i].children[j].visible = false

                    //     console.log('隐藏子替身',StandInGroupArrInModel[i])

                    // }

                    //不该显示的就设为隐藏
                    if (j != showChildrenIndex) {
                        StandInGroupArrInModel[i].children[j].visible = false
                    }



                }

            }

        }





    }

    //comeBackMaterials 是否按照userdata中的颜色本分来恢复颜色
    setStepPartsVisible(stepParent, targetGlobleStepNum, comeBackMaterials = true) {

        for (let i = 0; i < partAndGroupArr.length; i++) {

            let c = partAndGroupArr[i]
            if (c.userData.TYPE && c.userData.TYPE == 'PART') {
                c.visible = false;

                if (comeBackMaterials) {
                    this.changeColorByLdrawRule(c, c.userData.colorCode, lDrawLoader.materialLibrary)

                }

                //是原色就跳过
                // if (c.userData.ColorState && c.userData.ColorState == 'OriginalColor') { continue }

                //是原始颜色




                // c.userData.ColorState = 'OriginalColor'



                // partAndGroupArr[i].traverse(c => {

                //     if (c.isLine) {

                //         if (c.name == 'MyBoxHelper') {

                //             if (c.visible != false) {

                //                 c.visible = false

                //             }

                //         } else {

                //             if (c.visible != true) {

                //                 c.visible = true

                //             }

                //         }

                //         // if()

                //     } else if (c.isMesh && Array.isArray(c.userData.colorCode)) {

                //         console.log("设为原色5555", c);
                //         //修改材质的颜色
                //         if (c.userData.colorCode.length > 1) {
                //             c.material = c.userData.colorCode.map(
                //                 function (color) {

                //                     return lDrawLoader.materialLibrary[color];
                //                     // return material_opaque;
                //                 }
                //             );
                //         } else {
                //             c.material = lDrawLoader.materialLibrary[c.userData.colorCode[0]];
                //             // console.log(c.material.name);
                //         }

                //     }



                // })



            } else if (c.userData.TYPE && c.userData.TYPE == 'GROUP') {
                //如果是零件组，都显示
                c.visible = true;
            }

        }

        // 先把stepParent内的所有零件或者组都可见
        stepParent.traverse((c) => {
            if (c.isGroup && c.userData.TYPE) {
                if (c.userData.TYPE && c.userData.TYPE == 'PART') {
                    c.visible = true;
                } else if (c.userData.TYPE && c.userData.TYPE == 'GROUP') {
                    c.visible = true;
                }
            }
        });

        //显示出本步骤的零件
        // if (e.x == true) {
        for (let i in stepParent.children) {
            //本步骤先隐藏
            if (stepParent.children[i].userData.MyStep == targetGlobleStepNum) {


                if (stepParent.children[i].visible != true) {

                    stepParent.children[i].visible = true;

                }

                //本级零件中，如果不是左侧菜单选中的对象，则将本级零件中，ThisFloorStepNum低于选中
                //步骤的组设为本透明材质
                //高于ThisFloorStepNum的零件隐藏visible=false
            } else if (stepParent.children[i].userData.MyStep < targetGlobleStepNum) {

                console.log('前面步骤变色')

                if (stepParent.children[i].visible != true) {

                    stepParent.children[i].visible = true

                }

            } else {
                //高于ThisFloorStepNum的零件隐藏visible=false
                if (stepParent.children[i].visible != false) {

                    stepParent.children[i].visible = false;

                }
            }

        }
    }

    //comeBackMaterials 是否按照userdata中的颜色本分来恢复颜色
    //局部步骤
    setStepPartsVisible_localStep(stepParent, comeKackMaterials = true) {

        //本层选中的局部步骤
        var chooseStep_loacl = stepParent.userData.ThisFoorChooseStepNum


        for (let i = 0; i < partAndGroupArr.length; i++) {

            let c = partAndGroupArr[i]
            if (c.userData.TYPE && c.userData.TYPE == 'PART') {
                c.visible = false;

                if (comeKackMaterials) {
                    this.changeColorByLdrawRule(c, c.userData.colorCode, lDrawLoader.materialLibrary)

                }

            } else if (c.userData.TYPE && c.userData.TYPE == 'GROUP') {
                //如果是零件组，都显示
                c.visible = true;
            }

        }

        // 先把stepParent内的所有零件或者组都可见
        stepParent.traverse((c) => {
            if (c.isGroup && c.userData.TYPE) {
                if (c.userData.TYPE && c.userData.TYPE == 'PART') {
                    c.visible = true;
                } else if (c.userData.TYPE && c.userData.TYPE == 'GROUP') {
                    c.visible = true;
                }
            }
        });

        //显示出本步骤的零件
        // if (e.x == true) {
        for (let i = 0; i < stepParent.children.length; i++) {
            //本步骤先隐藏
            if (stepParent.children[i].userData.ThisFloorStepNum + 1 == chooseStep_loacl) {


                if (stepParent.children[i].visible != true) {

                    stepParent.children[i].visible = true;

                }

                //本级零件中，如果不是左侧菜单选中的对象，则将本级零件中，ThisFloorStepNum低于选中
                //步骤的组设为本透明材质
                //高于ThisFloorStepNum的零件隐藏visible=false
            } else if (stepParent.children[i].userData.ThisFloorStepNum + 1 < chooseStep_loacl) {

                console.log('前面步骤变色')

                if (stepParent.children[i].visible != true) {

                    stepParent.children[i].visible = true

                }

            } else {
                //高于ThisFloorStepNum的零件隐藏visible=false
                if (stepParent.children[i].visible != false) {

                    stepParent.children[i].visible = false;

                }
            }

        }
    }

    //停止所有动画
    stopAllTweenAnimation() {

        console.log('thisStepPartAnimation333', thisStepPartAnimation.length, thisStepPartAnimation)

        for (let i = 0; i < thisStepPartAnimation.length; i++) {

            if (thisStepPartAnimation[i].positionTween) {

                console.log('停止位置动画', thisStepPartAnimation[i].positionTween)
                thisStepPartAnimation[i].positionTween.stop()
            }

            if (thisStepPartAnimation[i].rotationTween) {
                console.log('停止旋转动画', thisStepPartAnimation[i].positionTween)

                thisStepPartAnimation[i].rotationTween.stop()
            }

        }

        thisStepPartAnimation.length = 0

    }


    //停止所有的相机动画
    stopStepCameraTweenData() {

        if (cameraAnimationTweenArr.length == 0) { return }

        for (let i = 0; i < cameraAnimationTweenArr.length; i++) {

            cameraAnimationTweenArr[i].tween.stop()

            console.log('停止相机动画', cameraAnimationTweenArr[i], cameraAnimationTweenArr[i].tween)

            camera.lookAt(cameraAnimationTweenArr[i].lookat)

            camera.updateProjectionMatrix()

            OrbitControl.target.copy(cameraAnimationTweenArr[i].lookat);

            OrbitControl.update();

        }

        cameraAnimationTweenArr = []

    }


    //停止所有主模型旋转动画
    stopAllModelRotateAnimation() {

        console.log('停止动画旋转的参数', modelRotateTweenArr)

        for (let i = 0; i < modelRotateTweenArr.length; i++) {

            modelRotateTweenArr[i].tween.stop()

            model.quaternion.copy(modelRotateTweenArr[i].targetQuaternion)

            model.updateMatrix()

            // PromiseTweenArr.push(
            //     new Promise(function (resolve) {
            //         modelRotateTweenArr[i].tween.onComplete(resolve);
            //     })
            // );

        }



    }



    //根据零件内的userdata内的PartVisibleInStepArr数组信息在合适的步骤显示零件
    // showGlobalStep 显示的步骤
    PartOrGroupVisibleByStepData(Group, showGlobalStep) {


        //本步骤中需要修改可见性的零件
        console.log('Model_children_visibleInfoArr222', Model_children_visibleInfoArr, showGlobalStep, 1, '1')
        var NeedSetVisibleInThisStep = Model_children_visibleInfoArr[showGlobalStep]
        //没有则可以跳过了
        if (NeedSetVisibleInThisStep == null) { return }

        for (let i = 0; i < Group.children.length; i++) {

            //只处理零件和组
            if (Group.children[i].userData.TYPE == 'PART') {

                // 小于本步骤的零件才需要根据PartVisibleInStepArr信息显示或者隐藏
                // 大于等于本步骤的零件不需要根据PartVisibleInStepArr显示或隐藏，因为零件都还没安装呢
                if (Group.children[i].userData.MyStep < showGlobalStep) {

                    //开始根据Model_children_visibleInfoArr数组内的信息来显示或者隐藏前面步骤的某些零件了


                    console.log('看看本步骤中有哪些需要修改可见性的零件', NeedSetVisibleInThisStep)
                    //看看本步骤中有哪些需要修改可见性的零件
                    for (let j = 0; j < NeedSetVisibleInThisStep.length; j++) {

                        if (NeedSetVisibleInThisStep[j].id == Group.children[i].id) {

                            console.log('设置该零件的可见性', j, Group.children[i], NeedSetVisibleInThisStep[j])

                            if (Group.children[i].visible != NeedSetVisibleInThisStep[j].visible) {
                                Group.children[i].visible = NeedSetVisibleInThisStep[j].visible

                            }

                        }

                    }

                }

            } else if (Group.children[i].userData.TYPE == 'GROUP') {

                this.PartOrGroupVisibleByStepData(Group.children[i], showGlobalStep)

            }


        }
    }

    //还原零件的安装位置和安装旋转
    placePositionAndRotation() {

        //还原到安装位置后再生成图片
        model.traverse(c => {
            if (c.isGroup) {
                if (c.userData.TYPE == 'GROUP' || c.userData.TYPE == 'PART') {
                    if (c.userData.placePosition && c.userData.placeQuaternion) {

                        console.log('placePosition5555 ', c.userData.placePosition)
                        console.log('placeRotation5555 ', c.userData.placeQuaternion)

                        var placePosition = c.userData.placePosition
                        // var placeRotation = c.userData.placeRotation
                        var placeQuaternion = c.userData.placeQuaternion

                        //设四元数稳定些，设欧拉角会有极少数情况的旋转错误，比如调试使用的相机模型
                        c.position.set(placePosition.x, placePosition.y, placePosition.z)

                        // c.rotation.set(placeRotation.x, placeRotation.y, placeRotation.z)

                        //设四元数稳定些，设欧拉角会有极少数情况的旋转错误，比如调试使用的相机模型
                        c.quaternion.set(
                            placeQuaternion.x,
                            placeQuaternion.y,
                            placeQuaternion.z,
                            placeQuaternion.w
                        )

                        // placeQuaternion

                        // c.updateMatrix()

                    }
                }
            }

        })

    }

    // placePositionAndRotation() {

    //     //还原到安装位置后再生成图片
    //     model.traverse(c => {
    //         if (c.isGroup) {
    //             if (c.userData.TYPE == 'GROUP' || c.userData.TYPE == 'PART') {
    //                 if (c.userData.placePosition && c.userData.placeRotation) {

    //                     console.log('placePosition5555 ', c.userData.placePosition)
    //                     console.log('placeRotation5555 ', c.userData.placeRotation)

    //                     var placePosition = c.userData.placePosition
    //                     // var placeRotation = c.userData.placeRotation
    //                     var placeQuaternion = c.userData.placeQuaternion

    //                     //设四元数稳定些，设欧拉角会有极少数情况的旋转错误，比如调试使用的相机模型
    //                     c.position.set(placePosition.x, placePosition.y, placePosition.z)

    //                     // c.rotation.set(placeRotation.x, placeRotation.y, placeRotation.z)

    //                     //设四元数稳定些，设欧拉角会有极少数情况的旋转错误，比如调试使用的相机模型
    //                     c.quaternion.set(
    //                         placeQuaternion.x,
    //                         placeQuaternion.y,
    //                         placeQuaternion.z,
    //                         placeQuaternion.w
    //                     )

    //                     // placeQuaternion

    //                     // c.updateMatrix()

    //                 }
    //             }
    //         }

    //     })

    // }


    CreateStepPartsTweenDataOnStep(partsInStep_arr) {

        thisStepPartAnimation.length = 0
        //所有的tween只能执行一次，本来是计划stop 后start 可以实现重复使用的，结果发现不行，只能执行一遍就不能执行第二遍了，不知为啥
        //所以每次播放前都会重新生成tween动画

        // 
        //现在我们可以将该步骤中的动画信息提取出来后执行显示出来

        // var ThisAndBeforeStepPartArr = [];

        // var StepAnimationInfo = []

        //还原位置和旋转

        //移除所有正在执行的动画
        //这一步要改，要将所有的零件的或者组的安装动画停止
        // this.removeAllStepAnimationAndPartRestorePositionAndRotation();

        //此处还需要停止所有动画

        //所有组和零件还原到备份位置

        // for (let i = 0; i < partAndGroupArr.length; i++) {

        //     // console.log('partAndGroupArr3333444', partAndGroupArr)

        //     if (!partAndGroupArr[i].userData.placePosition) { continue }
        //     let placePosition = partAndGroupArr[i].userData.placePosition

        //     // partAndGroupArr[i].position.set(placePosition.x, placePosition.y, placePosition.z)


        //     if (partAndGroupArr[i].position.x != placePosition.x || partAndGroupArr[i].position.y != placePosition.y || partAndGroupArr[i].position.z != placePosition.z) {
        //         partAndGroupArr[i].position.set(placePosition.x, placePosition.y, placePosition.z)
        //     }

        //     // if (!partAndGroupArr[i].position) { continue }

        //     if (!partAndGroupArr[i].userData.placeRotation) { continue }

        //     let placeRotation = partAndGroupArr[i].userData.placeRotation

        //     // if (partAndGroupArr[i].rotation.x != placeRotation.x || partAndGroupArr[i].rotation.y != placeRotation.y || partAndGroupArr[i].rotation.z != placeRotation.z) {
        //     partAndGroupArr[i].quaternion.set(placeRotation.x, placeRotation.y, placeRotation.z, placeRotation.w)
        //     // }
        // }

        // 创建一个数组来存储插值后的四元数
        //本来可以在j的for里面新建变量来做的，结果出现了变量公用，同一个步骤多个零件有动画的话四元数旋转将会公用，很荒谬的错误
        //所以只能这样在i循环外面存储四元数差值，我也很费解，可能与tweenjs的工作原理有关
        var interpolatedQuaternionsArr = [];


        //根据动画信息生成tween动画放置到零件或者组的userdata中
        for (let i = 0; i < partsInStep_arr.length; i++) {

            interpolatedQuaternionsArr[i] = []

            console.log('partsInStep_arr0000', partsInStep_arr[i])

            if (partsInStep_arr[i].userData.stepAnimationInfo) {



                partsInStep_arr[i].userData.stepAnimationTween = []

                const PartOrGroupAnimationInfoArr = partsInStep_arr[i].userData.stepAnimationInfo
                //遍历该零件或者组的动画数组生成生成TWEEN动画数据存放到userdata中
                for (let j = 0; j < PartOrGroupAnimationInfoArr.length; j++) {

                    //
                    interpolatedQuaternionsArr[i][j] = []

                    // for (let j in PartOrGroupAnimationInfoArr_jArr) {
                    var PartOrGroupAnimationInfoArr_j = PartOrGroupAnimationInfoArr[j];

                    const endPosition = PartOrGroupAnimationInfoArr_j.endPosition;

                    const endRotation = PartOrGroupAnimationInfoArr_j.endRotation;
                    console.log('endRotation3333', endRotation)

                    console.log('endPosition12112', endPosition)

                    //延迟动画参数
                    var startDelay;
                    if (PartOrGroupAnimationInfoArr_j.startDelay) {
                        startDelay = PartOrGroupAnimationInfoArr_j.startDelay;
                    } else {
                        startDelay = 0;
                    }

                    //延迟期间的可见性 PartOrGroupAnimationInfoArr_j.startDelay 延迟启动才能使用可见性false
                    // if (PartOrGroupAnimationInfoArr_j.delayVisible == false && startDelay != 0 && PartOrGroupAnimationInfoArr_j.startDelay) {
                    //

                    //延迟期间的可见性 stepAnimationInfo_obj.startDelay 延迟启动才能使用可见性false
                    // if (stepAnimationInfo_obj.delayVisible == false && startDelay != 0 && stepAnimationInfo_obj.startDelay) {
                    //
                    if (PartOrGroupAnimationInfoArr_j.delayVisible) {
                        partsInStep_arr[i].visible = true;
                    } else {
                        partsInStep_arr[i].visible = false;
                    }


                    console.log(
                        "动画前的可见性 ",
                        PartOrGroupAnimationInfoArr_j,
                        PartOrGroupAnimationInfoArr_j.delayVisible
                    );

                    // if (PartOrGroupAnimationInfoArr_j.delayVisible) {
                    //     showgroup.children[i].visible = true;
                    // } else {
                    //     showgroup.children[i].visible = false;
                    // }

                    // 创建一个Tween对象用于修改位置
                    // showgroup.children[i].position.copy(PartOrGroupAnimationInfoArr_j.startPosition)

                    var startPosition = new THREE.Vector3(
                        PartOrGroupAnimationInfoArr_j.startPosition.x,
                        PartOrGroupAnimationInfoArr_j.startPosition.y,
                        PartOrGroupAnimationInfoArr_j.startPosition.z
                    );

                    var startRoatatin = new THREE.Quaternion(
                        PartOrGroupAnimationInfoArr_j.startRotation.x,
                        PartOrGroupAnimationInfoArr_j.startRotation.y,
                        PartOrGroupAnimationInfoArr_j.startRotation.z,
                        PartOrGroupAnimationInfoArr_j.startRotation.w
                    );

                    //直接将物体移动到 startPosition和rotation
                    // stepAnimationInfo.delayTransformation = false
                    // stepAnimationInfo.delayVisible = false

                    // if (!PartOrGroupAnimationInfoArr_j.delayTransformation) {
                    //     //     console.log('设置延迟前变换前的状态', startPosition)

                    //     showgroup.children[i].position.copy(startPosition);

                    //     showgroup.children[i].rotation.copy(startRoatatin);
                    // }

                    console.log(
                        "PartOrGroupAnimationInfoArr_j.duration",
                        PartOrGroupAnimationInfoArr_j.duration
                    );
                    // const positionTween = new TWEEN.Tween(showgroup.children[i].position);
                    // positionTween.to({ x: endPosition.x, y: endPosition.y, z: endPosition.z }, PartOrGroupAnimationInfoArr_j.duration);
                    const positionTween = new TWEEN.Tween({
                        x: startPosition.x,
                        y: startPosition.y,
                        z: startPosition.z

                    })
                        .to(
                            { x: endPosition.x, y: endPosition.y, z: endPosition.z },
                            PartOrGroupAnimationInfoArr_j.duration
                        )
                        // .onStart(function () {
                        //     partsInStep_arr[i].position.set(startPosition.x,startPosition.y,startPosition.z)
                        //     console.log('tween动画执行开始事件')

                        // })

                        // .easing(TWEEN.Easing.Quadratic.InOut) // 缓动函数，可根据需要选择合适的缓动函数
                        .onUpdate(function () {
                            // showgroup.children[i].visible = true;
                            // 在Tween对象的每个时间步骤更新中执行的操作
                            // 更新物体的位置等
                            partsInStep_arr[i].position.set(this.x, this.y, this.z);

                            partsInStep_arr[i].visible = true;


                        });


                    //慢入慢出情况
                    if (PartOrGroupAnimationInfoArr_j.AnimationEffects == "lowSpeedInlowSpeedOut") {
                        positionTween.easing(TWEEN.Easing.Quadratic.InOut); // 设置插值方式 慢入慢出 lowSpeedInlowSpeedOut

                        //匀速情况
                    } else if (PartOrGroupAnimationInfoArr_j.AnimationEffects == "uniformSpeed") {
                        positionTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed

                        //未定义情况，默认匀速
                    } else {
                        positionTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed
                    }

                    positionTween.delay(startDelay);



                    // const rotationTween = new TWEEN.Tween(startRoatatin);
                    // rotationTween.to(
                    //     { x: endRotation.x, y: endRotation.y, z: endRotation.z, w: endRotation.w },
                    //     PartOrGroupAnimationInfoArr_j.duration
                    // ).onUpdate(function () {
                    //     // showgroup.children[i].visible = true;
                    //     // 在Tween对象的每个时间步骤更新中执行的操作
                    //     // 更新物体的位置等
                    //     partsInStep_arr[i].quaternion.set(this.x, this.y, this.z,this.w);

                    //     // partsInStep_arr[i].updateMatrixWorld();
                    // });

                    console.log('startRoatatin3334567', startRoatatin)


                    var starQ = new THREE.Quaternion(startRoatatin.x, startRoatatin.y, startRoatatin.z, startRoatatin.w)
                    // starQ.setFromRotationMatrix(startMatrix)
                    var targetQ = new THREE.Quaternion(endRotation.x, endRotation.y, endRotation.z, endRotation.w)


                    console.log('222starQ,targetQ222', starQ, targetQ)




                    // 进行60个线性插值
                    for (var k = 1; k <= 60; k++) {

                        var t = k / 60; // 插值参数，范围从0到1

                        var interpolatedQuaternion = new THREE.Quaternion();

                        // 使用slerp方法进行线性插值
                        interpolatedQuaternion.slerpQuaternions(starQ, targetQ, t);

                        // 将插值后的四元数添加到数组中
                        interpolatedQuaternionsArr[i][j].push(interpolatedQuaternion);
                        // interpolatedQuaternionsArr.push(interpolatedQuaternion);
                    }

                    console.log('interpolatedQuaternionsArr', interpolatedQuaternionsArr)
                    // console.log(interpolatedQuaternionsArr[0])

                    // console.log('PartOrGroupAnimationInfoArr_j.duration', PartOrGroupAnimationInfoArr_j.duration)


                    // const rotationTween = new TWEEN.Tween()

                    // console.log('开始矩阵与目标矩阵', startMatrix, targetMatrix)
                    // 创建Tween对象
                    // PartOrGroupAnimationInfoArr_j.duration
                    const rotationTween = new TWEEN.Tween({ x: 0 })
                        .to({ x: 59 }, PartOrGroupAnimationInfoArr_j.duration)

                        .onUpdate(function () {
                            // console.log('播放选准动画的i', i)
                            // console.log(interpolatedQuaternionsArr[0])
                            // // 在每一帧更新物体的矩阵
                            // console.log('model.matrix32333', interpolatedQuaternionsArr[Math.ceil(this.x)])
                            // console.log('interpolatedQuaternions55555', interpolatedQuaternionsArr)
                            // partsInStep_arr[i].quaternion.copy(interpolatedQuaternions[Math.ceil(this.x)])
                            // interpolatedQuaternionsArr[i][j]

                            partsInStep_arr[i].quaternion.set(
                                interpolatedQuaternionsArr[i][j][Math.ceil(this.x)].x,
                                interpolatedQuaternionsArr[i][j][Math.ceil(this.x)].y,
                                interpolatedQuaternionsArr[i][j][Math.ceil(this.x)].z,
                                interpolatedQuaternionsArr[i][j][Math.ceil(this.x)].w,
                            )


                            // partsInStep_arr[i].quaternion.set(
                            //     interpolatedQuaternionsArr[Math.ceil(this.x)].x,
                            //     interpolatedQuaternionsArr[Math.ceil(this.x)].y,
                            //     interpolatedQuaternionsArr[Math.ceil(this.x)].z,
                            //     interpolatedQuaternionsArr[Math.ceil(this.x)].w,
                            // )
                            // partsInStep_arr[i].quaternion.x = interpolatedQuaternions[Math.ceil(this.x)].x
                            // partsInStep_arr[i].quaternion.y = interpolatedQuaternions[Math.ceil(this.x)].y
                            // partsInStep_arr[i].quaternion.z = interpolatedQuaternions[Math.ceil(this.x)].z
                            // partsInStep_arr[i].quaternion.w = interpolatedQuaternions[Math.ceil(this.x)].w

                        })
                    // .start(); // 开始动画





                    //慢入慢出情况
                    if (PartOrGroupAnimationInfoArr_j.AnimationEffects == "lowSpeedInlowSpeedOut") {
                        rotationTween.easing(TWEEN.Easing.Quadratic.InOut); // 设置插值方式 慢入慢出 lowSpeedInlowSpeedOut

                        //匀速情况
                    } else if (PartOrGroupAnimationInfoArr_j.AnimationEffects == "uniformSpeed") {
                        rotationTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed

                        //未定义情况，默认匀速
                    } else {
                        rotationTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed
                    }

                    rotationTween.delay(startDelay);

                    // partsInStep_arr[i].userData.stepAnimationTween.push({
                    //     positionTween,
                    //     rotationTween,
                    // });

                    console.log('存放零件动画')

                    thisStepPartAnimation.push(
                        {
                            positionTween,
                            rotationTween,
                        }
                    )

                }

            }

        }



    }

    //零件或者组根据安装动画设置来隐藏或者显示
    setPartOrGroupVisibleByPartAnimation(partsInStep_arr) {

        console.log('显示或者隐藏零件3333', partsInStep_arr)

        //所有组和零件还原到备份位置

        // for (let i = 0; i < partsInStep_arr.length; i++) {

        //     // console.log('partsInStep_arr3333444', partsInStep_arr)

        //     if (!partsInStep_arr[i].userData.placePosition) { continue }
        //     let placePosition = partsInStep_arr[i].userData.placePosition

        //     // partsInStep_arr[i].position.set(placePosition.x, placePosition.y, placePosition.z)


        //     if (partsInStep_arr[i].position.x != placePosition.x || partsInStep_arr[i].position.y != placePosition.y || partsInStep_arr[i].position.z != placePosition.z) {
        //         partsInStep_arr[i].position.set(placePosition.x, placePosition.y, placePosition.z)
        //     }

        //     // if (!partsInStep_arr[i].position) { continue }

        //     if (!partsInStep_arr[i].userData.placeRotation) { continue }

        //     let placeRotation = partsInStep_arr[i].userData.placeRotation

        //     // if (partsInStep_arr[i].rotation.x != placeRotation.x || partsInStep_arr[i].rotation.y != placeRotation.y || partsInStep_arr[i].rotation.z != placeRotation.z) {
        //         partsInStep_arr[i].quaternion.set(placeRotation.x, placeRotation.y, placeRotation.z, placeRotation.w)
        //     // }
        // }

        //
        for (let i = 0; i < partsInStep_arr.length; i++) {

            if (partsInStep_arr[i].userData.stepAnimationInfo) {

                partsInStep_arr[i].userData.stepAnimationTween = []

                const PartOrGroupAnimationInfoArr = partsInStep_arr[i].userData.stepAnimationInfo
                //遍历该零件或者组的动画数组生成生成TWEEN动画数据存放到userdata中
                for (let j = 0; j < PartOrGroupAnimationInfoArr.length; j++) {

                    var PartOrGroupAnimationInfoArr_j = PartOrGroupAnimationInfoArr[j]
                    var startPosition = new THREE.Vector3(
                        PartOrGroupAnimationInfoArr_j.startPosition.x,
                        PartOrGroupAnimationInfoArr_j.startPosition.y,
                        PartOrGroupAnimationInfoArr_j.startPosition.z
                    );

                    var startRoatatin = new THREE.Quaternion(
                        PartOrGroupAnimationInfoArr_j.startRotation.x,
                        PartOrGroupAnimationInfoArr_j.startRotation.y,
                        PartOrGroupAnimationInfoArr_j.startRotation.z,
                        PartOrGroupAnimationInfoArr_j.startRotation.w
                    );

                    // var PartOrGroupAnimationInfoArr_j = PartOrGroupAnimationInfoArr[j];

                    if (PartOrGroupAnimationInfoArr_j.delayVisible) {

                        partsInStep_arr[i].visible = true;

                    } else {

                        partsInStep_arr[i].visible = false;

                    }

                    //按照动画设置零件位置和旋转
                    if (!PartOrGroupAnimationInfoArr_j.delayTransformation) {
                        //     console.log('设置延迟前变换前的状态', startPosition)

                        partsInStep_arr[i].position.copy(startPosition);

                        partsInStep_arr[i].rotation.copy(startRoatatin);
                    }

                }

            }

        }

    }

    async rotateParentAnimation(ObjArrInThisGlobleStep_arr) {


        modelRotateTweenArr = []

        console.log("执行父级旋转动画rrrr", ObjArrInThisGlobleStep_arr);

        //找到本步骤的第一个零件

        var ThisStepAnimationInfoPart = ObjArrInThisGlobleStep_arr[0]

        // for (let i in showGroup.children) {

        //     if (showGroup.children[i].userData.TYPE == "PART" || showGroup.children[i].userData.TYPE == "GROUP") {
        //         // if (model.children[i].userData.startingBuildingStep == true) {
        //         if (
        //             showGroup.children[i].userData.ThisFloorStepNum + 1 ==
        //             showGroup.userData.ThisFoorChooseStepNum
        //         ) {
        //             ThisStepAnimationInfoPart = showGroup.children[i];

        //             console.log("获取父级旋转数据成功2", showGroup.children[i]);

        //             break;
        //         }
        //     }
        // }

        if (ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set) {
            //看看父级旋转目标旋转与当前的旋转是否相同
            //如果相同则返回
            //不需要执行旋转主模型的旋转动画
            var nowQua = new THREE.Quaternion();
            model.getWorldQuaternion(nowQua);

            var TarQua = new THREE.Quaternion();

            TarQua.setFromRotationMatrix(
                ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix
            );
            var angel = nowQua.angleTo(TarQua);

            //看看主模型与目标旋转位移之后相差多少距离，如果距离是0，且夹角是0则不用执行旋转位移动画
            //经测试，坐标点会有6以内的误差
            const targetPosition = new THREE.Vector3();

            targetPosition.setFromMatrixPosition(
                ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix
            );

            var distance_ = targetPosition.distanceTo(model.position);

            console.log("targetPosition", targetPosition);
            console.log(model.position);
            console.log("两个四元数之间的距离是", distance_);

            console.log("两个四元数之间的夹角是", angel);
            //夹角是0，不需要执行模型旋转动画
            if (angel == 0 && distance_ < 6) {
                model.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
                model.quaternion.copy(TarQua);
                console.log("不用执行主模型旋转动画");
                // continue
                return;
            }



            //没有相关的数据则返回
            if (!(ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set && ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix)) {
                return;
            }


            var PromiseTweenArr = [];

            var Model_tagetPosition = new THREE.Vector3()

            Model_tagetPosition.setFromMatrixPosition(ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix)

            console.log('model旋转的eeeee目标位置是', Model_tagetPosition)

            var position_tween = new TWEEN.Tween(model.position)
                .to({
                    x: Model_tagetPosition.x,
                    y: Model_tagetPosition.y,
                    z: Model_tagetPosition.z,
                }, 500
                )

                .start();

            // 执行动画后放入promise
            PromiseTweenArr.push(
                new Promise(function (resolve) {
                    position_tween.onComplete(resolve);
                })
            );

            model.updateWorldMatrix(true, true)
            var startMatrix = model.matrixWorld.clone()
            var targetMatrix = new THREE.Matrix4().copy(ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix)


            var starQ = new THREE.Quaternion()
            starQ.setFromRotationMatrix(startMatrix)
            var targetQ = new THREE.Quaternion()
            targetQ.setFromRotationMatrix(targetMatrix)

            console.log('starQ,targetQ', starQ, targetQ)

            // 创建一个数组来存储插值后的四元数
            var interpolatedQuaternions = [];


            // 进行60个线性插值
            for (var i = 0; i <= 60; i++) {
                var t = i / 60; // 插值参数，范围从0到1
                var interpolatedQuaternion = new THREE.Quaternion();

                // 使用slerp方法进行线性插值
                interpolatedQuaternion.slerpQuaternions(starQ, targetQ, t);

                // 将插值后的四元数添加到数组中
                interpolatedQuaternions.push(interpolatedQuaternion);
            }

            console.log('interpolatedQuaternions', interpolatedQuaternions)


            console.log('开始矩阵与目标矩阵', startMatrix, targetMatrix)
            // 创建Tween对象
            var tweenMatrix4 = new TWEEN.Tween({ x: 0 })
                .to({ x: 60 }, 500) // 设置动画的时间为2秒

                .onUpdate(function () {
                    // 在每一帧更新物体的矩阵
                    console.log('model.matrix32333', interpolatedQuaternions[Math.ceil(this.x)])

                    model.quaternion.x = interpolatedQuaternions[Math.ceil(this.x)].x
                    model.quaternion.y = interpolatedQuaternions[Math.ceil(this.x)].y
                    model.quaternion.z = interpolatedQuaternions[Math.ceil(this.x)].z
                    model.quaternion.w = interpolatedQuaternions[Math.ceil(this.x)].w

                })
            // .start(); // 开始动画


            //目前只有一个动画
            modelRotateTweenArr.push({
                tween: tweenMatrix4,
                targetQuaternion: targetQ
            })

            await this.doRotateModelAnimation()

            console.log('主模型旋转动画结束')
            // //执行动画后放入promise
            // PromiseTweenArr.push(
            //     new Promise(function (resolve) {
            //         tweenMatrix4.onComplete(resolve);
            //     })
            // );

            // //等待所有的promise完成
            // await Promise.all(PromiseTweenArr);

            // console.log(model);
            // console.log(
            //     "model.rotation",
            //     model.rotation.x,
            //     model.rotation.y,
            //     model.rotation.z
            // );

        }
    }

    //创建本步骤中摄像机的动画
    CreateStepCameraTweenData(ObjArrInThisGlobleStep_arr) {

        //本步骤中具有动画信息的零件
        var stepCameraAnimationPart = ObjArrInThisGlobleStep_arr[0]


        console.log("stepCameraAnimationPart", stepCameraAnimationPart);

        const camera_animationInfoDataArr = stepCameraAnimationPart.userData.CameraAnimationArr;

        if (camera_animationInfoDataArr == null || (Array.isArray(camera_animationInfoDataArr) && camera_animationInfoDataArr.length == 0)) {

            //如果本步骤没有动画数据

            //如果用户开启了自动包围球
            if (AutoCamera) {
                this.AutoCameraAnimation_CreateStepCameraTweenData(stepCameraAnimationPart, ObjArrInThisGlobleStep_arr)
                console.log('启动自动包围盒')

            }
            return;
        }

        // 获取对应的 div 元素
        // const div = this.$refs.mpdview;
        // 获取宽高
        const canvasWidth = div_three.offsetWidth;
        const canvasHeight = div_three.offsetHeight;

        console.log('显示模型的屏幕宽高是', canvasWidth, canvasHeight)


        //现阶段只计划支持一个相机动画，似乎并不需要多个相机动画
        //这个相机动画可以是转场过渡动画，也可以不是过度动画，过度动画是具有阻塞性的，需要过度动画执行完成后零件动画才会启动

        //相机的动画组（理论上支持多个相机动画，现阶段只计划支持一个动画）
        cameraAnimationTweenArr = [];


        // if () {

        // }


        //相机的过渡动画组（理论上只需要一个过渡）
        for (let i in camera_animationInfoDataArr) {
            //将摄像机动画组成组添加到tween动画数组中

            var i_info = camera_animationInfoDataArr[i];

            // console

            if (!i_info) {


                continue;
            }

            //看是否启用动画
            if (i_info.CameraAnimationEnable == false) {
                console.log("未启用动画");

                //未启用动画，则直接将摄像机对准目标点，然后计算射线机位置
                console.log("步骤动画摄像机的查看角度参数是", i_info);

                if (
                    i_info.CameraSphereRadius > 0 &&
                    i_info.CameraLookAt.x != null &&
                    i_info.CameraLookAt.y != null &&
                    i_info.CameraLookAt.z != null
                ) {
                    var worldCamerSphereCenter = model.localToWorld(
                        new THREE.Vector3(
                            i_info.CameraLookAt.x,
                            i_info.CameraLookAt.y,
                            i_info.CameraLookAt.z
                        )
                    );

                    // this.showCameraSphere(
                    //     i_info.CameraSphereRadius,
                    //     worldCamerSphereCenter.x,
                    //     worldCamerSphereCenter.y,
                    //     worldCamerSphereCenter.z
                    // );

                    camera.lookAt(worldCamerSphereCenter);

                    OrbitControl.target.copy(worldCamerSphereCenter);

                    OrbitControl.update();
                } else {
                    this.$message({
                        message: "无法计算摄像机坐标，原因:缺少半径或包围球中心坐标",
                        type: "warning",
                    });
                }

                //摄像机最大化显示包围球
                this.cameraLookAtSphere(i_info);
            } else if (i_info.CameraAnimationEnable == true) {
                //启用动画的话，看看有没有这只自动包围球，如果设置了自动包围球，则求出自动包围球
                if (i_info.CameraAutoSphere == true) {
                    console.log("启用动画，自动包围球开启");

                    var CameraTween = this.CameraAnimation_AutoMode_true(i_info, canvasWidth, canvasHeight, stepCameraAnimationPart);

                    if (CameraTween) {

                        // console.log(
                        //     "i_info.CameraLookAt",
                        //     i_info.CameraLookAt.x,
                        //     i_info.CameraLookAt.y,
                        //     i_info.CameraLookAt.z
                        // );

                        var worldCamerSphereCenter_ = model.localToWorld(
                            new THREE.Vector3(
                                i_info.CameraLookAt.x,
                                i_info.CameraLookAt.y,
                                i_info.CameraLookAt.z
                            )
                        );

                        console.log(
                            worldCamerSphereCenter_.x,
                            worldCamerSphereCenter_.y,
                            worldCamerSphereCenter_.z
                        );

                        console.log(model);

                        console.log(model.position.x, model.position.y, model.position.z);

                        console.log(model.rotation.x, model.rotation.y, model.rotation.z);

                        console.log("CameraTween", CameraTween);
                        // return {
                        //     CameraTween,
                        //     targetPosition: new THREE.Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z)
                        // }

                        cameraAnimationTweenArr.push({
                            targetPosition: CameraTween.targetPosition,
                            tween: CameraTween.CameraTween,
                            lookat: worldCamerSphereCenter_.clone(),
                        });
                        // }
                    }

                    // cameraAnimationTweenArr
                    // cameraTransitionAnimationTweenArr
                } else {
                    //如果没设置自动包围球，则按照零件内的userdata内的相机动画数据应用到tween动画中

                    console.log("启用动画，自动包围球关闭");
                    //自动包围球关闭的模式下，这种模式依赖于零件中userdata中存储的相机动画数组数据
                    var CameraTween2 = this.CameraAnimation_AutoMode_false(
                        i_info,
                        canvasWidth,
                        canvasHeight
                    );

                    if (CameraTween2) {
                        // if (i_info.CameraAnimationTransition == true) {

                        //     //这是运镜动画
                        //     cameraTransitionAnimationTweenArr.push(CameraTween2)

                        // } else {
                        var worldCamerSphereCenter_1 = model.localToWorld(
                            new THREE.Vector3(
                                i_info.CameraLookAt.x,
                                i_info.CameraLookAt.y,
                                i_info.CameraLookAt.z
                            )
                        );

                        // cameraAnimationTweenArr.push({
                        //     tween: CameraTween2,
                        //     lookat: worldCamerSphereCenter_1.clone()
                        // })
                        cameraAnimationTweenArr.push({
                            targetPosition: CameraTween2.targetPosition,
                            tween: CameraTween2.CameraTween,
                            lookat: worldCamerSphereCenter_1.clone(),
                        });

                        // }
                    }
                }
            }

            //         AutoAnimationGroup.children[i].visible = true
            //         // 更新物体的位置等
            //         AutoAnimationGroup.children[i].position.set(this.x, this.y, this.z);

            //         // AutoAnimationGroup.children[i].updateMatrixWorld()
            //     }
        }
    }




    // placePositionAndRotation


    //生成自动化的相机动画，相机将最大化显示模型
    //这个模式由用户手动开启
    AutoCameraAnimation_CreateStepCameraTweenData(stepCameraAnimationPart, ObjArrInThisGlobleStep_arr) {


        const canvasWidth = div_three.offsetWidth;
        const canvasHeight = div_three.offsetHeight;


        //求出当前步骤零件的包围盒

        var Binding_box = new THREE.Box3()
        var Binding_Sphere = new THREE.Sphere()

        console.log('ObjArrInThisGlobleStep_arr333', ObjArrInThisGlobleStep_arr)


        for (let i = 0; i < ObjArrInThisGlobleStep_arr.length; i++) {

            // Binding_box.expandByObject(ObjArrInThisGlobleStep_arr[i])

            ObjArrInThisGlobleStep_arr[i].traverse((c) => {
                if (c.isMesh) {
                    Binding_box.expandByObject(c);
                }
            });

            // if(){

            // }

        }



        Binding_box.getBoundingSphere(Binding_Sphere)

        console.log('Binding_box,Binding_Sphereeee', Binding_box, Binding_Sphere)

        var LOOKcenter = model.worldToLocal(Binding_Sphere.center.clone());


        // .expandByObject

        // i_info

        var i_info = {
            CameraAngel: {
                HorizontalAngel: 45,
                VerticalAngel: 45
            },
            CameraAnimationEnable: true,
            CameraAutoSphere: true,
            CameraLookAt: {
                x: LOOKcenter.x,
                y: LOOKcenter.y,
                z: LOOKcenter.z
            },
            CameraSphereRadius: Binding_Sphere.radius,
            Duration: 500,
            Effects: "lowSpeedInlowSpeedOut",
            StartOption: "stepBegin",
            startDelay: 0
        }

        var CameraTween = this.CameraAnimation_AutoMode_true(i_info, canvasWidth, canvasHeight, stepCameraAnimationPart);

        if (CameraTween) {

            // console.log(
            //     "i_info.CameraLookAt",
            //     i_info.CameraLookAt.x,
            //     i_info.CameraLookAt.y,
            //     i_info.CameraLookAt.z
            // );

            var worldCamerSphereCenter_ = model.localToWorld(
                new THREE.Vector3(
                    i_info.CameraLookAt.x,
                    i_info.CameraLookAt.y,
                    i_info.CameraLookAt.z
                )
            );

            console.log(
                worldCamerSphereCenter_.x,
                worldCamerSphereCenter_.y,
                worldCamerSphereCenter_.z
            );

            console.log(model);

            console.log(model.position.x, model.position.y, model.position.z);

            console.log(model.rotation.x, model.rotation.y, model.rotation.z);

            console.log("CameraTween", CameraTween);
            // return {
            //     CameraTween,
            //     targetPosition: new THREE.Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z)
            // }

            cameraAnimationTweenArr.push({
                targetPosition: CameraTween.targetPosition,
                tween: CameraTween.CameraTween,
                lookat: worldCamerSphereCenter_.clone(),
            });
            // }
        }




    }

    //专门用来执行相机动画
    async CamreaTweenArrAnimationsDo(tweenArray) {
        var PromiseTweenArr = [];

        for (let j in tweenArray) {
            console.log("23执行动画23222", j, tweenArray[j]);

            if (tweenArray[j].tween) {
                //***********************以下解决两个动画的lookAt点不同而造成的画面快速切换造成的闪烁问题**************** */
                //以射线机为中心制作一个包围球
                //包围球的半径就是相机现在距离目标点的距离
                //然后通过摄像机发出去一条射线，摄像机与包围球将会有个交点
                //然后就可以通过这个交点 慢慢转向目标点

                // var worldCamerSphereCenter = model.localToWorld(new THREE.Vector3(info_item.CameraLookAt.x, info_item.CameraLookAt.y, info_item.CameraLookAt.z))

                var radius = camera.position.distanceTo(
                    new THREE.Vector3(
                        tweenArray[j].lookat.x,
                        tweenArray[j].lookat.y,
                        tweenArray[j].lookat.z
                    )
                );

                var sphere = new THREE.Sphere(camera.position, radius);

                const raycaster = new THREE.Raycaster();
                raycaster.setFromCamera({ x: 0, y: 0 }, camera);

                // //包围球与摄像头中心射线的交叉点
                var intersectionPoint = new THREE.Vector3();

                raycaster.ray.intersectSphere(sphere, intersectionPoint);

                // var a = raycaster.ray.distanceToPoint(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z))
                // console.log('射线离点的距离是', a)

                // //求 摄像机-交点 和 摄像机-目标点 之间的夹角，如果夹角很小，则不需要执行过渡动画
                // //判断部分以后有需要再做，感觉不做也行，100毫秒过渡够了

                // // camera.updateMatrix()

                // const v3_camera_to_intersect = new THREE.Vector3()
                // v3_camera_to_intersect.subVectors(intersectionPoint, camera.position)

                // const v3_camera_to_sphere = new THREE.Vector3()
                // v3_camera_to_sphere.subVectors(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z), camera.position)

                // var angle_ = v3_camera_to_intersect.angleTo(v3_camera_to_sphere)

                // console.log('夹角angle_是', angle_)

                // //夹角是0，不用镜头过渡动画
                // //实际应用中，不知道为什么会产生一个小夹角，导致产生了一个不应该出现的中心点过渡动画，即使是同一个步骤连续播两次的情况也会出现过渡动画
                // //所以只要夹角在0.06弧度（约为3度）内就不会产生过渡动画

                // console.log('tweenArray[j].lookat', tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z)

                // //世界坐标转局部坐标
                // //调试查看数据用
                // var localAMatrix = model.worldToLocal(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z))

                // console.log('局部的坐标是:', localAMatrix)

                // if (angle_ > 0.1) {

                await new Promise((resolve) => {
                    new TWEEN.Tween({
                        x: intersectionPoint.x,
                        y: intersectionPoint.y,
                        z: intersectionPoint.z,
                    })
                        .to(
                            {
                                x: tweenArray[j].lookat.x,
                                y: tweenArray[j].lookat.y,
                                z: tweenArray[j].lookat.z,
                            },
                            200
                        )

                        .onUpdate(function () {
                            // AutoAnimationGroup.children[i].visible = true
                            // // 更新物体的位置等
                            // AutoAnimationGroup.children[i].position.set(this.x, this.y, this.z);
                            console.log(
                                "更新摄像机坐标过渡",
                                intersectionPoint,
                                tweenArray[j].lookat,
                                this.x,
                                this.y,
                                this.z
                            );
                            camera.lookAt(new THREE.Vector3(this.x, this.y, this.z));
                            OrbitControl.target.copy(new THREE.Vector3(this.x, this.y, this.z));
                            OrbitControl.update();
                        })
                        .onComplete(() => {
                            resolve();
                        })
                        .start();
                });

                // } else {
                //     camera.lookAt(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z))
                //     OrbitControl.target.copy(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z))
                //     OrbitControl.update()
                // }

                //***********************以上解决两个动画的lookAt点不同而造成的画面快速切换造成的闪烁问题**************** */

                //看看现在的位置是不是就是动画的目标位置，如果是，则跳过这个动画执行
                // cameraAnimationTweenArr.push({
                //             targetPosition: CameraTween2.targetPosition,
                //             tween: CameraTween2.CameraTween,
                //             lookat: worldCamerSphereCenter_1.clone()
                //         })

                console.log(
                    "摄像机动画离目标位置的距离",
                    tweenArray[j].targetPosition.distanceTo(camera.position)
                );
                //如果摄像机在目标位置，则不用执行该摄像机动画
                if (tweenArray[j].targetPosition.distanceTo(camera.position) > 10) {
                    tweenArray[j].tween.start();

                    // tweenArray[j].tween.delay()

                    PromiseTweenArr.push(
                        new Promise(function (resolve) {
                            tweenArray[j].tween.onComplete(resolve);
                        })
                    );
                } else {
                    camera.lookAt(
                        new THREE.Vector3(
                            tweenArray[j].lookat.x,
                            tweenArray[j].lookat.y,
                            tweenArray[j].lookat.z
                        )
                    );

                    // tweenArray[j].tween.setAnimationDuraction(100),
                    // tweenArray[j].tween.start()

                    // PromiseTweenArr.push(new Promise(function (resolve) {
                    //     tweenArray[j].tween.onComplete(resolve);
                    // }));
                    // camera.position.copy(weenArray[j].targetPosition)
                }
            }
        }

        //等待所有的promise完成
        await Promise.all(PromiseTweenArr);
        console.log("相机动画添加执行完毕");
    }

    //播放本步骤零件动画
    async playThisStepPartOrGroupAnimation(thisStepPartsArr) {

        console.log('播放本步骤动画哟:', thisStepPartsArr)

        var tweenArray = thisStepPartAnimation

        // for (let i = 0; i < thisStepPartsArr.length; i++) {

        //     if (!thisStepPartsArr[i].userData.stepAnimationTween) { continue }

        //     const PartOrGroupUserdataTweenArr = thisStepPartsArr[i].userData.stepAnimationTween

        //     if (Array.isArray(PartOrGroupUserdataTweenArr)) {

        //         for (let j = 0; j < PartOrGroupUserdataTweenArr.length; j++) {

        //             // const TWEEN_j = 

        //             tweenArray.push(PartOrGroupUserdataTweenArr[j])



        //         }

        //     }
        // }

        var PromiseTweenArr = [];

        for (let j in tweenArray) {
            console.log("23执行动画23", j, tweenArray[j]);

            //看看i是不是带有position或者ratation（零件动画）
            // var positionOrRotationTween_i_bool = false;

            if (tweenArray[j].positionTween) {
                // positionOrRotationTween_i_bool = true;
                // tweenArray[j].rotationTween.startPosition.x = 100

                // tweenArray[j].rotationTween.startPosition.y = 100
                // tweenArray[j].rotationTween.startPosition.z = 100
                // tweenArray[j].positionTween.onStart(function () {
                //     this.x = 100
                //     this.y = 100
                //     this.z = 100
                // })


                tweenArray[j].positionTween.start();
                //执行动画后放入promise
                PromiseTweenArr.push(
                    new Promise(function (resolve) {
                        tweenArray[j].positionTween.onComplete(resolve);
                    })
                );
            }

            if (tweenArray[j].rotationTween) {
                // positionOrRotationTween_i_bool = true;

                // tweenArray[j].rotationTween.startPosition = { x: 100, y: 100, z: 100 };
                tweenArray[j].rotationTween.start();

                PromiseTweenArr.push(
                    new Promise(function (resolve) {
                        tweenArray[j].rotationTween.onComplete(resolve);
                    })
                );
            }

            //表示i不是零件动画，因为零件动画带有position和rotation属性
            // if (positionOrRotationTween_i_bool == false) {
            //     if (tweenArray[j]) {
            //         tweenArray[j].start();

            //         PromiseTweenArr.push(
            //             new Promise(function (resolve) {
            //                 tweenArray[j].onComplete(resolve);
            //             })
            //         );
            //     }
            // }
        }

        //等待所有的promise完成
        await Promise.all(PromiseTweenArr);

        console.log("动画添加执行完毕");

    }

    //执行主模型旋转动画
    async doRotateModelAnimation() {

        var PromiseTweenArr = []

        for (let i = 0; i < modelRotateTweenArr.length; i++) {

            modelRotateTweenArr[i].tween.start()

            PromiseTweenArr.push(
                new Promise(function (resolve) {
                    modelRotateTweenArr[i].tween.onComplete(resolve);
                })
            );

        }


        // //等待所有的promise完成
        await Promise.all(PromiseTweenArr);

    }

    //将步骤中的动画数据生成出来（自动包围球开启的模式下）
    CameraAnimation_AutoMode_true(i_info, canvasWidth, canvasHeight, stepCameraAnimationPart) {
        //先找到本步骤的动画数据组

        // if (i_info.CameraAngel == null || i_info.CameraAngel.HorizontalAngel == null || i_info.CameraAngel.VerticalAngel == null || !i_info.CameraAnimationEnable || i_info.CameraLookAt == null
        //     || i_info.CameraLookAt.x == null || i_info.CameraLookAt.y == null || i_info.CameraLookAt.z == null || i_info.CameraSphereRadius == null || i_info.Duration == null) { return }

        const sphere = this.CreateSphereFromThisAndBeforeStep(stepCameraAnimationPart);
        //根据参数计算摄像机的目标位置

        var radius = sphere.radius;

        //竖屏状态下要缩小半径，要不然包围球会超出
        if (canvasWidth < canvasHeight) {
            radius = radius * (canvasHeight / canvasWidth);
        }

        // console.log('camera.position', camera.position.x, camera.position.y, camera.position.z)
        // const lookAt = new THREE.Vector3(i_info.CameraLookAt.x * 1, i_info.CameraLookAt.y * 1, i_info.CameraLookAt.z * 1)

        // const lookAt = new THREE.Vector3(sphere.center.x, sphere.center.y, sphere.center.z)
        const lookAt = model.localToWorld(
            new THREE.Vector3(
                i_info.CameraLookAt.x * 1,
                i_info.CameraLookAt.y * 1,
                i_info.CameraLookAt.z * 1
            )
        );

        // const cameraLookAt = lookAt

        const distance = (radius * 1.1) / Math.tan(((Math.PI / 180) * camera.fov) / 2);

        //水平和垂直角度都是0的时候，以世界坐标的z轴负方向相同
        const horizontalAngle =
            i_info.CameraAngel.HorizontalAngel != null
                ? i_info.CameraAngel.HorizontalAngel * 1
                : 45;

        //垂直角度
        const verticalAngle =
            i_info.CameraAngel.VerticalAngel != null
                ? i_info.CameraAngel.VerticalAngel * 1
                : 45;

        //原创，后期可能会忘，希望以下说明能尽可能清楚
        //这段代码会导致摄像机没办法正常的到达模型的顶部和底部，只能保证前后左右的视角是正常的，所以需要调整

        //调整办法
        //先确定y在经过包围盒中心点的水平面上的投影度
        //然后根据该投影长度去求x和z的值
        //distance其实就是包围球的半径
        const y_distanceToCenterOnXZ_Plane =
            distance * Math.cos((Math.PI / 180) * verticalAngle);

        console.log("y_distanceToCenterOnXZ_Plane999", y_distanceToCenterOnXZ_Plane);

        const cameraPosition = {
            x: lookAt.x + y_distanceToCenterOnXZ_Plane * Math.sin((Math.PI / 180) * horizontalAngle),
            y: lookAt.y + distance * Math.sin((Math.PI / 180) * verticalAngle),
            z: lookAt.z + y_distanceToCenterOnXZ_Plane * Math.cos((Math.PI / 180) * horizontalAngle),
        };

        var duration = i_info.Duration > 0 ? i_info.Duration : 1000;

        const CameraTween = new TWEEN.Tween(camera.position);

        CameraTween.to(
            { x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z },
            duration
        ).onUpdate(function () {
            camera.lookAt(lookAt);
            // camera.lookAt(cameraLookAt);

            OrbitControl.target.copy(lookAt);

            OrbitControl.update();

            // camera.lookAt(new THREE.Vector3())
        });

        //慢入慢出情况
        if (i_info.Effects == "lowSpeedInlowSpeedOut") {
            CameraTween.easing(TWEEN.Easing.Quadratic.InOut); // 设置插值方式 慢入慢出 lowSpeedInlowSpeedOut

            //匀速情况
        } else if (i_info.Effects == "uniformSpeed") {
            CameraTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed

            //未定义情况，默认匀速
        } else {
            CameraTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed
        }

        // CameraTween.start()

        // return CameraTween

        return {
            CameraTween,
            targetPosition: new THREE.Vector3(
                cameraPosition.x,
                cameraPosition.y,
                cameraPosition.z
            ),
        };
    }

    //根据本步骤和前面的步骤求包围球
    CreateSphereFromThisAndBeforeStep(stepCameraAnimationPart) {
        console.log('stepCameraAnimationPart999', stepCameraAnimationPart)
        //求出中心点
        const showgroup = stepCameraAnimationPart.parent;

        const bbox = new THREE.Box3();

        const chooseStep = stepCameraAnimationPart.userData.MyStep;

        var ThisAndBeforeStepPartArr = [];

        var sphere;

        for (let i = 0; i < showgroup.children.length; i++) {

            if (!showgroup.children[i].userData.placePosition) {
                //没有位置备份信息就跳过
                continue
            }

            //将本步骤及小于本步骤的所有零件存起来
            if (showgroup.children[i].userData.MyStep <= chooseStep) {

                ThisAndBeforeStepPartArr.push(showgroup.children[i]);
                console.log('ThisAndBeforeStepPartArr', ThisAndBeforeStepPartArr)

                //将本层的本步骤以前的所有零件的坐标和旋转都设为安装时的坐标和旋转，保证求出来的包围盒可靠
                const i_userData = showgroup.children[i].userData;

                // //求出包围盒后将零件移动回之前的旋转和位置，从而保证动画前零件保持在原来 位置（为 延迟期间是否变换功能 服务）
                const backupPosition = showgroup.children[i].position.clone();
                const backupRotation = showgroup.children[i].quaternion.clone();

                // console.log('i_userData.placePosition', i_userData.placePosition)
                showgroup.children[i].position.set(
                    i_userData.placePosition.x,
                    i_userData.placePosition.y,
                    i_userData.placePosition.z
                );

                console.log('3333i_userData', i_userData)

                showgroup.children[i].quaternion.set(
                    i_userData.placeQuaternion.x,
                    i_userData.placeQuaternion.y,
                    i_userData.placeQuaternion.z,
                    i_userData.placeQuaternion.w
                );

                // showgroup.children[i].quaternion.set(
                //     i_userData.placeQuaternion.x,
                //     i_userData.placeQuaternion.y,
                //     i_userData.placeQuaternion.z

                // );

                //先将showgroup.children[i]更新，避免包围盒不准确
                showgroup.children[i].updateMatrixWorld();

                showgroup.children[i].traverse((c) => {
                    if (c.isMesh) {
                        bbox.expandByObject(c);
                    }
                });

                //求出安装状态的包围盒后将零件还原到上面求包围盒前的备份状态,备份及还原的操作主要是保证动画时不影响到包围盒尺寸
                showgroup.children[i].position.copy(backupPosition);
                showgroup.children[i].quaternion.copy(backupRotation);
            }
        }

        sphere = new THREE.Sphere();

        bbox.getBoundingSphere(sphere);


        console.log('本步骤前步骤的包围盒参数是', bbox, sphere)

        //自动填入包围球的中心坐标
        // info_item.CameraLookAt.x = sphere.center.x
        // info_item.CameraLookAt.y = sphere.center.y
        // info_item.CameraLookAt.z = sphere.center.z

        // camera.lookAt(new THREE.Vector3(cameraLookAt_x,cameraLookAt_y,cameraLookAt_z));

        // camera.updateProjectionMatrix ()

        // OrbitControl.target.copy(sphere.center)

        // OrbitControl.update()

        return sphere;
    }

    //将步骤中的动画数据生成出来（自动包围球关闭的模式下）
    CameraAnimation_AutoMode_false(i_info, canvasWidth, canvasHeight) {
        //先找到本步骤的动画数据组

        if (
            i_info.CameraAngel == null ||
            i_info.CameraAngel.HorizontalAngel == null ||
            i_info.CameraAngel.VerticalAngel == null ||
            !i_info.CameraAnimationEnable ||
            i_info.CameraLookAt == null ||
            i_info.CameraLookAt.x == null ||
            i_info.CameraLookAt.y == null ||
            i_info.CameraLookAt.z == null ||
            i_info.CameraSphereRadius == null ||
            i_info.Duration == null
        ) {
            return;
        }

        //根据参数计算摄像机的目标位置

        var radius = i_info.CameraSphereRadius * 1;

        //竖屏状态下要缩小半径，要不然包围球会超出
        if (canvasWidth < canvasHeight) {
            radius = radius * (canvasHeight / canvasWidth);
        }

        // console.log('camera.position', camera.position.x, camera.position.y, camera.position.z)
        // const lookAt = new THREE.Vector3(i_info.CameraLookAt.x * 1, i_info.CameraLookAt.y * 1, i_info.CameraLookAt.z * 1)
        const lookAt = model.localToWorld(
            new THREE.Vector3(
                i_info.CameraLookAt.x * 1,
                i_info.CameraLookAt.y * 1,
                i_info.CameraLookAt.z * 1
            )
        );
        console.log(
            "lookat334444,",
            new THREE.Vector3(
                i_info.CameraLookAt.x * 1,
                i_info.CameraLookAt.y * 1,
                i_info.CameraLookAt.z * 1
            )
        );
        console.log(lookAt.x, lookAt.y, lookAt.z);
        // const cameraLookAt = lookAt

        const distance = (radius * 1.1) / Math.tan(((Math.PI / 180) * camera.fov) / 2);

        //水平和垂直角度都是0的时候，以世界坐标的z轴负方向相同
        const horizontalAngle = i_info.CameraAngel.HorizontalAngel * 1;

        //垂直角度
        const verticalAngle = i_info.CameraAngel.VerticalAngel * 1;

        //原创，后期可能会忘，希望以下说明能尽可能清楚
        //这段代码会导致摄像机没办法正常的到达模型的顶部和底部，只能保证前后左右的视角是正常的，所以需要调整

        //调整办法
        //先确定y在经过包围盒中心点的水平面上的投影度
        //然后根据该投影长度去求x和z的值
        //distance其实就是包围球的半径
        const y_distanceToCenterOnXZ_Plane =
            distance * Math.cos((Math.PI / 180) * verticalAngle);

        console.log("y_distanceToCenterOnXZ_Plane999", y_distanceToCenterOnXZ_Plane);

        const cameraPosition = {
            x:
                lookAt.x +
                y_distanceToCenterOnXZ_Plane * Math.sin((Math.PI / 180) * horizontalAngle),
            y: lookAt.y + distance * Math.sin((Math.PI / 180) * verticalAngle),
            z:
                lookAt.z +
                y_distanceToCenterOnXZ_Plane * Math.cos((Math.PI / 180) * horizontalAngle),
        };

        var duration = i_info.Duration > 0 ? i_info.Duration : 1000;

        const CameraTween = new TWEEN.Tween(camera.position);

        CameraTween.to(
            { x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z },
            duration
        ).onUpdate(function () {
            camera.lookAt(lookAt);
            // camera.lookAt(cameraLookAt);

            OrbitControl.target.copy(lookAt);

            OrbitControl.update();

            // camera.lookAt(new THREE.Vector3())
        });

        //慢入慢出情况
        if (i_info.Effects == "lowSpeedInlowSpeedOut") {
            CameraTween.easing(TWEEN.Easing.Quadratic.InOut); // 设置插值方式 慢入慢出 lowSpeedInlowSpeedOut

            //匀速情况
        } else if (i_info.Effects == "uniformSpeed") {
            CameraTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed

            //未定义情况，默认匀速
        } else {
            CameraTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed
        }

        // CameraTween.start()
        // return CameraTween
        return {
            CameraTween,
            targetPosition: new THREE.Vector3(
                cameraPosition.x,
                cameraPosition.y,
                cameraPosition.z
            ),
        };
    }

    /**
* 传入零件后，递归修改零件的颜色，如果遇到16的组才进去修改，不是16的组不修改（子孙也不修改）
*/
    changeColorByLdrawRule(Part, ColorNum_string, materialLibrary) {

        //条件线段现在不能显示，我还没想好怎么处理条件线段,我要移除
        // var ConditionalLineSegmentsArr = []

        const setMaterials = function (GROUP, upperColour_ = null) {

            //console.log('设置颜色主', upperColour_)

            var upperColour = upperColour_


            for (let index = 0; index < GROUP.children.length; index++) {
                const i_obj = GROUP.children[index]

                // if (i_obj.userData.colorCode) {
                // if (i_obj.isLine) {


                //   //条件线段不能上色
                //   // if (!i_obj.isConditionalLine) {






                //   // } else {
                //   //零件的线应该是什么颜色？以后还得升级，可以尝试读取线父级的材质的rgb值再决定
                //   if (i_obj.parent.userData.colorCode == '0' || i_obj.parent.userData.colorCode == '256') {

                //     // 	//有优化空间
                //     // //console.log('material_flotArr', material_flotArr)
                //     i_obj.material = materialLibrary[8]

                //     // i_obj.parent.userData.colorCode = '8'

                //   } else {

                //     i_obj.material = materialLibrary[0]

                //     // i_obj.parent.userData.colorCode = '0'

                //   }

                //   //条件线段现在不能显示，我还没想好怎么处理条件线段
                //   if (i_obj.isConditionalLine) {

                //     // i_obj.visible = false

                //     ConditionalLineSegmentsArr.push(i_obj)



                //   }
                //   // }


                //   // continue

                //   // i_obj.isLine

                // } else 


                if (i_obj.isMesh) {

                    //console.log('设置材质333', i_obj, materialLibrary, materialLibrary[0])
                    //console.log('i_obj.material', i_obj.material)
                    //console.log('!i_obj.material', !i_obj.material)
                    //console.log(upperColour, i_obj.material)

                    if (!i_obj.userData.colorCode) {

                        console.error('没有材质文件', upperColour)

                        //如果没有材质
                        if (!i_obj.material || i_obj.material.userData.code != upperColour) {
                            i_obj.material = materialLibrary[upperColour]
                        }




                        continue
                    }

                    //组形式的材质
                    if (Array.isArray(i_obj.userData.colorCode)) {

                        console.log('数组设置有颜色', i_obj.userData.colorCode)

                        //  if (i_obj.userData.colorCode.length >= 1) {

                        // i_obj.material = i_obj.material.forEach(function (colourValue) {

                        // 	if (colourValue == '16') {

                        // 		materialLibrary[upperColour]


                        // 	} else {

                        // 		materialLibrary[colourValue]

                        // 	}
                        // })
                        console.log('i_obj.userData.colorCodeeee', i_obj.userData.colorCode)

                        // i_obj.material = i_obj.userData.colorCode.map(function (colourValue) {
                        //     if (colourValue == '16') {
                        //         return materialLibrary[upperColour];
                        //     } else {
                        //         console.log('设置颜色给数组', materialLibrary[colourValue])
                        //         return materialLibrary[colourValue];
                        //     }
                        // });
                        var colorArr = i_obj.userData.colorCode
                        for (let i = 0; i < colorArr.length; i++) {

                            //不是正确的材质才修改
                            //没有材质也修改

                            if (colorArr[i] == '16') {

                                if (!i_obj.material[i] || i_obj.material[i].userData.code != upperColour) {
                                    i_obj.material[i] = materialLibrary[upperColour]
                                }

                            } else {

                                if (!i_obj.material[i] || i_obj.material[i].userData.code != colorArr[i]) {
                                    i_obj.material[i] = materialLibrary[colorArr[i]]
                                }
                            }
                            // }



                        }

                        // }

                    } else {
                        //普通形式的材质

                        if (i_obj.userData.colorCode == '16') {

                            i_obj.material = materialLibrary[upperColour]

                        } else {

                            i_obj.material = materialLibrary[i_obj.userData.colorCode]

                        }

                    }


                    // if (i_obj.userData.colorCode == ['16']) {

                    // 	i_obj.material = materialLibrary[upperColour]

                    // } else if (i_obj.userData.colorCode != null) {

                    // 	// if(Array.isArray(i_obj.userData.colorCode)){

                    // 	if (i_obj.userData.colorCode.length == 1) {

                    // 		i_obj.material = materialLibrary[i_obj.userData.colorCode[0]]

                    // 	} else if (i_obj.userData.colorCode.length >= 1) {

                    // 		i_obj.material = i_obj.userData.colorCode.forEach(function (colourValue) { materialLibrary[colourValue] })

                    // 	}

                    // 	// }



                    // }

                    // i_obj.material = upperColour ? materialLibrary[upperColour] : materialLibrary[i_obj.userData.colorCode]




                } else if (i_obj.isGroup) {

                    if (i_obj.userData.colorCode) {

                        if (i_obj.userData.colorCode != '16') {


                            setMaterials(i_obj, i_obj.userData.colorCode)

                            // if(i_obj.material){

                            // }

                        } else if (i_obj.userData.colorCode == '16') {


                            setMaterials(i_obj, upperColour)

                        }
                    }

                }



                // }

                // if (i_obj.name.endsWith('.ldr') || i_obj.name.endsWith('.dat')) {
                // 	//零件



                // }


            }



        }

        console.log('设置材质颜色', Part, ColorNum_string)

        setMaterials(Part, ColorNum_string)



        //目前不知道条件线段如何处理，所以我全部移除
        // for (let i = 0; i < ConditionalLineSegmentsArr.length; i++) {

        //   ConditionalLineSegmentsArr[i].removeFromParent()

        // }
    }

    disposeData() {
        // div_three
        
        cameraAnimationTweenArr.length = 0
       
        modelRotateTweenArr.length = 0
        
        thisStepPartAnimation.length = 0
        
        Model_children_visibleInfoArr.length = 0
        //自动摄像机动画，自动将模型最大化显示
        

        
        model = null

        // camera

        targetGlobleStepNum= null

        partAndGroupArr.length = 0

        // OrbitControl = null

        // lDrawLoader

    }



}


export { DoStepAnimation };
